import { AuthActionTypeIds, IClerUserAuthAction, IGetUserAuthAction, ISetUserAuthAction } from "../action-types/authActionTypes";
import { IUserInfoModel } from "../../models/response/account/user-info"

export const getUser = (): IGetUserAuthAction => {
    return {
        type: AuthActionTypeIds.GET_USER_AUTH
    }
}

export const setUser = (data: IUserInfoModel): ISetUserAuthAction => {
    return {
        type: AuthActionTypeIds.SET_USER_AUTH,
        payload: data
    }
}
export const clearUser = (): IClerUserAuthAction => {
    return {
        type: AuthActionTypeIds.CLEAR_USER_AUTH
    }
}