import { sf_bom_chitiet_remove_khoannop_rq } from "../../models/request/bom/sf_bom_chitiet_remove_khoannop_rq";
import { sf_bom_chitiet_res, sf_bom_chitiet_viewmodel } from "../../models/response/bom/sf_bom_chitiet";
import { sf_bom_hinhthucnopbom_viewmodel } from "../../models/response/bom/sf_bom_hinhthucnopbom";
import { BomChiTietActionTypeIds, BomChiTietChangeSelectedIds, BomChiTietCloseDeleteConfrim, BomChiTietCloseKhoanNopModal, BomChiTietDeleteError, BomChiTietDeleteStart, BomChiTietDeleteSuccess, BomChiTietLoadErrAction, BomChiTietLoadStartAction, BomChiTietLoadSuccessAction, BomChiTietShowDeleteConfrim, BomChiTietShowKhoanNopModal } from "../action-types/bomChiTietActionTypes";

export const loadBomChiTietStartAction = (sf_bom_id: number): BomChiTietLoadStartAction => {
    return {
        type: BomChiTietActionTypeIds.BOMCHITIET_LOAD_START,
        payload: sf_bom_id
    }
}
export const loadBomChiTietErrAction = (message: string): BomChiTietLoadErrAction => {
    return {
        type: BomChiTietActionTypeIds.BOMCHITIET_LOAD_ERR,
        payload: message
    }
}
export const loadBomChiTietSuccessAction = (sf_bom_chitiet_res: sf_bom_chitiet_res): BomChiTietLoadSuccessAction => {
    return {
        type: BomChiTietActionTypeIds.BOMCHITIET_LOAD_SUCCESS,
        payload: sf_bom_chitiet_res
    }
}
export const showBomChiTietKhoanNopModalAction = (sf_bom_hinhthucnopbom_viewmodel: sf_bom_hinhthucnopbom_viewmodel): BomChiTietShowKhoanNopModal => {
    return {
        type: BomChiTietActionTypeIds.BOMCHITIET_SHOW_KHOANNOP_MODAL,
        payload: sf_bom_hinhthucnopbom_viewmodel
    }
}

export const closeBomChiTietKhoanNopAction = (): BomChiTietCloseKhoanNopModal => {
    return {
        type: BomChiTietActionTypeIds.BOMCHITIET_CLOSE_KHOANNOP_MODAL
    }
}

export const changeBomChiTietSelectedIds = (sf_khoannop_ids: number[]): BomChiTietChangeSelectedIds => {
    return {
        type: BomChiTietActionTypeIds.BOMCHITIET_CHANGE_SELECTED_IDS,
        payload: sf_khoannop_ids
    }
}


export const showBomChiTietDeleteConfirm = (): BomChiTietShowDeleteConfrim => {
    return {
        type: BomChiTietActionTypeIds.BOMCHITIET_SHOW_DELETE_CONFIRM
    }
}


export const closeBomChiTietDeleteConfirm = (): BomChiTietCloseDeleteConfrim => {
    return {
        type: BomChiTietActionTypeIds.BOMCHITIET_CLOSE_DELETE_CONFIRM
    }
}



export const deleteBomChiTietStart = (rq: sf_bom_chitiet_remove_khoannop_rq): BomChiTietDeleteStart => {
    return {
        type: BomChiTietActionTypeIds.BOMCHITIET_DELETE_START,
        payload: rq
    }
}



export const deleteBomChiTietSuccess = (): BomChiTietDeleteSuccess => {
    return {
        type: BomChiTietActionTypeIds.BOMCHITIET_DELETE_SUCCESS
    }
}

export const deleteBomChiTietError = (message: string): BomChiTietDeleteError => {
    return {
        type: BomChiTietActionTypeIds.BOMCHITIET_DELETE_ERRROR,
        payload: message
    }
}