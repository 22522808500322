import { sf_nhomkhoannop } from "../../models/response/category/sf_nhomkhoannop"
export enum NhomKhoanNopActionTypeIds {
    NHOMKHOANNOP_LOAD_START = "NHOMKHOANNOP_LOAD_START",
    NHOMKHOANNOP_LOAD_SUCCESS = "NHOMKHOANNOP_LOAD_SUCCESS",
    NHOMKHOANNOP_LOAD_ERROR = "NHOMKHOANNOP_LOAD_ERROR",

    KHOANOP_SHOW_DETAIL_MODAL = "KHOANOP_SHOW_DETAIL_MODAL",
    NHOMKHOANNOP_SHOW_DELETE_CONFIRM = "NHOMKHOANNOP_SHOW_DELETE_CONFIRM",

    NHOMKHOANNOP_SAVE_START = "NHOMKHOANNOP_SAVE_START",
    NHOMKHOANNOP_SAVE_SUCCESS = "NHOMKHOANNOP_SAVE_SUCCESS",
    NHOMKHOANNOP_SAVE_ERROR = "NHOMKHOANNOP_SAVE_ERROR",

    NHOMKHOANNOP_DELETE_START = "NHOMKHOANNOP_DELETE_START",
    NHOMKHOANNOP_DELETE_SUCCESSS = "NHOMKHOANNOP_DELETE_SUCCESSS",
    NHOMKHOANNOP_DELETE_ERROR = "NHOMKHOANNOP_DELETE_ERROR",

    NHOMKHOANNOP_CHANGE_SELECTED_IDS = "NHOMKHOANNOP_CHANGE_SELECTED_IDS"

}

export interface NhomKhoanNopLoadStartAction {
    type: NhomKhoanNopActionTypeIds.NHOMKHOANNOP_LOAD_START
}
export interface NhomKhoanNopLoadSuccessAction {
    type: NhomKhoanNopActionTypeIds.NHOMKHOANNOP_LOAD_SUCCESS,
    payload: sf_nhomkhoannop[]
}
export interface NhomKhoanNopLoadErrorAction {
    type: NhomKhoanNopActionTypeIds.NHOMKHOANNOP_LOAD_ERROR,
    payload: string
}

export interface NhomKhoanNopShowDetailModalAction {
    type: NhomKhoanNopActionTypeIds.KHOANOP_SHOW_DETAIL_MODAL,
    payload: boolean

}
export interface NhomKhoanNopShowDeleteConfirmAction {
    type: NhomKhoanNopActionTypeIds.NHOMKHOANNOP_SHOW_DELETE_CONFIRM,
    payload: boolean
}
export interface NhomKhoanNopSaveStartAction {
    type: NhomKhoanNopActionTypeIds.NHOMKHOANNOP_SAVE_START,
    payload: sf_nhomkhoannop
}
export interface NhomKhoanNopSaveSuccessAction {
    type: NhomKhoanNopActionTypeIds.NHOMKHOANNOP_SAVE_SUCCESS,
    payload: sf_nhomkhoannop
}
export interface NhomKhoanNopSaveErrorAction {
    type: NhomKhoanNopActionTypeIds.NHOMKHOANNOP_SAVE_ERROR,
    payload: string
}
export interface NhomKhoanNopDeleteStartAction {
    type: NhomKhoanNopActionTypeIds.NHOMKHOANNOP_DELETE_START,
    payload: number[]
}
export interface NhomKhoanNopDeleteSuccessAction {
    type: NhomKhoanNopActionTypeIds.NHOMKHOANNOP_DELETE_SUCCESSS,
    payload: number[]
}
export interface NhomKhoanNopDeleteErrorAction {
    type: NhomKhoanNopActionTypeIds.NHOMKHOANNOP_DELETE_ERROR,
    payload: string
}

export interface NhomKhoanNopChangeSelectedIdsAction {
    type: NhomKhoanNopActionTypeIds.NHOMKHOANNOP_CHANGE_SELECTED_IDS,
    payload: number[]
}
export type NhomKhoanNopActionTypes =
    NhomKhoanNopLoadStartAction | NhomKhoanNopLoadSuccessAction | NhomKhoanNopLoadErrorAction |
    NhomKhoanNopShowDetailModalAction | NhomKhoanNopShowDeleteConfirmAction |
    NhomKhoanNopSaveStartAction | NhomKhoanNopSaveSuccessAction | NhomKhoanNopSaveErrorAction |
    NhomKhoanNopDeleteStartAction | NhomKhoanNopDeleteSuccessAction | NhomKhoanNopDeleteErrorAction |
    NhomKhoanNopChangeSelectedIdsAction