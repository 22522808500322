import { call, put, takeEvery } from "redux-saga/effects"
import { bomChiTietApi } from "../../api/bomChiTietApi"
import { IBaseResponeModel } from "../../models/response/base-response"
import { BomChiTietActionTypeIds, BomChiTietDeleteStart, BomChiTietLoadStartAction } from "../action-types/bomChiTietActionTypes"
import { deleteBomChiTietError, deleteBomChiTietSuccess, loadBomChiTietErrAction, loadBomChiTietSuccessAction } from "../actions/bomChiTietActions"
export function* bomChiTietSaga(): any {
    yield takeEvery(BomChiTietActionTypeIds.BOMCHITIET_LOAD_START, loadBomChiTietStartWorker)
    yield takeEvery(BomChiTietActionTypeIds.BOMCHITIET_DELETE_START, deleteBomChiTietStartWorker)
}
function* loadBomChiTietStartWorker(data: BomChiTietLoadStartAction) {
    const res: IBaseResponeModel = yield call(bomChiTietApi.select,data.payload)
    if (res.is_success) {
        yield put(loadBomChiTietSuccessAction(res.data))
    } else {
        yield put(loadBomChiTietErrAction(res.message || ""))
    }
}

function* deleteBomChiTietStartWorker(data: BomChiTietDeleteStart) {
    const res: IBaseResponeModel = yield call(bomChiTietApi.delete,data.payload)
    if (res.is_success) {
        yield put(deleteBomChiTietSuccess())
    } else {
        yield put(deleteBomChiTietError(res.message || ""))
    }
}