import { IBaseDeleteRequestModel } from "../models/request/base/deleteRequest";
import { sf_loaikhoannop } from "../models/response/category/sf_loaikhoannop";
import { apiClient } from "./apiClient";

export const SF_LOAIKHOANNOP_SELECT_ALL_API = "sf_loaikhoannop/select_all";
export const SF_LOAIKHOANNOP_INSERT_API = "sf_loaikhoannop/insert";
export const SF_LOAIKHOANNOP_UPDATE_API = "sf_loaikhoannop/update";
export const SF_LOAIKHOANNOP_DELETE_API = "sf_loaikhoannop/delete";

export const loaiKhoanNopApi = {
    select_all: () => {
        return apiClient.post(SF_LOAIKHOANNOP_SELECT_ALL_API)
    },
    insert: (data: sf_loaikhoannop) => {
        return apiClient.post(SF_LOAIKHOANNOP_INSERT_API, data)
    },
    update: (data: sf_loaikhoannop) => {
        return apiClient.post(SF_LOAIKHOANNOP_UPDATE_API, data)
    },
    delete: (data: number[]) => {
        const model: IBaseDeleteRequestModel = {
            ids: data
        }
        return apiClient.post(SF_LOAIKHOANNOP_DELETE_API, model)
    }
}