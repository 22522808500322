import { ILoginSSORequestModel } from "../../models/request/account";
import { AccountActionTypeIds, ILoginErrorAction, ILoginStartAction, ILoginSuccessAction, ILogOutAction } from "../action-types/accountActionTypes";

export const loginStart = (data: ILoginSSORequestModel): ILoginStartAction => {
    return {
        type: AccountActionTypeIds.LOGIN_START,
        payload: data
    }
}

export const loginSuccess = (): ILoginSuccessAction => {
    return {
        type: AccountActionTypeIds.LOGIN_SUCCESS
    }
}

export const loginError = (data?: string): ILoginErrorAction => {
    return {
        type: AccountActionTypeIds.LOGIN_ERROR,
        payload: data
    }
}

export const logOut = (): ILogOutAction => {
    return {
        type: AccountActionTypeIds.LOG_OUT
    }
}

