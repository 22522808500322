import { NamHocTruongKhoiHeFilter } from "../models/namHocTruongKhoiHeFilter";
import { sf_dexuatmiengiam_approve_rq } from "../models/request/gan-mien-giam/sf_dexuatmiengiam_approve_rq";
import { sf_dexuatmiengiam_changed_rq } from "../models/request/gan-mien-giam/sf_dexuatmiengiam_changed_rq";
import { apiClient } from "./apiClient";

export const SF_DEXUATMIENGIAM_SELECT = "sf_dexuatmiengiam/select";
export const SF_DEXUATMIENGIAM_CHANGED = "sf_dexuatmiengiam/changed";
export const SF_DEXUATMIENGIAM_APPROVE = "sf_dexuatmiengiam/approve_all";

export const ganMienGiamApi = {
    select: (rq: NamHocTruongKhoiHeFilter) => {
        return apiClient.post(`${SF_DEXUATMIENGIAM_SELECT}`, rq)
    },
    changed: (rq: sf_dexuatmiengiam_changed_rq) => {
        return apiClient.post(`${SF_DEXUATMIENGIAM_CHANGED}`, rq)
    },
    approve: (rq: sf_dexuatmiengiam_approve_rq) => {
        return apiClient.post(`${SF_DEXUATMIENGIAM_APPROVE}`, rq)
    },

}