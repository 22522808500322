import { IBaseDeleteRequestModel } from "../models/request/base/deleteRequest";
import { sf_mucthuphi_coppy_rq } from "../models/request/mucthuphi/sf_mucthiphi_coppy_rq";
import { sf_mucthuphi_select_rq } from "../models/request/mucthuphi/sf_mucthiphi_select_rq";
import { sf_mucthuphi } from "../models/response/category/sf_mucthuphi";
import { apiClient } from "./apiClient";

export const SF_MUCTHUPHI_SELECT_ALL_API = "sf_mucthuphi/select";
export const SF_MUCTHUPHI_INSERT_API = "sf_mucthuphi/insert";
export const SF_MUCTHUPHI_UPDATE_API = "sf_mucthuphi/update";
export const SF_MUCTHUPHI_DELETE_API = "sf_mucthuphi/delete";
export const SF_MUCTHUPHI_COPPY_API = "sf_mucthuphi/copy";


export const mucThuPhiApi = {
    select: (request: sf_mucthuphi_select_rq) => {
        return apiClient.post(`${SF_MUCTHUPHI_SELECT_ALL_API}`, request)
    },
    insert: (data: sf_mucthuphi) => {
        return apiClient.post(`${SF_MUCTHUPHI_INSERT_API}`, data)
    },
    update: (data: sf_mucthuphi) => {
        return apiClient.post(`${SF_MUCTHUPHI_UPDATE_API}`, data)
    },
    delete: (data: number[]) => {
        const model: IBaseDeleteRequestModel = {
            ids: data
        }
        return apiClient.post(`${SF_MUCTHUPHI_DELETE_API}`, model)
    },
    coppy: (data: sf_mucthuphi_coppy_rq) => {
        return apiClient.post(`${SF_MUCTHUPHI_COPPY_API}`, data)
    }
}