import { NamHocTruongKhoiHeFilter } from "../models/namHocTruongKhoiHeFilter";

export const localStorageHelper = {
    getNamHocTruongKhoiFilter: (): NamHocTruongKhoiHeFilter => {
        return {
            nam_hoc: localStorageHelper.getNamHoc(),
            dm_he_id: localStorageHelper.getDmHeId(),
            dm_khoi_id: localStorageHelper.getDmKhoiId(),
            dm_truong_id: localStorageHelper.getDmTruongId()
        }
    },
    setNamHocTruongKhoiFilter: (filter: NamHocTruongKhoiHeFilter) => {
        localStorageHelper.setNamHoc(filter.nam_hoc);
        localStorageHelper.setDmHeId(filter.dm_he_id);
        localStorageHelper.setDmKhoiId(filter.dm_khoi_id);
        localStorageHelper.setDmTruongId(filter.dm_truong_id);

    },
    getNamHoc: (): string => {
        try {
            return localStorage.nam_hoc ? localStorage.nam_hoc.toString() : "";
        } catch {
            return "";
        }
    },
    setNamHoc: (nam_hoc: string): void => {
        localStorage.setItem("nam_hoc", nam_hoc)
    },
    getDmHeId: (): number => {
        try {
            return localStorage.dm_he_id ? parseInt(localStorage.dm_he_id) : 0;
        } catch {
            return 0;
        }
    },
    setDmHeId: (dm_he_id: number): void => {
        localStorage.setItem("dm_he_id", dm_he_id.toString())
    },
    getDmKhoiId: (): number => {
        try {
            return localStorage.dm_khoi_id ? parseInt(localStorage.dm_khoi_id) : 0;
        } catch {
            return 0;
        }
    },
    setDmKhoiId: (dm_khoi_id: number): void => {
        localStorage.setItem("dm_khoi_id", dm_khoi_id.toString())
    },
    getDmTruongId: (): number => {
        try {
            return localStorage.dm_truong_id ? parseInt(localStorage.dm_truong_id) : 0;
        } catch {
            return 0;
        }
    },
    setDmTruongId: (dm_truong_id: number): void => {
        localStorage.setItem("dm_truong_id", dm_truong_id.toString())
    }
}