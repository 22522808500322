import { sf_hachtoan_congno, sf_hachtoan_congno_viewmodel } from "../../../models/response/hach-toan-cong-no/sf_hachtoan_congno";
import { sf_hachtoan_congno_select_by_hocsinh_rq } from "../../../models/request/hach-toan-cong-no/sf_hachtoan_congno_select_by_hocsinh_rq"
import { EditTBTPHachToanActionIds, EditTBTPHachToanCloseAddModel, EditTBTPHachToanCloseDeleteConfirm, EditTBTPHachToanDeleteError, EditTBTPHachToanDeleteStart, EditTBTPHachToanDeleteSuccess, EditTBTPHachToanLoadHachToanAddedError, EditTBTPHachToanLoadHachToanAddedStart, EditTBTPHachToanLoadHachToanAddedSuccess, EditTBTPHachToanLoadHachToanPendingError, EditTBTPHachToanLoadHachToanPendingStart, EditTBTPHachToanLoadHachToanPendingSuccess, EditTBTPHachToanShowAddModel, EditTBTPHachToanShowDeleteConfirm } from "../../action-types/edit-tbtp/editTBTPHachToanActionTypes";
import { sf_tbtp_remove_hachtoan_rq } from "../../../models/request/tbtp/sf_tbtp_remove_hachtoan_rq";

export const loadEditTBTPHachToanAddedStart = (sf_tbtp_id: number): EditTBTPHachToanLoadHachToanAddedStart => {
    return {
        type: EditTBTPHachToanActionIds.EDIT_TBTP_HACHTOAN_LOAD_HACHTOAN_ADDED_START,
        payload: sf_tbtp_id
    }
}


export const loadEditTBTPHachToanAddedSuccess = (sf_hachtoan_congnos: sf_hachtoan_congno[]): EditTBTPHachToanLoadHachToanAddedSuccess => {
    return {
        type: EditTBTPHachToanActionIds.EDIT_TBTP_HACHTOAN_LOAD_HACHTOAN_ADDED_SUCESS,
        payload: sf_hachtoan_congnos
    }
}

export const loadEditTBTPHachToanAddedError = (message: string): EditTBTPHachToanLoadHachToanAddedError => {
    return {
        type: EditTBTPHachToanActionIds.EDIT_TBTP_HACHTOAN_LOAD_HACHTOAN_ADDED_ERROR,
        payload: message
    }
}



export const loadEditTBTPHachToanPendingStart = (rq: sf_hachtoan_congno_select_by_hocsinh_rq): EditTBTPHachToanLoadHachToanPendingStart => {
    return {
        type: EditTBTPHachToanActionIds.EDIT_TBTP_HACHTOAN_LOAD_HACHTOAN_PENDING_START,
        payload: rq
    }
}


export const loadEditTBTPHachToanPendingSuccess = (payload: sf_hachtoan_congno_viewmodel[]): EditTBTPHachToanLoadHachToanPendingSuccess => {
    return {
        type: EditTBTPHachToanActionIds.EDIT_TBTP_HACHTOAN_LOAD_HACHTOAN_PENDING_SUCCESS,
        payload: payload
    }
}

export const loadEditTBTPHachToanPendingError = (message: string): EditTBTPHachToanLoadHachToanPendingError => {
    return {
        type: EditTBTPHachToanActionIds.EDIT_TBTP_HACHTOAN_LOAD_HACHTOAN_PENDING_ERROR,
        payload: message
    }
}


export const showEditTBTPHachToanDeleteConfirm = (sf_hachtoan_congno_id: number): EditTBTPHachToanShowDeleteConfirm => {
    return {
        type: EditTBTPHachToanActionIds.EDIT_TBTP_HACHTOAN_SHOW_DELETE_CONFIRM,
        payload: sf_hachtoan_congno_id
    }
}

export const closeEditTBTPHachToanDeleteConfirm = (): EditTBTPHachToanCloseDeleteConfirm => {
    return {
        type: EditTBTPHachToanActionIds.EDIT_TBTP_HACHTOAN_CLOSE_DELETE_CONFIRM,

    }
}

export const showEditTBTPHachToanAddModal = (): EditTBTPHachToanShowAddModel => {
    return {
        type: EditTBTPHachToanActionIds.EDIT_TBTP_HACHTOAN_SHOW_ADD_MODAL
    }
}


export const closeEditTBTPHachToanAddModal = (): EditTBTPHachToanCloseAddModel => {
    return {
        type: EditTBTPHachToanActionIds.EDIT_TBTP_HACHTOAN_CLOSE_ADD_MODAL
    }
}


export const deleteEditTBTPHachToanStart = (rq: sf_tbtp_remove_hachtoan_rq): EditTBTPHachToanDeleteStart => {
    return {
        type: EditTBTPHachToanActionIds.EDIT_TBTP_HACHTOAN_DELTE_START,
        payload: rq
    }
}



export const deleteEditTBTPHachToanSuccess = (): EditTBTPHachToanDeleteSuccess => {
    return {
        type: EditTBTPHachToanActionIds.EDIT_TBTP_HACHTOAN_DELTE_SUCCESS
    }
}



export const deleteEditTBTPHachToanError = (message: string): EditTBTPHachToanDeleteError => {
    return {
        type: EditTBTPHachToanActionIds.EDIT_TBTP_HACHTOAN_DELTE_ERROR,
        payload: message
    }
}
