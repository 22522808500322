import { sf_tbtp_add_hachtoan_rq } from "../../models/request/tbtp/sf_tbtp_add_hachtoan_rq"
import { sf_tbtp_auto_hachtoan_rq } from "../../models/request/tbtp/sf_tbtp_auto_hachtoan_rq"
import { sf_tbtp_pdf_create_rq } from "../../models/request/tbtp/sf_tbtp_pdf_create_rq"
import { sf_tbtp_select_rq } from "../../models/request/tbtp/sf_tbtp_select_rq"
import { sf_tbtp_send_rq } from "../../models/request/tbtp/sf_tbtp_send_rq"
import { sf_tbtp, sf_tbtp_viewmodel } from "../../models/response/tbtp/sf_tbtp"
import {
    TBTPActionTypeIds, TBTPAutoHachToanCloseConfirm, TBTPAutoHachToanErrror, TBTPAutoHachToanShowConfirm, TBTPAutoHachToanStart, TBTPAutoHachToanSuccess, TBTPChangeSelectedIdsAction, TBTPCloseDeleteConfirmAction, TBTPCloseDetailModalAction, TBTPCloseEditAction, TBTPClosePdfConfirm, TBTPCreatePdfError, TBTPCreatePdfStart, TBTPCreatePdfSuccess, TBTPDeleteErrorAction, TBTPDeleteStartAction,
    TBTPDeleteSuccessAction, TBTPHeChangedAction,
    TBTPKhoiChangedAction, TBTPLoadErrorAction, TBTPLoadStartAction, TBTPLoadSuccessAction, TBTPNamHocChangedAction, TBTPSaveErrorAction, TBTPSaveStartAction, TBTPSaveSuccessAction, TBTPSendCloseConfirm, TBTPSendErr, TBTPSendShowConfirm, TBTPSendStart, TBTPSendSuccess, TBTPShowDeleteConfirmAction, TBTPShowDetailModalAction, TBTPShowEditAction, TBTPShowPdfConfirm, TBTPTruongChangedAction
} from "../action-types/tbtpActionTypes"

export const loadTBTPStart = (payload: sf_tbtp_select_rq): TBTPLoadStartAction => {
    return {
        type: TBTPActionTypeIds.TBTP_LOAD_START,
        payload: payload
    }
}

export const loadTBTPSuccess = (payload: sf_tbtp_viewmodel[]): TBTPLoadSuccessAction => {
    return {
        type: TBTPActionTypeIds.TBTP_LOAD_SUCCESS,
        payload: payload
    }
}
export const loadTBTPError = (payload: string): TBTPLoadErrorAction => {
    return {
        type: TBTPActionTypeIds.TBTP_LOAD_ERROR,
        payload: payload
    }
}

export const showTBTPDetailModal = (): TBTPShowDetailModalAction => {
    return {
        type: TBTPActionTypeIds.TBTP_SHOW_DETAIL_MODAL,
    }
}
export const closeTBTPDetailModal = (): TBTPCloseDetailModalAction => {
    return {
        type: TBTPActionTypeIds.TBTP_CLOSE_DETAIL_MODAL,
    }
}

export const showTBTPDeleteConfirm = (): TBTPShowDeleteConfirmAction => {
    return {
        type: TBTPActionTypeIds.TBTP_SHOW_DELETE_CONFIRM,
    }
}
export const closeTBTPDeleteConfirm = (): TBTPCloseDeleteConfirmAction => {
    return {
        type: TBTPActionTypeIds.TBTP_CLOSE_DELETE_CONFIRM,
    }
}

export const saveTBTPStart = (payload: sf_tbtp): TBTPSaveStartAction => {
    return {
        type: TBTPActionTypeIds.TBTP_SAVE_START,
        payload: payload
    }
}

export const saveTBTPSuccess = (payload: sf_tbtp): TBTPSaveSuccessAction => {
    return {
        type: TBTPActionTypeIds.TBTP_SAVE_SUCCESS,
        payload: payload
    }
}
export const saveTBTPError = (payload: string): TBTPSaveErrorAction => {
    return {
        type: TBTPActionTypeIds.TBTP_SAVE_ERROR,
        payload: payload
    }
}

export const deleteTBTPStart = (payload: number[]): TBTPDeleteStartAction => {
    return {
        type: TBTPActionTypeIds.TBTP_DELETE_START,
        payload: payload
    }
}

export const deleteTBTPSuccess = (payload: number[]): TBTPDeleteSuccessAction => {
    return {
        type: TBTPActionTypeIds.TBTP_DELETE_SUCCESSS,
        payload: payload
    }
}
export const deleteTBTPError = (payload: string): TBTPDeleteErrorAction => {
    return {
        type: TBTPActionTypeIds.TBTP_DELETE_ERROR,
        payload: payload
    }
}

export const changeTBTPeSelectionIds = (payload: number[]): TBTPChangeSelectedIdsAction => {
    return {
        type: TBTPActionTypeIds.TBTP_CHANGE_SELECTED_IDS,
        payload: payload
    }
}

export const changeTBTPNamHoc = (payload: string): TBTPNamHocChangedAction => {
    return {
        type: TBTPActionTypeIds.TBTP_NAMHOC_CHANGED,
        payload: payload
    }
}

export const changeTBTPTruong = (payload: number): TBTPTruongChangedAction => {
    return {
        type: TBTPActionTypeIds.TBTP_TRUONG_CHANGED,
        payload: payload
    }
}
export const changeTBTPHe = (payload: number): TBTPHeChangedAction => {
    return {
        type: TBTPActionTypeIds.TBTP_HE_CHANGED,
        payload: payload
    }
}

export const changeTBTPKhoi = (payload: number): TBTPKhoiChangedAction => {
    return {
        type: TBTPActionTypeIds.TBTP_KHOI_CHANGED,
        payload: payload
    }
}


export const showEditModal = (payload: sf_tbtp_viewmodel): TBTPShowEditAction => {
    return {
        type: TBTPActionTypeIds.TBTP_SHOW_EDIT,
        payload: payload
    }
}



export const closeEditModal = (): TBTPCloseEditAction => {
    return {
        type: TBTPActionTypeIds.TBTP_CLOSE_EDIT
    }
}

export const showPdfConfirm = (payload: number[]): TBTPShowPdfConfirm =>{
    return {
        type: TBTPActionTypeIds.TBTP_SHOW_PDF_CONFIRM,
        payload: payload
    }
}

export const closePdfConfirm = (): TBTPClosePdfConfirm =>{
    return {
        type: TBTPActionTypeIds.TBTP_CLOSE_PDF_CONFIRM,
    }
}


export const createPdfStart = (payload: sf_tbtp_pdf_create_rq): TBTPCreatePdfStart =>{
    return {
        type: TBTPActionTypeIds.TBTP_CREATE_PDF_START,
        payload: payload
    }
}


export const createPdfSuccess = (): TBTPCreatePdfSuccess =>{
    return {
        type: TBTPActionTypeIds.TBTP_CREATE_PDF_SUCCESS,
    }
}



export const createPdfError = (message:string): TBTPCreatePdfError =>{
    return {
        type: TBTPActionTypeIds.TBTP_CREATE_PDF_ERROR,
        payload: message
    }
}




export const showAutoHachToanConfirm = (payload: number[]): TBTPAutoHachToanShowConfirm =>{
    return {
        type: TBTPActionTypeIds.TBTP_AUTO_HACHTOAN_SHOW_CONFIRM,
        payload: payload
    }
}

export const closeAutoHachToanConfirm = (): TBTPAutoHachToanCloseConfirm =>{
    return {
        type: TBTPActionTypeIds.TBTP_AUTO_HACHTOAN_CLOSE_CONFIRM,
    }
}


export const autoHachToanStart = (payload: sf_tbtp_auto_hachtoan_rq): TBTPAutoHachToanStart =>{
    return {
        type: TBTPActionTypeIds.TBTP_AUTO_HACHTOAN_START,
        payload: payload
    }
}


export const autoHachToanSuccess = (): TBTPAutoHachToanSuccess =>{
    return {
        type: TBTPActionTypeIds.TBTP_AUTO_HACHTOAN_SUCCESS,
    }
}



export const autoHachToanError = (message:string): TBTPAutoHachToanErrror =>{
    return {
        type: TBTPActionTypeIds.TBTP_AUTO_HACHTOAN_ERRROR,
        payload: message
    }
}




export const showTBTPSendConfirm = (payload: number[]): TBTPSendShowConfirm =>{
    return {
        type: TBTPActionTypeIds.TBTP_SEND_SHOW_CONFIRM,
        payload: payload
    }
}

export const closeTBTPSendConfirm = (): TBTPSendCloseConfirm =>{
    return {
        type: TBTPActionTypeIds.TBTP_SEND_CLOSE_CONFIRM,
    }
}


export const sendTBTPStart = (payload: sf_tbtp_send_rq): TBTPSendStart =>{
    return {
        type: TBTPActionTypeIds.TBTP_SEND_START,
        payload: payload
    }
}


export const sendTBTPSuccess = (): TBTPSendSuccess =>{
    return {
        type: TBTPActionTypeIds.TBTP_SEND_SUCCESS,
    }
}



export const sendTBTPError = (message:string): TBTPSendErr =>{
    return {
        type: TBTPActionTypeIds.TBTP_SEND_ERR,
        payload: message
    }
}