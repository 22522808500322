import { sf_nhomkhoannop } from "../../models/response/category/sf_nhomkhoannop"
import {
    NhomKhoanNopActionTypeIds,
    NhomKhoanNopLoadStartAction, NhomKhoanNopLoadSuccessAction, NhomKhoanNopLoadErrorAction, NhomKhoanNopShowDetailModalAction,
    NhomKhoanNopSaveStartAction, NhomKhoanNopSaveSuccessAction, NhomKhoanNopSaveErrorAction, NhomKhoanNopDeleteStartAction, NhomKhoanNopDeleteSuccessAction, NhomKhoanNopDeleteErrorAction, NhomKhoanNopChangeSelectedIdsAction, NhomKhoanNopShowDeleteConfirmAction
} from "../action-types/nhomKhoanNopActionTypes"

export const loadNhomKhoanNopStart = (): NhomKhoanNopLoadStartAction => {
    return {
        type: NhomKhoanNopActionTypeIds.NHOMKHOANNOP_LOAD_START
    }
}

export const loadNhomKhoanNopSuccess = (payload: sf_nhomkhoannop[]): NhomKhoanNopLoadSuccessAction => {
    return {
        type: NhomKhoanNopActionTypeIds.NHOMKHOANNOP_LOAD_SUCCESS,
        payload: payload
    }
}
export const loadNhomKhoanNopError = (payload: string): NhomKhoanNopLoadErrorAction => {
    return {
        type: NhomKhoanNopActionTypeIds.NHOMKHOANNOP_LOAD_ERROR,
        payload: payload
    }
}

export const showNhomKhoanNopDetailModal = (payload: boolean): NhomKhoanNopShowDetailModalAction => {
    return {
        type: NhomKhoanNopActionTypeIds.KHOANOP_SHOW_DETAIL_MODAL,
        payload: payload
    }
}

export const showNhomKhoanNopDeleteConfirm = (payload: boolean): NhomKhoanNopShowDeleteConfirmAction => {
    return {
        type: NhomKhoanNopActionTypeIds.NHOMKHOANNOP_SHOW_DELETE_CONFIRM,
        payload: payload
    }
}

export const saveNhomKhoanNopStart = (payload: sf_nhomkhoannop): NhomKhoanNopSaveStartAction => {
    return {
        type: NhomKhoanNopActionTypeIds.NHOMKHOANNOP_SAVE_START,
        payload: payload
    }
}

export const saveNhomKhoanNopSuccess = (payload: sf_nhomkhoannop): NhomKhoanNopSaveSuccessAction => {
    return {
        type: NhomKhoanNopActionTypeIds.NHOMKHOANNOP_SAVE_SUCCESS,
        payload: payload
    }
}
export const saveNhomKhoanNopError = (payload: string): NhomKhoanNopSaveErrorAction => {
    return {
        type: NhomKhoanNopActionTypeIds.NHOMKHOANNOP_SAVE_ERROR,
        payload: payload
    }
}

export const deleteNhomKhoanNopStart = (payload: number[]): NhomKhoanNopDeleteStartAction => {
    return {
        type: NhomKhoanNopActionTypeIds.NHOMKHOANNOP_DELETE_START,
        payload: payload
    }
}

export const deleteNhomKhoanNopSuccess = (payload: number[]): NhomKhoanNopDeleteSuccessAction => {
    return {
        type: NhomKhoanNopActionTypeIds.NHOMKHOANNOP_DELETE_SUCCESSS,
        payload: payload
    }
}
export const deleteNhomKhoanNopError = (payload: string): NhomKhoanNopDeleteErrorAction => {
    return {
        type: NhomKhoanNopActionTypeIds.NHOMKHOANNOP_DELETE_ERROR,
        payload: payload
    }
}

export const changeNhomKhoanNopSelectionIds = (payload: number[]): NhomKhoanNopChangeSelectedIdsAction => {
    return {
        type: NhomKhoanNopActionTypeIds.NHOMKHOANNOP_CHANGE_SELECTED_IDS,
        payload: payload
    }
}