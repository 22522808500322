import { combineReducers } from "redux";
import { accountReducer } from "./accountReducer";
import { authReducer } from "./authReducer";
import { bomChiTietReducer } from "./bomChiTietReducer";
import { BOMReducer } from "./bomReducer";
import { categorySourceReducer } from "./categorySourceReducer";
import { commonReducer } from "./commonReducer";
import { dictionaryReducer } from "./dictionaryReducer";
import { editTBTPHachToanReducer } from "./edit-tbtp/editTBTPHachToanReducer";
import { editTBTPKhoanNopReducer } from "./edit-tbtp/editTBTPKhoanNopReducer";
import { ganMienGiamReducer } from "./ganMienGiamReducer";
import { hachToanCongNoReducer } from "./hachToanCongNoReducer";
import { hinhThucMienGiamReducer } from "./hinhThucMienGiamReducer";
import { khoanNopGroupReducer } from "./khoanNopGroupReducer";
import { khoanNopReducer } from "./khoanNopReducer";
import { loaiKhoanNopReducer } from "./loaiKhoanNopReducer";
import { mucThuPhiReducer } from "./mucThuPhiReducer";
import { ngayNghiReducer } from "./ngayNghiReducer";
import { nhomKhoanNopReducer } from "./nhomKhoanNopReducer";
import { tbtpReducer } from "./tbtpReducer";
import { thuPhiChiTietReducer } from "./thuPhiChiTietReducer";
import { thuPhiReducer } from "./thuPhiReducers";
import { tongHopChiTietReducer } from "./tongHopChiTietReducer";
import { tongHopReducer } from "./tongHopReducer";

const reducers = combineReducers({
    auth: authReducer,
    account: accountReducer,
    dictionary: dictionaryReducer,
    common: commonReducer,
    khoanNop: khoanNopReducer,
    loaiKhoanNop: loaiKhoanNopReducer,
    nhomKhoanNop: nhomKhoanNopReducer,
    khoanNopGroup: khoanNopGroupReducer,
    categorySource: categorySourceReducer,
    mucThuPhi: mucThuPhiReducer,
    bom: BOMReducer,
    bomChiTiet: bomChiTietReducer,
    tbtp: tbtpReducer,
    hinhThucMienGiam: hinhThucMienGiamReducer,
    ganMienGiam: ganMienGiamReducer,
    hachToan: hachToanCongNoReducer,
    editTBTPHachToan: editTBTPHachToanReducer,
    editTBTPKhoanNop: editTBTPKhoanNopReducer,
    tongHop: tongHopReducer,
    tongHopChiTiet: tongHopChiTietReducer,
    thuPhi: thuPhiReducer,
    thuPhiChiTiet: thuPhiChiTietReducer,
    ngayNghi: ngayNghiReducer
})

export default reducers

export type RootState = ReturnType<typeof reducers>