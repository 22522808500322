import LoadPanel from 'devextreme-react/load-panel';
import 'devextreme/dist/css/dx.common.css';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import { Image } from "semantic-ui-react";
import { appInfo } from './AppInfo';
import { Loadable } from './components/loadable';
// import Content from './Content';
import { CommonProvider } from './contexts/common';
import { NavigationProvider } from './contexts/navigation';
import './dx-styles.scss';
import { loginStart, logOut } from './state/actions/accountActions';
import { getUser } from './state/actions/authActions';
import { RootState } from './state/reducers';
import './style.css';
import './themes/generated/theme.additional.css';
import './themes/generated/theme.base.css';
import { useScreenSizeClass } from './utils/media-query';
import pMinDelay from 'p-min-delay';
import LoaderPage from './components/loader-page';
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}
export const Content = Loadable(() => pMinDelay(import('./Content'), 250), <LoaderPage />);

const App = () => {

    const user = useSelector((state: RootState) => state.auth.user_info)
    const is_login_loading = useSelector((state: RootState) => state.account.isLoading)
    const is_getUser_loading = useSelector((state: RootState) => state.auth.isLoading)
    const isLoading = is_login_loading || is_getUser_loading
    const query = useQuery();
    const hash = query.get("hash") || "";
    const user_id: string = query.get("user") || "0";
    const ticket_key = query.get("key") || "";

    const dispatch = useDispatch();

    //1. Nếu đã đăng nhập, đúng user từ portal -> OK
    //2. Nếu đã đăng nhập nhưng không đúng user từ portal -> đăng xuất
    //3. Nếu chưa đăng nhập, chưa có tickey_key, hash -> Chuyển sang portal
    //4. Nếu chưa đăng nhập, có ticket_key, hash -> thực hiện đăng nhập

    //Đọc access token để set user
    //Nếu user chưa đc set: undefined
    // Đã được set (dù có hợp lệ hay không): != undefined
    useEffect(() => {
        if (user == undefined) {
            dispatch(getUser())
        }
    }, [user])
    //Sau khi check từ access token
    useEffect(() => {
        if (user != undefined) {
            if (user.id > 0 && user_id !== "0" && user.id.toString() !== user_id) {
                dispatch(logOut());
            }
            if (!user || user.id <= 0) {
                if (ticket_key != "" && hash != "") {
                    dispatch(loginStart({ ticket_key: ticket_key, hash: hash }))
                } else {
                    window.location.href = appInfo.homeURL + "/login?service=" + window.location.origin + "&system_id=" + appInfo.subSytemId;
                }
            }
        }
    }, [user, ticket_key, hash, user_id])

    useEffect(() => {
        if (user && user.id) {
            // dispatch(loadDictionary())
        }
    }, [user])

    if (user && user.id > 0 && (user.id.toString() === user_id || user_id === "0")) {
        return <Content />
    }
    if (isLoading) {
        return <LoadPanel visible={true} />;
    } else {
        return <Image src="./images/3s.png" className="loading-login"></Image>
    }

}


export default function () {
    const screenSizeClass = useScreenSizeClass();

    return (
        <Router>
            <NavigationProvider>
                <CommonProvider>
                    <div className={`app ${screenSizeClass}`}>
                        <App />
                    </div>
                </CommonProvider>
            </NavigationProvider>
        </Router>
    );
}
