import React from 'react'

export const LoaderPage = () => {
    return (
        <>
            <div style={{
                height: window.innerHeight - 100,
                display: "flex",
                alignItems: "center",
                width: "100%",
                background: "transparent",
                flexDirection: "column",
                justifyContent: "center"

            }}>
                <img width="60px" src={"./images/Spinner.svg"} alt="loader" style={{
                    borderRadius: "50%"
                }} />
            </div>
        </>
    )
}

export default LoaderPage