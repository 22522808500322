import { IBaseDeleteRequestModel } from "../models/request/base/deleteRequest";
import { sf_bom_copy_namhoc_rq, sf_bom_copy_rq } from "../models/request/bom/sf_bom_copy_rq";
import { sf_bom_selecte_rq } from "../models/request/bom/sf_bom_select_rq";
import { sf_bom } from "../models/response/bom/sf_bom";
import { apiClient } from "./apiClient";

export const SF_BOM_SELECT_API = "sf_bom/select";

export const SF_BOM_DETAIL_API = "sf_bom/select_by_id";
export const SF_BOM_INSERT_API = "sf_bom/insert";
export const SF_BOM_UPDATE_API = "sf_bom/update";
export const SF_BOM_DELETE_API = "sf_bom/delete";
export const SF_BOM_COPY_NAMHOC_API = "sf_bom/copy_namhoc";
export const SF_BOM_COPY_API = "sf_bom/coppy";

export const bomApi = {
    select: (rq: sf_bom_selecte_rq) => {
        return apiClient.post(`${SF_BOM_SELECT_API}`, rq)
    },
    detail: (sf_bom_id: number) => {
        return apiClient.post(SF_BOM_DETAIL_API + "?id=" + sf_bom_id)
    },
    insert: (data: sf_bom) => {
        return apiClient.post(`${SF_BOM_INSERT_API}`, data)
    },
    update: (data: sf_bom) => {
        return apiClient.post(`${SF_BOM_UPDATE_API}`, data)
    },
    delete: (data: number[]) => {
        const model: IBaseDeleteRequestModel = {
            ids: data
        }
        return apiClient.post(`${SF_BOM_DELETE_API}`, model)
    },
    copy_namhoc:(rq:sf_bom_copy_namhoc_rq)=>{
        return apiClient.post(`${SF_BOM_COPY_NAMHOC_API}`, rq)
    },
    copy:(rq:sf_bom_copy_rq)=>{
        
        return apiClient.post(`${SF_BOM_COPY_API}`, rq)
    }
}