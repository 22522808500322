import { call, put, takeEvery } from "redux-saga/effects"
import { khoanNopGroupApi } from "../../api/khoanNopGroupApi"
import { IBaseResponeModel } from "../../models/response/base-response"
import { KhoanNopGroupActionTypeIds, KhoanNopGroupDeleteStartAction, KhoanNopGroupSaveStartAction } from "../action-types/khoanNopGroupActionTypes"
import {
    loadKhoanNopGroupError, loadKhoanNopGroupSuccess, saveKhoanNopGroupError, saveKhoanNopGroupSuccess,
    deleteKhoanNopGroupSuccess, deleteKhoanNopGroupError
} from "../actions/khoanNopGroupActions"
export function* khoanNopGroupSaga(): any {
    yield takeEvery(KhoanNopGroupActionTypeIds.KHOANNOPGROUP_LOAD_START, loadKhoanNopGroupStartWorker)
    yield takeEvery(KhoanNopGroupActionTypeIds.KHOANNOPGROUP_SAVE_START, saveKhoanNopGroupStartWorker)
    yield takeEvery(KhoanNopGroupActionTypeIds.KHOANNOPGROUP_DELETE_START, deleteKhoanNopGroupStartWorker)
}
function* loadKhoanNopGroupStartWorker() {
    const res: IBaseResponeModel = yield call(khoanNopGroupApi.select_all)
    if (res.is_success) {
        yield put(loadKhoanNopGroupSuccess(res.data))
    } else {
        yield put(loadKhoanNopGroupError(res.message || ""))
    }
}
function* saveKhoanNopGroupStartWorker(data: KhoanNopGroupSaveStartAction) {
    let res: IBaseResponeModel;
    if (data.payload.id > 0) {
        res = yield call(khoanNopGroupApi.update, data.payload)
    } else {
        res = yield call(khoanNopGroupApi.insert, data.payload)
    }

    if (res.is_success) {
        yield put(saveKhoanNopGroupSuccess(data.payload))
        yield call(loadKhoanNopGroupStartWorker)
    } else {
        yield put(saveKhoanNopGroupError(res.message || ""))
    }
}
function* deleteKhoanNopGroupStartWorker(data: KhoanNopGroupDeleteStartAction) {
    const res: IBaseResponeModel = yield call(khoanNopGroupApi.delete, data.payload)
    if (res.is_success) {
        yield put(deleteKhoanNopGroupSuccess(data.payload))
        yield call(loadKhoanNopGroupStartWorker)
    } else {
        yield put(deleteKhoanNopGroupError(res.message || ""))
    }
}