import { IDictionaryModel } from "../../models/response/dictionary";
import { DictionaryActionTypeIds, ILoadDictionaryRequestedAction, ISetDictionaryAction } from "../action-types/dictionaryActionTypes";

export const loadDictionary = (): ILoadDictionaryRequestedAction => {
    return {
        type: DictionaryActionTypeIds.LOAD_DICTIONARY_REQUESTED
    }
}

export const setDictionary = (data: IDictionaryModel[]): ISetDictionaryAction => {
    return {
        type: DictionaryActionTypeIds.SET_DICTIONARY,
        payload: data
    }
}