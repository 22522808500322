import { sf_phanbophi_viewmodel } from "../../models/request/phan-bo-phi/sf_phan_bo_phi";
import { sf_mucphaithu } from "../../models/response/thu-phi/sf_mucphaithu";
import { sf_mucphaithu_chitiet_viewmodel } from "../../models/response/thu-phi/sf_mucphaithu_chitiet";
import { sf_mucphaithu_chitiet_miengiam_viewmodel } from "../../models/response/thu-phi/sf_mucphaithu_chitiet_miengiam";
import { sf_mucphaithu_miengiam } from "../../models/response/thu-phi/sf_mucphaithu_miengiam";
import { ThuPhiChiTietActionTypeIds, ThuPhiChiTietCloseConfirmDeleteKhoanNop, ThuPhiChiTietDeleteKhoanNopErr, ThuPhiChiTietDeleteKhoanNopStart, ThuPhiChiTietDeleteKhoanNopSuccess, ThuPhiChiTietInitialAction, ThuPhiChiTietLoadErr, ThuPhiChiTietLoadStart, ThuPhiChiTietLoadSuccess, ThuPhiChiTietMienGiamChiTietLoadSuccess, ThuPhiChiTietMienGiamLoadStart, ThuPhiChiTietMienGiamLoadSuccess, ThuPhiChiTietMucPhaiThuLoadStart, ThuPhiChiTietMucPhaiThuLoadSuccess, ThuPhiChiTietPhanBoPhiLoadErr, ThuPhiChiTietPhanBoPhiLoadStart, ThuPhiChiTietPhanBoPhiLoadSuccess, ThuPhiChiTietShowConfirmDeleteKhoanNop } from "../action-types/thuPhiChiTietActionTypes";
import { ThuPhiChiTietPageState } from "../page-state-model/thuPhiChiTietPageState";

export const initialThuPhiChiTietState = (state: ThuPhiChiTietPageState): ThuPhiChiTietInitialAction => {
    return {
        type: ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_INITIAL,
        payload: state
    }

}


export const loadThuPhiChiTietStart = (sf_mucphaithu_id: number): ThuPhiChiTietLoadStart => {
    return {
        type: ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_LOAD_START,
        payload: sf_mucphaithu_id
    }
}


export const loadThuPhiChiTietSuccess = (data: sf_mucphaithu_chitiet_viewmodel[]): ThuPhiChiTietLoadSuccess => {
    return {
        type: ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_LOAD_SUCCESS,
        payload: data
    }
}


export const loadThuPhiChiTietError = (data: string): ThuPhiChiTietLoadErr => {
    return {
        type: ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_LOAD_ERROR,
        payload: data
    }
}

export const loadThuPhiMienGiamStart = (sf_mucphaithu_id: number): ThuPhiChiTietMienGiamLoadStart => {
    return {
        type: ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_MIENGIAM_LOAD_START,
        payload: sf_mucphaithu_id
    }
}
export const loadThuPhiMienGiamSuccess = (data: sf_mucphaithu_miengiam[]): ThuPhiChiTietMienGiamLoadSuccess => {
    return {
        type: ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_MIENGIAM_LOAD_SUCCESS,
        payload: data
    }
}


export const loadThuPhiMienGiamChiTietSuccess = (data: sf_mucphaithu_chitiet_miengiam_viewmodel[]): ThuPhiChiTietMienGiamChiTietLoadSuccess => {
    return {
        type: ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_MIENGIAM_CHITIET_LOAD_SUCCESS,
        payload: data
    }
}


export const showThuPhiDeleteKhoanNopConfirm = (data: sf_mucphaithu_chitiet_viewmodel): ThuPhiChiTietShowConfirmDeleteKhoanNop => {
    return {
        type: ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_SHOW_DELETE_CONFIRM,
        payload: data
    }
}


export const closeThuPhiDeleteKhoanNopConfirm = (): ThuPhiChiTietCloseConfirmDeleteKhoanNop => {
    return {
        type: ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_CLOSE_DELETE_CONFIRM,
    }
}

export const deleteThuPhiKhoanNopStart = (sf_mucphaithu_chitiet_selected: sf_mucphaithu_chitiet_viewmodel): ThuPhiChiTietDeleteKhoanNopStart => {
    return {
        type: ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_DELETE_KHOANNOP_START,
        payload: sf_mucphaithu_chitiet_selected
    }
}


export const deleteThuPhiKhoanNopSuccess = (): ThuPhiChiTietDeleteKhoanNopSuccess => {
    return {
        type: ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_DELETE_KHOANNOP_SUCCESS,
    }
}


export const deleteThuPhiKhoanNopError = (message: string): ThuPhiChiTietDeleteKhoanNopErr => {
    return {
        type: ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_DELETE_KHOANNOP_ERR,
        payload: message
    }
}



export const loadThuPhiPhanBoPhiStart = (sf_mucphaithu_id: number): ThuPhiChiTietPhanBoPhiLoadStart => {
    return {
        type: ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_LOAD_PHANBOPHI_START,
        payload: sf_mucphaithu_id
    }
}


export const loadThuPhiPhanBoPhiSuccess = (data: sf_phanbophi_viewmodel[]): ThuPhiChiTietPhanBoPhiLoadSuccess => {
    return {
        type: ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_LOAD_PHANBOPHI_SUCCESS,
        payload: data
    }
}


export const loadThuPhiPhanBoPhiError = (data: string): ThuPhiChiTietPhanBoPhiLoadErr => {
    return {
        type: ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_LOAD_PHANBOPHI_ERROR,
        payload: data
    }
}

export const loadThuPhiChiTietMucPhaiThuStart = (sf_mucphaithu_id: number): ThuPhiChiTietMucPhaiThuLoadStart => {
    return {
        type: ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_MUCPHAITHU_LOAD_START,
        payload: sf_mucphaithu_id
    }
}

export const loadThuPhiChiTietMucPhaiThuSuccess = (sf_mucphaithu: sf_mucphaithu): ThuPhiChiTietMucPhaiThuLoadSuccess => {
    return {
        type: ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_MUCPHAITHU_LOAD_SUCCESS,
        payload: sf_mucphaithu
    }
}
