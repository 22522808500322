import { NamHocTruongKhoiHeFilter } from "../../models/namHocTruongKhoiHeFilter";
import { ganMienGiamFilterStudentModel } from "../../models/request/gan-mien-giam/ganMienGiamFilterStudent";
import { sf_dexuatmiengiam_approve_rq } from "../../models/request/gan-mien-giam/sf_dexuatmiengiam_approve_rq";
import { sf_dexuatmiengiam_changed_rq } from "../../models/request/gan-mien-giam/sf_dexuatmiengiam_changed_rq";
import { sf_hinhthucmiengiam } from "../../models/response/category/sf_hinhthucmiengiam";
import { sf_dexuatmiengiam, sf_dexuatmiengiam_viewmodel } from "../../models/response/gan-mien-giam/sf_dexuatmiengiam";
import { sf_dexuatmiengiam_changed_res } from "../../models/response/gan-mien-giam/sf_dexuatmiengiam_changed_res";
import { ts_hocsinh_viewmodel } from "../../models/response/hocsinh/ts_hocsinh";
import { GanMienGiamActionTypeIds, GanMienGiamApproveAllCancelAction, GanMienGiamApproveAllErrorAction, GanMienGiamApproveAllStartAction, GanMienGiamApproveAllSubmitAction, GanMienGiamApproveAllSuccessAction, GanMienGiamChangeErrAction, GanMienGiamChangeFilterAction, GanMienGiamChangeFilterStudentAction, GanMienGiamChangeStartAction, GanMienGiamChangeSuccessAction, GanMienGiamCloseFilterStudentModalAction, GanMienGiamLoadErrAction, GanMienGiamLoadHinhThucMienGiamStartAction, GanMienGiamLoadHinhThucMienGiamSuccessAction, GanMienGiamLoadHocSinhStartAction, GanMienGiamLoadHocSinhSuccessAction, GanMienGiamLoadStartAction, GanMienGiamLoadSuccessAction, GanMienGiamShowFilterStudentModalAction } from "../action-types/ganMienGiamActionTypes";

export const changeGanMienGiamFilter = (filter: NamHocTruongKhoiHeFilter): GanMienGiamChangeFilterAction => {
    return {
        type: GanMienGiamActionTypeIds.GAN_MIEN_GIAM_CHANGE_FILTER,
        payload: filter
    }
}


export const changeGanMienGiamFilterStudent = (filter: ganMienGiamFilterStudentModel): GanMienGiamChangeFilterStudentAction => {
    return {
        type: GanMienGiamActionTypeIds.GAN_MIEN_GIAM_CHANGE_FILTER_STUDENT,
        payload: filter
    }
}


export const showGanMienGiamFilterStudentModal = (): GanMienGiamShowFilterStudentModalAction => {
    return {
        type: GanMienGiamActionTypeIds.GAN_MIEN_GIAM_SHOW_FILTER_STUDENT_MODAL,
    }
}


export const closeGanMienGiamFilterStudentModal = (): GanMienGiamCloseFilterStudentModalAction => {
    return {
        type: GanMienGiamActionTypeIds.GAN_MIEN_GIAM_CLOSE_FILTER_STUDENT_MODAL,
    }
}


export const loadGanMienGiamStart = (filter: NamHocTruongKhoiHeFilter): GanMienGiamLoadStartAction => {
    return {
        type: GanMienGiamActionTypeIds.GAN_MIEN_GIAM_LOAD_START,
        payload: filter
    }
}

export const loadGanMienGiamSuccess = (sf_dexuatmiengiams: sf_dexuatmiengiam_viewmodel[]): GanMienGiamLoadSuccessAction => {
    return {
        type: GanMienGiamActionTypeIds.GAN_MIEN_GIAM_LOAD_SUCCESS,
        payload: sf_dexuatmiengiams
    }
}
export const loadGanMienGiamErr = (message: string): GanMienGiamLoadErrAction => {
    return {
        type: GanMienGiamActionTypeIds.GAN_MIEN_GIAM_LOAD_ERROR,
        payload: message
    }
}


export const loadGanMienGiamHinhThucMienGiamStart = (nam_hoc: string)
    : GanMienGiamLoadHinhThucMienGiamStartAction => {
    return {
        type: GanMienGiamActionTypeIds.GAN_MIEN_GIAM_LOAD_HINHTHUCMIENGIAM_START,
        payload: nam_hoc
    }
}

export const loadGanMienGiamHinhThucMienGiamSuccess = (sf_hinhthucmiengiams: sf_hinhthucmiengiam[])
    : GanMienGiamLoadHinhThucMienGiamSuccessAction => {
    return {
        type: GanMienGiamActionTypeIds.GAN_MIEN_GIAM_LOAD_HINHTHUCMIENGIAM_SUCCESS,
        payload: sf_hinhthucmiengiams
    }
}


export const loadGanMienGiamHocSinhStart = (filter: NamHocTruongKhoiHeFilter): GanMienGiamLoadHocSinhStartAction => {
    return {
        type: GanMienGiamActionTypeIds.GAN_MIEN_GIAM_LOAD_HOCSINH_START,
        payload: filter
    }
}

export const loadGanMienGiamHocSinhSuccess = (ts_hocsinhs: ts_hocsinh_viewmodel[]): GanMienGiamLoadHocSinhSuccessAction => {
    return {
        type: GanMienGiamActionTypeIds.GAN_MIEN_GIAM_LOAD_HOCSINH_SUCCESS,
        payload: ts_hocsinhs
    }
}


export const changeGanMienGiamStart = (request: sf_dexuatmiengiam_changed_rq): GanMienGiamChangeStartAction => {
    return {
        type: GanMienGiamActionTypeIds.GAN_MIEN_GIAM_CHANGE_START,
        payload: request
    }
}


export const changeGanMienGiamSuccess = (request: sf_dexuatmiengiam_changed_res): GanMienGiamChangeSuccessAction => {
    return {
        type: GanMienGiamActionTypeIds.GAN_MIEN_GIAM_CHANGE_SUCCESS,
        payload: request
    }
}

export const changeGanMienGiamErr = (message: string): GanMienGiamChangeErrAction => {
    return {
        type: GanMienGiamActionTypeIds.GAN_MIEN_GIAM_CHANGE_ERR,
        payload: message
    }
}

export const approveGanMienGiamStart = (request: sf_dexuatmiengiam_approve_rq): GanMienGiamApproveAllStartAction => {
    return {
        type: GanMienGiamActionTypeIds.GAN_MIEN_GIAM_APPROVE_ALL_START,
        payload: request
    }
}

export const approveGanMienGiamCancel = (): GanMienGiamApproveAllCancelAction => {
    return {
        type: GanMienGiamActionTypeIds.GAN_MIEN_GIAM_APPROVE_ALL_CANCEL
    }
}

export const approveGanMienGiamSubmit = (request: sf_dexuatmiengiam_approve_rq): GanMienGiamApproveAllSubmitAction => {
    return {
        type: GanMienGiamActionTypeIds.GAN_MIEN_GIAM_APPROVE_ALL_SUBMIT,
        payload: request
    }
}


export const approveGanMienGiamSuccess = (): GanMienGiamApproveAllSuccessAction => {
    return {
        type: GanMienGiamActionTypeIds.GAN_MIEN_GIAM_APPROVE_ALL_SUCCESS,
    }
}



export const approveGanMienGiamError = (message: string): GanMienGiamApproveAllErrorAction => {
    return {
        type: GanMienGiamActionTypeIds.GAN_MIEN_GIAM_APPROVE_ALL_ERR,
        payload: message
    }
}