import { sf_phanbophi_viewmodel } from "../../models/request/phan-bo-phi/sf_phan_bo_phi"
import { sf_mucphaithu_insert_rq } from "../../models/request/thu-phi/sf_mucphaithu_insert_from_khoannop_rq"
import { sf_mucphaithu } from "../../models/response/thu-phi/sf_mucphaithu"
import { sf_mucphaithu_chitiet_viewmodel } from "../../models/response/thu-phi/sf_mucphaithu_chitiet"
import { sf_mucphaithu_chitiet_miengiam_viewmodel } from "../../models/response/thu-phi/sf_mucphaithu_chitiet_miengiam"
import { sf_mucphaithu_miengiam } from "../../models/response/thu-phi/sf_mucphaithu_miengiam"
import { ThuPhiChiTietPageState } from "../page-state-model/thuPhiChiTietPageState"

export enum ThuPhiChiTietActionTypeIds {
    THUPHI_CHITIET_INITIAL = "THUPHI_CHITIET_INITIAL",

    THUPHI_CHITIET_LOAD_START = "THUPHI_CHITIET_LOAD_START",
    THUPHI_CHITIET_LOAD_SUCCESS = "THUPHI_CHITIET_LOAD_SUCCESS",
    THUPHI_CHITIET_LOAD_ERROR = "THUPHI_CHITIET_LOAD_ERROR",

    THUPHI_CHITIET_MIENGIAM_LOAD_START = "THUPHI_CHITIET_MIENGIAM_LOAD_START",
    THUPHI_CHITIET_MIENGIAM_LOAD_SUCCESS = "THUPHI_CHITIET_MIENGIAM_LOAD_SUCCESS",

    THUPHI_CHITIET_MIENGIAM_CHITIET_LOAD_START = "THUPHI_CHITIET_MIENGIAM_CHITIET_LOAD_START",
    THUPHI_CHITIET_MIENGIAM_CHITIET_LOAD_SUCCESS = "THUPHI_CHITIET_MIENGIAM_CHITIET_LOAD_SUCCESS",

    THUPHI_CHITIET_SHOW_DELETE_CONFIRM = "THUPHI_CHITIET_SHOW_DELETE_CONFIRM",
    THUPHI_CHITIET_CLOSE_DELETE_CONFIRM = "THUPHI_CHITIET_CLOSE_DELETE_CONFIRM",

    THUPHI_CHITIET_DELETE_KHOANNOP_START = "THUPHI_CHITIET_DELETE_KHOANNOP_START",
    THUPHI_CHITIET_DELETE_KHOANNOP_SUCCESS = "THUPHI_CHITIET_DELETE_KHOANNOP_SUCCESS",
    THUPHI_CHITIET_DELETE_KHOANNOP_ERR = "THUPHI_CHITIET_DELETE_KHOANNOP_ERR",

    THUPHI_CHITIET_LOAD_PHANBOPHI_START = "THUPHI_CHITIET_LOAD_PHANBOPHI_START",
    THUPHI_CHITIET_LOAD_PHANBOPHI_SUCCESS = "THUPHI_CHITIET_LOAD_PHANBOPHI_SUCCESS",
    THUPHI_CHITIET_LOAD_PHANBOPHI_ERROR = "THUPHI_CHITIET_LOAD_PHANBOPHI_ERROR",


    THUPHI_CHITIET_MUCPHAITHU_LOAD_START = "THUPHI_CHITIET_MUCPHAITHU_LOAD_START",
    THUPHI_CHITIET_MUCPHAITHU_LOAD_SUCCESS = "THUPHI_CHITIET_MUCPHAITHU_LOAD_SUCCESS",

}


export interface ThuPhiChiTietMucPhaiThuLoadStart {
    type: ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_MUCPHAITHU_LOAD_START,
    payload: number
}

export interface ThuPhiChiTietMucPhaiThuLoadSuccess {
    type: ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_MUCPHAITHU_LOAD_SUCCESS,
    payload: sf_mucphaithu
}


export interface ThuPhiChiTietPhanBoPhiLoadStart {
    type: ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_LOAD_PHANBOPHI_START,
    payload: number
}

export interface ThuPhiChiTietPhanBoPhiLoadSuccess {
    type: ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_LOAD_PHANBOPHI_SUCCESS,
    payload: sf_phanbophi_viewmodel[]

}

export interface ThuPhiChiTietPhanBoPhiLoadErr {
    type: ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_LOAD_PHANBOPHI_ERROR,
    payload: string

}

export interface ThuPhiChiTietMienGiamChiTietLoadStart {
    type: ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_MIENGIAM_CHITIET_LOAD_START,
    payload: number
}
export interface ThuPhiChiTietMienGiamChiTietLoadSuccess {
    type: ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_MIENGIAM_CHITIET_LOAD_SUCCESS,
    payload: sf_mucphaithu_chitiet_miengiam_viewmodel[]
}

export interface ThuPhiChiTietMienGiamLoadStart {
    type: ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_MIENGIAM_LOAD_START,
    payload: number
}
export interface ThuPhiChiTietMienGiamLoadSuccess {
    type: ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_MIENGIAM_LOAD_SUCCESS,
    payload: sf_mucphaithu_miengiam[]
}

export interface ThuPhiChiTietInitialAction {
    type: ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_INITIAL,
    payload: ThuPhiChiTietPageState
}

export interface ThuPhiChiTietLoadStart {
    type: ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_LOAD_START,
    payload: number
}


export interface ThuPhiChiTietLoadSuccess {
    type: ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_LOAD_SUCCESS,
    payload: sf_mucphaithu_chitiet_viewmodel[]
}


export interface ThuPhiChiTietLoadErr {
    type: ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_LOAD_ERROR,
    payload: string
}

export interface ThuPhiChiTietShowConfirmDeleteKhoanNop {
    type: ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_SHOW_DELETE_CONFIRM,
    payload: sf_mucphaithu_chitiet_viewmodel

}

export interface ThuPhiChiTietCloseConfirmDeleteKhoanNop {
    type: ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_CLOSE_DELETE_CONFIRM,

}

export interface ThuPhiChiTietDeleteKhoanNopStart {
    type: ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_DELETE_KHOANNOP_START,
    payload: sf_mucphaithu_chitiet_viewmodel

}

export interface ThuPhiChiTietDeleteKhoanNopSuccess {
    type: ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_DELETE_KHOANNOP_SUCCESS,

}

export interface ThuPhiChiTietDeleteKhoanNopErr {
    type: ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_DELETE_KHOANNOP_ERR,
    payload: string

}


export type ThuPhiChiTietActionTypes = ThuPhiChiTietInitialAction |
    ThuPhiChiTietLoadStart | ThuPhiChiTietLoadSuccess | ThuPhiChiTietLoadErr |
    ThuPhiChiTietMienGiamChiTietLoadStart | ThuPhiChiTietMienGiamChiTietLoadSuccess |
    ThuPhiChiTietMienGiamLoadStart | ThuPhiChiTietMienGiamLoadSuccess |
    ThuPhiChiTietShowConfirmDeleteKhoanNop | ThuPhiChiTietCloseConfirmDeleteKhoanNop |
    ThuPhiChiTietDeleteKhoanNopStart | ThuPhiChiTietDeleteKhoanNopSuccess | ThuPhiChiTietDeleteKhoanNopErr |
    ThuPhiChiTietPhanBoPhiLoadStart | ThuPhiChiTietPhanBoPhiLoadSuccess | ThuPhiChiTietPhanBoPhiLoadErr |
    ThuPhiChiTietMucPhaiThuLoadStart | ThuPhiChiTietMucPhaiThuLoadSuccess 