
import { dm_he } from "../../models/response/category/dm_he";
import { dm_khoi } from "../../models/response/category/dm_khoi";
import { dm_namhoc } from "../../models/response/category/dm_namhoc";
import { dm_truong } from "../../models/response/category/dm_truong";
import { dm_truong_khoi_he } from "../../models/response/category/dm_truong_khoi_he";
import { sf_hinhthucnopbom } from "../../models/response/category/sf_hinhthucnopbom";
import {
    CategorySourceActionTypeIds, DmHeLoadStartAction, DmHeLoadSuccessAction,
    DmKhoiLoadStartAction, DmKhoiLoadSuccessAction, DmNamHocLoadStartAction,
    DmNamHocLoadSuccesAction, DmTruongKhoiHeLoadStartAction, DmTruongKhoiHeLoadSucessAction, DmTruongLoadStartAction, DmTruongLoadSuccessAction,
    DmTruongTreeViewLoadStartAction, DmTruongTreeViewLoadSuccessAction, SfHinhThucNopBomLoadStartAction, SfHinhThucNopBomLoadSuccessAction
} from "../action-types/categorySourceActionTypes";
import { dm_truong_treeview } from "../page-state-model/categorySourceState";

export const loadDmTruongStartAction = (): DmTruongLoadStartAction => {
    return {
        type: CategorySourceActionTypeIds.DM_TRUONG_LOAD_START
    }
}
export const loadDmTruongSuccessAction = (payload: dm_truong[]): DmTruongLoadSuccessAction => {
    return {
        type: CategorySourceActionTypeIds.DM_TRUONG_LOAD_SUCCESS,
        payload: payload
    }
}
export const loadHeStartAction = (): DmHeLoadStartAction => {
    return {
        type: CategorySourceActionTypeIds.DM_HE_LOAD_START
    }
}
export const loadHeSuccessAction = (payload: dm_he[]): DmHeLoadSuccessAction => {
    return {
        type: CategorySourceActionTypeIds.DM_HE_LOAD_SUCCESS,
        payload: payload
    }
}
export const loadKhoiStartAction = (): DmKhoiLoadStartAction => {
    return {
        type: CategorySourceActionTypeIds.DM_KHOI_LOAD_START
    }
}
export const loadKhoiSucessAction = (payload: dm_khoi[]): DmKhoiLoadSuccessAction => {
    return {
        type: CategorySourceActionTypeIds.DM_KHOI_LOAD_SUCCESS,
        payload: payload
    }
}

export const loadNamHocStartAction = (): DmNamHocLoadStartAction => {
    return {
        type: CategorySourceActionTypeIds.DM_NAMHOC_LOAD_START
    }
}
export const loadNamHocSuccessAction = (payload: dm_namhoc[]): DmNamHocLoadSuccesAction => {
    return {
        type: CategorySourceActionTypeIds.DM_NAMHOC_LOAD_SUCESS,
        payload: payload
    }
}
export const loadDmTruongTreeViewLoadStartAction = (): DmTruongTreeViewLoadStartAction => {
    return {
        type: CategorySourceActionTypeIds.DM_TRUONG_TREEVIEW_LOAD_START
    }
}
export const loadDmTruongTreeViewLoadSuccessAction = (payload: dm_truong_treeview[]): DmTruongTreeViewLoadSuccessAction => {
    return {
        type: CategorySourceActionTypeIds.DM_TRUONG_TREEVIEW_LOAD_SUCCESS,
        payload: payload
    }
}

export const loadDmTruongKhoiHeStartAction = (): DmTruongKhoiHeLoadStartAction => {
    return {
        type: CategorySourceActionTypeIds.DM_TRUONG_KHOI_HE_LOAD_START
    }
}
export const loadDmTruongKhoiHeSuccessAction = (payload: dm_truong_khoi_he[]): DmTruongKhoiHeLoadSucessAction => {
    return {
        type: CategorySourceActionTypeIds.DM_TRUONG_KHOI_HE_LOAD_SUCESS,
        payload: payload
    }
}


export const loadSfHinhThucNopBomStartAction = (): SfHinhThucNopBomLoadStartAction => {
    return {
        type: CategorySourceActionTypeIds.SF_HINHTHUCNOPBOM_LOAD_START
    }
}
export const loadSfHinhThucNopBomSuccessAction = (payload: sf_hinhthucnopbom[]): SfHinhThucNopBomLoadSuccessAction => {
    return {
        type: CategorySourceActionTypeIds.SF_HINHTHUCNOPBOM_LOAD_SUCCESS,
        payload: payload
    }
}