import React, { lazy, Suspense } from 'react';
export const Loadable = (importFunc: any, fallback: any) => {
  const LazyComponent = lazy(importFunc);

  return (props: any) => (
    <Suspense fallback={fallback}>
      <LazyComponent {...props} />
    </Suspense>
  );
};
