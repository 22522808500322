import { NamHocTruongKhoiHeFilter } from "../../models/namHocTruongKhoiHeFilter"
import { sf_hachtoan_congno, sf_hachtoan_congno_viewmodel } from "../../models/response/hach-toan-cong-no/sf_hachtoan_congno"
import {
    HachToanCongNoActionTypeIds,
    HachToanCongNoLoadStartAction, HachToanCongNoLoadSuccessAction, HachToanCongNoLoadErrorAction, HachToanCongNoShowDetailModalAction,
    HachToanCongNoSaveStartAction, HachToanCongNoSaveSuccessAction, HachToanCongNoSaveErrorAction,
    HachToanCongNoDeleteStartAction, HachToanCongNoDeleteSuccessAction, HachToanCongNoDeleteErrorAction,
    HachToanCongNoChangeSelectedIdsAction, HachToanCongNoShowDeleteConfirmAction,
    HachToanCongNoCloseDetailModalAction, HachToanCongNoCloseDeleteConfirmAction, HachToanCongNoChangeFilterAction, HachToanCongNoShowImportModal, HachToanCongNoCloseImportModal
} from "../action-types/hachToanCongNoActionTypes"

export const loadHachToanCongNoStart = (filter: NamHocTruongKhoiHeFilter): HachToanCongNoLoadStartAction => {
    return {
        type: HachToanCongNoActionTypeIds.HACHTOANCONGNO_LOAD_START,
        payload: filter
    }
}

export const loadHachToanCongNoSuccess = (payload: sf_hachtoan_congno_viewmodel[]): HachToanCongNoLoadSuccessAction => {
    return {
        type: HachToanCongNoActionTypeIds.HACHTOANCONGNO_LOAD_SUCCESS,
        payload: payload
    }
}
export const loadHachToanCongNoError = (payload: string): HachToanCongNoLoadErrorAction => {
    return {
        type: HachToanCongNoActionTypeIds.HACHTOANCONGNO_LOAD_ERROR,
        payload: payload
    }
}

export const showHachToanCongNoDetailModal = (payload?: sf_hachtoan_congno_viewmodel): HachToanCongNoShowDetailModalAction => {
    return {
        type: HachToanCongNoActionTypeIds.HACHTOANCONGNO_SHOW_DETAIL_MODAL,
        payload: payload
    }
}
export const closeHachToanCongNoDetailModal = (): HachToanCongNoCloseDetailModalAction => {
    return {
        type: HachToanCongNoActionTypeIds.HACHTOANCONGNO_CLOSE_DETAIL_MODAL,
    }
}

export const showHachToanCongNoDeleteConfirm = (): HachToanCongNoShowDeleteConfirmAction => {
    return {
        type: HachToanCongNoActionTypeIds.HACHTOANCONGNO_SHOW_DELETE_CONFIRM,
    }
}
export const closeHachToanCongNoDeleteConfirm = (): HachToanCongNoCloseDeleteConfirmAction => {
    return {
        type: HachToanCongNoActionTypeIds.HACHTOANCONGNO_CLOSE_DELETE_CONFIRM,
    }
}

export const saveHachToanCongNoStart = (payload: sf_hachtoan_congno): HachToanCongNoSaveStartAction => {
    return {
        type: HachToanCongNoActionTypeIds.HACHTOANCONGNO_SAVE_START,
        payload: payload
    }
}

export const saveHachToanCongNoSuccess = (payload: sf_hachtoan_congno): HachToanCongNoSaveSuccessAction => {
    return {
        type: HachToanCongNoActionTypeIds.HACHTOANCONGNO_SAVE_SUCCESS,
        payload: payload
    }
}
export const saveHachToanCongNoError = (payload: string): HachToanCongNoSaveErrorAction => {
    return {
        type: HachToanCongNoActionTypeIds.HACHTOANCONGNO_SAVE_ERROR,
        payload: payload
    }
}

export const deleteHachToanCongNoStart = (payload: number[]): HachToanCongNoDeleteStartAction => {
    return {
        type: HachToanCongNoActionTypeIds.HACHTOANCONGNO_DELETE_START,
        payload: payload
    }
}

export const deleteHachToanCongNoSuccess = (payload: number[]): HachToanCongNoDeleteSuccessAction => {
    return {
        type: HachToanCongNoActionTypeIds.HACHTOANCONGNO_DELETE_SUCCESSS,
        payload: payload
    }
}
export const deleteHachToanCongNoError = (payload: string): HachToanCongNoDeleteErrorAction => {
    return {
        type: HachToanCongNoActionTypeIds.HACHTOANCONGNO_DELETE_ERROR,
        payload: payload
    }
}

export const changeHachToanCongNoSelectionIds = (payload: number[]): HachToanCongNoChangeSelectedIdsAction => {
    return {
        type: HachToanCongNoActionTypeIds.HACHTOANCONGNO_CHANGE_SELECTED_IDS,
        payload: payload
    }
}

export const changeHachToanCongNoFilter = (filter: NamHocTruongKhoiHeFilter): HachToanCongNoChangeFilterAction => {
    return {
        type: HachToanCongNoActionTypeIds.HACHTOANCONGNO_CHANGE_FILTER,
        payload: filter
    }
}

export const showHachToanCongNoImportModal = (): HachToanCongNoShowImportModal => {
    return {
        type: HachToanCongNoActionTypeIds.HACHTOANCONGNO_SHOW_IMPORT_MODEL
    }
}

export const closeHachToanCongNoImportModal = (): HachToanCongNoCloseImportModal => {
    return {
        type: HachToanCongNoActionTypeIds.HACHTOANCONGNO_CLOSE_IMPORT_MODEL
    }
}