import { showNotify } from "../../../helpers/toast";
import { PageBaseStatus } from "../../../models/pageBaseStatus";
import { EditTBTPKhoanNopActionTypeIds, EditTBTPKhoanNopActionTypes } from "../../action-types/edit-tbtp/editTBTPKhoanNopActionTypes";
import { EditTBTPKhoanNopPageState } from "../../page-state-model/edit-tbtp/editTBTPKhoanNopPageState";

const initialState: EditTBTPKhoanNopPageState = {
    is_show_confirm_remove_khoannop: false,
    is_show_modal_add_khoannop: false,
    sf_hinhthucnopboms: [],
    sf_khoannop_editing_id: 0,
    sf_tbtp_khoannops: [],
    sf_tbtp_viewmodel: undefined,
    status: PageBaseStatus.is_completed
}
export const editTBTPKhoanNopReducer = (state: EditTBTPKhoanNopPageState = initialState, action: EditTBTPKhoanNopActionTypes): EditTBTPKhoanNopPageState => {
    switch (action.type) {
        case EditTBTPKhoanNopActionTypeIds.LOAD_KHOANNOP_START:
            return {
                ...state,
                status: PageBaseStatus.is_loading,
            }
        case EditTBTPKhoanNopActionTypeIds.LOAD_KHOANNOP_SUCCESS:
            return {
                ...state,
                status: PageBaseStatus.is_completed,
                sf_tbtp_khoannops: action.payload
            }
        case EditTBTPKhoanNopActionTypeIds.LOAD_KHOANNOP_ERROR:
            showNotify({ message: "Không tải được danh sách khoản nộp", type: "warning" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }
        case EditTBTPKhoanNopActionTypeIds.LOAD_HINHTHUCNOPBOM_START:
            return {
                ...state
            }
        case EditTBTPKhoanNopActionTypeIds.LOAD_HINHTHUCNOPBOM_SUCCESS:
            return {
                ...state,
                sf_hinhthucnopboms: action.payload
            }
        case EditTBTPKhoanNopActionTypeIds.LOAD_HINHTHUCNOPBOM_ERROR:
            showNotify({ message: "Không tải được danh sách hình thức nộp BOM", type: "warning" })
            return {
                ...state,
            }
        case EditTBTPKhoanNopActionTypeIds.SHOW_DELETE_CONFIRM:
            return {
                ...state,
                sf_khoannop_editing_id: action.payload,
                is_show_confirm_remove_khoannop: true
            }
        case EditTBTPKhoanNopActionTypeIds.CLOSE_DELETE_CONFIRM:
            return {
                ...state,
                is_show_confirm_remove_khoannop: false
            }
        case EditTBTPKhoanNopActionTypeIds.KHOANNOP_DELETE_START:
            return {
                ...state,
                status: PageBaseStatus.is_deleting
            }
        case EditTBTPKhoanNopActionTypeIds.KHOANNOP_DELETE_SUCCESS:
            showNotify({ message: "Đã bỏ khoản nộp khỏi BOM", type: "success" })
            return {
                ...state,
                status: PageBaseStatus.is_need_reload,
                is_show_confirm_remove_khoannop: false

            }
        case EditTBTPKhoanNopActionTypeIds.KHOANNOP_DELETE_ERROR:
            showNotify({ message: "Không bỏ được khoản nộp khỏi BOM", type: "warning" })
            return {
                ...state
            }
        case EditTBTPKhoanNopActionTypeIds.KHOANNOP_SHOW_ADD_MODAL:
            return {
                ...state,
                is_show_modal_add_khoannop: true
            }
        case EditTBTPKhoanNopActionTypeIds.KHOANNOP_CLOSE_ADD_MODAL:
            return {
                ...state,
                is_show_modal_add_khoannop: false
            }
        case EditTBTPKhoanNopActionTypeIds.KHOANNOP_ADD_START:
            return {
                ...state,
                status: PageBaseStatus.is_saving
            }
        case EditTBTPKhoanNopActionTypeIds.KHOANNOP_ADD_SUCCESS:
            showNotify({ message: "Thêm khoản nộp thành công", type: "success" })

            return {
                ...state,
                status: PageBaseStatus.is_need_reload,
                is_show_modal_add_khoannop: false
            }
        case EditTBTPKhoanNopActionTypeIds.KHOANNOP_ADD_ERROR:
            showNotify({ message: "Thêm khoản nộp không thành công", type: "warning" })
            return {
                ...state,
                status: PageBaseStatus.is_completed
            }

        default:
            return state;
    }
}