import { sf_bom_chitiet_remove_khoannop_rq } from "../../models/request/bom/sf_bom_chitiet_remove_khoannop_rq"
import { sf_bom_chitiet_res, sf_bom_chitiet_viewmodel } from "../../models/response/bom/sf_bom_chitiet"
import { sf_bom_hinhthucnopbom_viewmodel } from "../../models/response/bom/sf_bom_hinhthucnopbom"

export enum BomChiTietActionTypeIds {
    BOMCHITIET_LOAD_START = "BOMCHITIET_LOAD_START",
    BOMCHITIET_LOAD_ERR = "BOMCHITIET_LOAD_ERR",
    BOMCHITIET_LOAD_SUCCESS = "BOMCHITIET_LOAD_SUCCESS",

    BOMCHITIET_SHOW_KHOANNOP_MODAL = "BOMCHITIET_SHOW_KHOANNOP_MODAL",
    BOMCHITIET_CLOSE_KHOANNOP_MODAL = "BOMCHITIET_CLOSE_KHOANNOP_MODAL",

    BOMCHITIET_CHANGE_SELECTED_IDS = "BOMCHITIET_CHANGE_SELECTED_IDS",

    BOMCHITIET_SHOW_DELETE_CONFIRM = "BOMCHITIET_SHOW_DELETE_CONFIRM",
    BOMCHITIET_CLOSE_DELETE_CONFIRM = "BOMCHITIET_CLOSE_DELETE_CONFIRM",
    BOMCHITIET_DELETE_START = "BOMCHITIET_DELETE_START",
    BOMCHITIET_DELETE_SUCCESS = "BOMCHITIET_DELETE_SUCCESS",
    BOMCHITIET_DELETE_ERRROR = "BOMCHITIET_DELETE_ERRROR",


}
export interface BomChiTietLoadStartAction {
    type: BomChiTietActionTypeIds.BOMCHITIET_LOAD_START,
    payload: number
}
export interface BomChiTietLoadErrAction {
    type: BomChiTietActionTypeIds.BOMCHITIET_LOAD_ERR,
    payload: string
}

export interface BomChiTietLoadSuccessAction {
    type: BomChiTietActionTypeIds.BOMCHITIET_LOAD_SUCCESS,
    payload: sf_bom_chitiet_res
}

export interface BomChiTietShowKhoanNopModal {
    type: BomChiTietActionTypeIds.BOMCHITIET_SHOW_KHOANNOP_MODAL,
    payload: sf_bom_hinhthucnopbom_viewmodel
}

export interface BomChiTietCloseKhoanNopModal {
    type: BomChiTietActionTypeIds.BOMCHITIET_CLOSE_KHOANNOP_MODAL
}

export interface BomChiTietChangeSelectedIds {
    type: BomChiTietActionTypeIds.BOMCHITIET_CHANGE_SELECTED_IDS,
    payload: number[]
}


export interface BomChiTietShowDeleteConfrim {
    type: BomChiTietActionTypeIds.BOMCHITIET_SHOW_DELETE_CONFIRM,
}

export interface BomChiTietCloseDeleteConfrim {
    type: BomChiTietActionTypeIds.BOMCHITIET_CLOSE_DELETE_CONFIRM,
}


export interface BomChiTietDeleteStart {
    type: BomChiTietActionTypeIds.BOMCHITIET_DELETE_START,
    payload: sf_bom_chitiet_remove_khoannop_rq
}


export interface BomChiTietDeleteSuccess {
    type: BomChiTietActionTypeIds.BOMCHITIET_DELETE_SUCCESS,
}


export interface BomChiTietDeleteError {
    type: BomChiTietActionTypeIds.BOMCHITIET_DELETE_ERRROR,
    payload: string
}

export type BomChiTietActionTypes =
    BomChiTietLoadStartAction | BomChiTietLoadSuccessAction | BomChiTietLoadErrAction |
    BomChiTietShowKhoanNopModal | BomChiTietCloseKhoanNopModal |
    BomChiTietChangeSelectedIds |
    BomChiTietShowDeleteConfrim | BomChiTietCloseDeleteConfrim |
    BomChiTietDeleteStart | BomChiTietDeleteSuccess | BomChiTietDeleteError