import { sf_ngaynghi_rq } from "../../models/response/category/sf_ngaynghi"
import {
    NgayNghiActionTypeIds, NgayNghiChangeSelectedIdsAction, NgayNghiCloseDeleteConfirmAction, NgayNghiCloseDetailModalAction, NgayNghiDeleteErrorAction, NgayNghiDeleteStartAction, NgayNghiDeleteSuccessAction, NgayNghiLoadErrorAction, NgayNghiLoadStartAction, NgayNghiLoadSuccessAction, NgayNghiSaveErrorAction, NgayNghiSaveStartAction, NgayNghiSaveSuccessAction, NgayNghiShowDeleteConfirmAction, NgayNghiShowDetailModalAction
} from "../action-types/ngayNghiActionTypes"

export const loadNgayNghiStart = (): NgayNghiLoadStartAction => {
    return {
        type: NgayNghiActionTypeIds.NGAYNGHI_LOAD_START
    }
}

export const loadNgayNghiSuccess = (payload: sf_ngaynghi_rq[]): NgayNghiLoadSuccessAction => {
    return {
        type: NgayNghiActionTypeIds.NGAYNGHI_LOAD_SUCCESS,
        payload: payload
    }
}
export const loadNgayNghiError = (payload: string): NgayNghiLoadErrorAction => {
    return {
        type: NgayNghiActionTypeIds.NGAYNGHI_LOAD_ERROR,
        payload: payload
    }
}

export const showNgayNghiDetailModal = (payload?: sf_ngaynghi_rq): NgayNghiShowDetailModalAction => {
    return {
        type: NgayNghiActionTypeIds.NGAYNGHI_SHOW_DETAIL_MODAL,
        payload: payload
    }
}
export const closeNgayNghiDetailModal = (): NgayNghiCloseDetailModalAction => {
    return {
        type: NgayNghiActionTypeIds.NGAYNGHI_CLOSE_DETAIL_MODAL,
    }
}

export const showNgayNghiDeleteConfirm = (): NgayNghiShowDeleteConfirmAction => {
    return {
        type: NgayNghiActionTypeIds.NGAYNGHI_SHOW_DELETE_CONFIRM,
    }
}
export const closeNgayNghiDeleteConfirm = (): NgayNghiCloseDeleteConfirmAction => {
    return {
        type: NgayNghiActionTypeIds.NGAYNGHI_CLOSE_DELETE_CONFIRM,
    }
}

export const saveNgayNghiStart = (payload: sf_ngaynghi_rq): NgayNghiSaveStartAction => {
    return {
        type: NgayNghiActionTypeIds.NGAYNGHI_SAVE_START,
        payload: payload
    }
}

export const saveNgayNghiSuccess = (payload: sf_ngaynghi_rq): NgayNghiSaveSuccessAction => {
    return {
        type: NgayNghiActionTypeIds.NGAYNGHI_SAVE_SUCCESS,
        payload: payload
    }
}
export const saveNgayNghiError = (payload: string): NgayNghiSaveErrorAction => {
    return {
        type: NgayNghiActionTypeIds.NGAYNGHI_SAVE_ERROR,
        payload: payload
    }
}

export const deleteNgayNghiStart = (payload: number[]): NgayNghiDeleteStartAction => {
    return {
        type: NgayNghiActionTypeIds.NGAYNGHI_DELETE_START,
        payload: payload
    }
}

export const deleteNgayNghiSuccess = (payload: number[]): NgayNghiDeleteSuccessAction => {
    return {
        type: NgayNghiActionTypeIds.NGAYNGHI_DELETE_SUCCESSS,
        payload: payload
    }
}
export const deleteNgayNghiError = (payload: string): NgayNghiDeleteErrorAction => {
    return {
        type: NgayNghiActionTypeIds.NGAYNGHI_DELETE_ERROR,
        payload: payload
    }
}

export const changeNgayNghiSelectionIds = (payload: number[]): NgayNghiChangeSelectedIdsAction => {
    return {
        type: NgayNghiActionTypeIds.NGAYNGHI_CHANGE_SELECTED_IDS,
        payload: payload
    }
}