import { call, put, takeEvery } from "redux-saga/effects"
import { hachToanCongNoApi } from "../../../api/hachToanCongNopApi"
import { tbtpApi } from "../../../api/tbtpApi"
import { IBaseResponeModel } from "../../../models/response/base-response"
import { EditTBTPHachToanActionIds, EditTBTPHachToanDeleteStart, EditTBTPHachToanLoadHachToanAddedStart, EditTBTPHachToanLoadHachToanPendingStart } from "../../action-types/edit-tbtp/editTBTPHachToanActionTypes"
import { deleteEditTBTPHachToanError, deleteEditTBTPHachToanSuccess, loadEditTBTPHachToanAddedError, loadEditTBTPHachToanAddedSuccess, loadEditTBTPHachToanPendingError, loadEditTBTPHachToanPendingSuccess } from "../../actions/edit-tbtp/editTBTPHachToanActions"

export function* editTBTPHachToanSagas(): any {
    yield takeEvery(EditTBTPHachToanActionIds.EDIT_TBTP_HACHTOAN_LOAD_HACHTOAN_ADDED_START, loadEditTBTPHachToanAddedStart)
    yield takeEvery(EditTBTPHachToanActionIds.EDIT_TBTP_HACHTOAN_LOAD_HACHTOAN_PENDING_START, loadEditTBTPHachToanPendingStart)
    yield takeEvery(EditTBTPHachToanActionIds.EDIT_TBTP_HACHTOAN_DELTE_START, deleteEditTBTPHachToanStartWorker)
}
function* loadEditTBTPHachToanAddedStart(data: EditTBTPHachToanLoadHachToanAddedStart) {
    const res: IBaseResponeModel = yield call(tbtpApi.select_hachtoans,data.payload)
    if (res.is_success) {
        yield put(loadEditTBTPHachToanAddedSuccess(res.data))
    } else {
        yield put(loadEditTBTPHachToanAddedError(res.message || ""))
    }
}

function* loadEditTBTPHachToanPendingStart(data: EditTBTPHachToanLoadHachToanPendingStart) {
    const res: IBaseResponeModel = yield call(hachToanCongNoApi.select_by_hocsinh,data.payload)
    if (res.is_success) {
        yield put(loadEditTBTPHachToanPendingSuccess(res.data))
    } else {
        yield put(loadEditTBTPHachToanPendingError(res.message || ""))
    }
}


function* deleteEditTBTPHachToanStartWorker(data: EditTBTPHachToanDeleteStart) {
    const res: IBaseResponeModel = yield call(tbtpApi.remove_hachtoan,data.payload)
    if (res.is_success) {
        yield put(deleteEditTBTPHachToanSuccess())
    } else {
        yield put(deleteEditTBTPHachToanError(res.message || ""))
    }
}