import { showNotify } from "../../helpers/toast";
import { PageBaseStatus } from "../../models/pageBaseStatus";
import { NgayNghiActionTypeIds, NgayNghiActionTypes } from "../action-types/ngayNghiActionTypes";
import { NgayNghiPageState } from "../page-state-model/ngayNghiPageState";
const initialState: NgayNghiPageState = {
    sf_ngaynghis: [],
    sf_ngaynghi_selected_ids: [],
    status: PageBaseStatus.is_completed
}
export const ngayNghiReducer = (state: NgayNghiPageState = initialState, action: NgayNghiActionTypes): NgayNghiPageState => {
    switch (action.type) {
        case NgayNghiActionTypeIds.NGAYNGHI_LOAD_START:
            return {
                ...state,
                status: PageBaseStatus.is_loading
            }
        case NgayNghiActionTypeIds.NGAYNGHI_LOAD_SUCCESS:
            return {
                ...state,
                status: PageBaseStatus.is_completed,
                sf_ngaynghis: action.payload
            }
        case NgayNghiActionTypeIds.NGAYNGHI_LOAD_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }
        case NgayNghiActionTypeIds.NGAYNGHI_SHOW_DETAIL_MODAL:
            return {
                ...state,
                is_show_detail_modal: true,
                sf_ngaynghi_editing: action.payload
            }
        case NgayNghiActionTypeIds.NGAYNGHI_CLOSE_DETAIL_MODAL:
            return {
                ...state,
                is_show_detail_modal: false,
                sf_ngaynghi_editing: undefined
            }
        case NgayNghiActionTypeIds.NGAYNGHI_SHOW_DELETE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: true
            }
        case NgayNghiActionTypeIds.NGAYNGHI_CLOSE_DELETE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: false
            }
        case NgayNghiActionTypeIds.NGAYNGHI_SAVE_START:
            return {
                ...state,
                status: PageBaseStatus.is_saving
            }
        case NgayNghiActionTypeIds.NGAYNGHI_SAVE_SUCCESS:
            showNotify({
                message: `${action.payload.id > 0 ? "Cập nhật " : "Thêm mới "} thành công ngày nghỉ ${action.payload.noi_dung}`,
                type: "success"
            })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
                sf_ngaynghi_editing: undefined,
                is_show_detail_modal: false
            }
        case NgayNghiActionTypeIds.NGAYNGHI_SAVE_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }

        case NgayNghiActionTypeIds.NGAYNGHI_DELETE_START:
            return {
                ...state,
                status: PageBaseStatus.is_deleting
            }
        case NgayNghiActionTypeIds.NGAYNGHI_DELETE_SUCCESSS:
            showNotify({ message: `Xóa thành công ${action.payload.length} ngày nghỉ`, type: "success" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
                is_show_delete_confirm: false
            }
        case NgayNghiActionTypeIds.NGAYNGHI_DELETE_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }
        case NgayNghiActionTypeIds.NGAYNGHI_CHANGE_SELECTED_IDS:
            return {
                ...state,
                sf_ngaynghi_selected_ids: action.payload
            }
        default:
            return state;
    }
}