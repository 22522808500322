import { sf_ngaynghi_rq } from "../../models/response/category/sf_ngaynghi"
export enum NgayNghiActionTypeIds {
    NGAYNGHI_LOAD_START = "NGAYNGHI_LOAD_START",
    NGAYNGHI_LOAD_SUCCESS = "NGAYNGHI_LOAD_SUCCESS",
    NGAYNGHI_LOAD_ERROR = "NGAYNGHI_LOAD_ERROR",

    NGAYNGHI_SHOW_DETAIL_MODAL = "NGAYNGHI_SHOW_DETAIL_MODAL",
    NGAYNGHI_CLOSE_DETAIL_MODAL = "NGAYNGHI_CLOSE_DETAIL_MODAL",

    NGAYNGHI_SHOW_DELETE_CONFIRM = "NGAYNGHI_SHOW_DELETE_CONFIRM",
    NGAYNGHI_CLOSE_DELETE_CONFIRM = "NGAYNGHI_CLOSE_DELETE_CONFIRM",

    NGAYNGHI_SAVE_START = "NGAYNGHI_SAVE_START",
    NGAYNGHI_SAVE_SUCCESS = "NGAYNGHI_SAVE_SUCCESS",
    NGAYNGHI_SAVE_ERROR = "NGAYNGHI_SAVE_ERROR",

    NGAYNGHI_DELETE_START = "NGAYNGHI_DELETE_START",
    NGAYNGHI_DELETE_SUCCESSS = "NGAYNGHI_DELETE_SUCCESSS",
    NGAYNGHI_DELETE_ERROR = "NGAYNGHI_DELETE_ERROR",

    NGAYNGHI_CHANGE_SELECTED_IDS = "NGAYNGHI_CHANGE_SELECTED_IDS"

}

export interface NgayNghiLoadStartAction {
    type: NgayNghiActionTypeIds.NGAYNGHI_LOAD_START
}
export interface NgayNghiLoadSuccessAction {
    type: NgayNghiActionTypeIds.NGAYNGHI_LOAD_SUCCESS,
    payload: sf_ngaynghi_rq[]
}
export interface NgayNghiLoadErrorAction {
    type: NgayNghiActionTypeIds.NGAYNGHI_LOAD_ERROR,
    payload: string
}

export interface NgayNghiShowDetailModalAction {
    type: NgayNghiActionTypeIds.NGAYNGHI_SHOW_DETAIL_MODAL,
    payload?: sf_ngaynghi_rq
}
export interface NgayNghiCloseDetailModalAction {
    type: NgayNghiActionTypeIds.NGAYNGHI_CLOSE_DETAIL_MODAL,
}
export interface NgayNghiShowDeleteConfirmAction {
    type: NgayNghiActionTypeIds.NGAYNGHI_SHOW_DELETE_CONFIRM,
}
export interface NgayNghiCloseDeleteConfirmAction {
    type: NgayNghiActionTypeIds.NGAYNGHI_CLOSE_DELETE_CONFIRM,
}

export interface NgayNghiSaveStartAction {
    type: NgayNghiActionTypeIds.NGAYNGHI_SAVE_START,
    payload: sf_ngaynghi_rq
}
export interface NgayNghiSaveSuccessAction {
    type: NgayNghiActionTypeIds.NGAYNGHI_SAVE_SUCCESS,
    payload: sf_ngaynghi_rq
}
export interface NgayNghiSaveErrorAction {
    type: NgayNghiActionTypeIds.NGAYNGHI_SAVE_ERROR,
    payload: string
}
export interface NgayNghiDeleteStartAction {
    type: NgayNghiActionTypeIds.NGAYNGHI_DELETE_START,
    payload: number[]
}
export interface NgayNghiDeleteSuccessAction {
    type: NgayNghiActionTypeIds.NGAYNGHI_DELETE_SUCCESSS,
    payload: number[]
}
export interface NgayNghiDeleteErrorAction {
    type: NgayNghiActionTypeIds.NGAYNGHI_DELETE_ERROR,
    payload: string
}

export interface NgayNghiChangeSelectedIdsAction {
    type: NgayNghiActionTypeIds.NGAYNGHI_CHANGE_SELECTED_IDS,
    payload: number[]
}
export type NgayNghiActionTypes =
    NgayNghiLoadStartAction | NgayNghiLoadSuccessAction | NgayNghiLoadErrorAction |
    NgayNghiShowDetailModalAction | NgayNghiShowDeleteConfirmAction |
    NgayNghiCloseDetailModalAction | NgayNghiCloseDeleteConfirmAction |
    NgayNghiSaveStartAction | NgayNghiSaveSuccessAction | NgayNghiSaveErrorAction |
    NgayNghiDeleteStartAction | NgayNghiDeleteSuccessAction | NgayNghiDeleteErrorAction |
    NgayNghiChangeSelectedIdsAction