import { sf_hinhthucmiengiam } from "../../models/response/category/sf_hinhthucmiengiam"
import { sf_hinhthucmiengiam_hinhthuctinh } from "../../models/response/category/sf_hinhthucmiengiam_hinhthuctinh"
export enum HinhThucMienGiamActionTypeIds {
    HINHTHUCMIENGIAM_LOAD_START = "HINHTHUCMIENGIAM_LOAD_START",
    HINHTHUCMIENGIAM_LOAD_SUCCESS = "HINHTHUCMIENGIAM_LOAD_SUCCESS",
    HINHTHUCMIENGIAM_LOAD_ERROR = "HINHTHUCMIENGIAM_LOAD_ERROR",

    HINHTHUCMIENGIAM_SHOW_DETAIL_MODAL = "HINHTHUCMIENGIAM_SHOW_DETAIL_MODAL",
    HINHTHUCMIENGIAM_CLOSE_DETAIL_MODAL = "HINHTHUCMIENGIAM_CLOSE_DETAIL_MODAL",

    HINHTHUCMIENGIAM_SHOW_DELETE_CONFIRM = "HINHTHUCMIENGIAM_SHOW_DELETE_CONFIRM",
    HINHTHUCMIENGIAM_CLOSE_DELETE_CONFIRM = "HINHTHUCMIENGIAM_CLOSE_DELETE_CONFIRM",

    HINHTHUCMIENGIAM_SAVE_START = "HINHTHUCMIENGIAM_SAVE_START",
    HINHTHUCMIENGIAM_SAVE_SUCCESS = "HINHTHUCMIENGIAM_SAVE_SUCCESS",
    HINHTHUCMIENGIAM_SAVE_ERROR = "HINHTHUCMIENGIAM_SAVE_ERROR",

    HINHTHUCMIENGIAM_DELETE_START = "HINHTHUCMIENGIAM_DELETE_START",
    HINHTHUCMIENGIAM_DELETE_SUCCESSS = "HINHTHUCMIENGIAM_DELETE_SUCCESSS",
    HINHTHUCMIENGIAM_DELETE_ERROR = "HINHTHUCMIENGIAM_DELETE_ERROR",

    HINHTHUCMIENGIAM_CHANGE_SELECTED_IDS = "HINHTHUCMIENGIAM_CHANGE_SELECTED_IDS",

    HINHTHUCMIENGIAM_HINHTHUCTINH_LOAD_START = "HINHTHUCMIENGIAM_HINHTHUCTINH_LOAD_START",
    HINHTHUCMIENGIAM_HINHTHUCTINH_LOAD_SUCCESS = "HINHTHUCMIENGIAM_HINHTHUCTINH_LOAD_SUCCESS",

    HINHTHUCMIENGIAM_CHANGE_NAMHOC = "HINHTHUCMIENGIAM_CHANGE_NAMHOC",

    HINHTHUCMIENGIAM_SHOW_ITEMS_MODAL = "HINHTHUCMIENGIAM_SHOW_ITEMS_MODAL",
    HINHTHUCMIENGIAM_CLOSE_ITEMS_MODAL = "HINHTHUCMIENGIAM_CLOSE_ITEMS_MODAL",

    HINHTHUCMIENGIAM_SHOW_COPY_MODAL = "HINHTHUCMIENGIAM_SHOW_COPY_MODAL",
    HINHTHUCMIENGIAM_CLOSE_COPY_MODAL = "HINHTHUCMIENGIAM_CLOSE_COPY_MODAL"

}

export interface HinhThucMienGiamLoadStartAction {
    type: HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_LOAD_START,
    payload: string
}
export interface HinhThucMienGiamLoadSuccessAction {
    type: HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_LOAD_SUCCESS,
    payload: sf_hinhthucmiengiam[]
}
export interface HinhThucMienGiamLoadErrorAction {
    type: HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_LOAD_ERROR,
    payload: string
}

export interface HinhThucMienGiamShowDetailModalAction {
    type: HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_SHOW_DETAIL_MODAL,
    payload?: sf_hinhthucmiengiam
}
export interface HinhThucMienGiamCloseDetailModalAction {
    type: HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_CLOSE_DETAIL_MODAL,
}
export interface HinhThucMienGiamShowDeleteConfirmAction {
    type: HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_SHOW_DELETE_CONFIRM,
}
export interface HinhThucMienGiamCloseDeleteConfirmAction {
    type: HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_CLOSE_DELETE_CONFIRM,
}

export interface HinhThucMienGiamSaveStartAction {
    type: HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_SAVE_START,
    payload: sf_hinhthucmiengiam
}
export interface HinhThucMienGiamSaveSuccessAction {
    type: HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_SAVE_SUCCESS,
    payload: sf_hinhthucmiengiam
}
export interface HinhThucMienGiamSaveErrorAction {
    type: HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_SAVE_ERROR,
    payload: string
}
export interface HinhThucMienGiamDeleteStartAction {
    type: HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_DELETE_START,
    payload: number[]
}
export interface HinhThucMienGiamDeleteSuccessAction {
    type: HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_DELETE_SUCCESSS,
    payload: number[]
}
export interface HinhThucMienGiamDeleteErrorAction {
    type: HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_DELETE_ERROR,
    payload: string
}

export interface HinhThucMienGiamChangeSelectedIdsAction {
    type: HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_CHANGE_SELECTED_IDS,
    payload: number[]
}


export interface HinhThucMienGiamHinhThucTinhLoadStartAction {
    type: HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_HINHTHUCTINH_LOAD_START
}


export interface HinhThucMienGiamHinhThucTinhLoadSuccessAction {
    type: HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_HINHTHUCTINH_LOAD_SUCCESS,
    payload: sf_hinhthucmiengiam_hinhthuctinh[]
}


export interface HinhThucMienGiamChangeNamHocAction {
    type: HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_CHANGE_NAMHOC,
    payload: string
}

export interface HinhThucMienGiamShowItemsModalAction {
    type: HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_SHOW_ITEMS_MODAL,
    payload: sf_hinhthucmiengiam
}
export interface HinhThucMienGiamCloseItemModalAction {
    type: HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_CLOSE_ITEMS_MODAL
}

export interface HinhThucMienGiamShowCopyModalAction {
    type: HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_SHOW_COPY_MODAL
}
export interface HinhThucMienGiamCloseCopyModalAction {
    type: HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_CLOSE_COPY_MODAL
}

export type HinhThucMienGiamActionTypes =
    HinhThucMienGiamLoadStartAction | HinhThucMienGiamLoadSuccessAction | HinhThucMienGiamLoadErrorAction |
    HinhThucMienGiamShowDetailModalAction | HinhThucMienGiamShowDeleteConfirmAction |
    HinhThucMienGiamCloseDetailModalAction | HinhThucMienGiamCloseDeleteConfirmAction |
    HinhThucMienGiamSaveStartAction | HinhThucMienGiamSaveSuccessAction | HinhThucMienGiamSaveErrorAction |
    HinhThucMienGiamDeleteStartAction | HinhThucMienGiamDeleteSuccessAction | HinhThucMienGiamDeleteErrorAction |
    HinhThucMienGiamChangeSelectedIdsAction |
    HinhThucMienGiamHinhThucTinhLoadStartAction | HinhThucMienGiamHinhThucTinhLoadSuccessAction |
    HinhThucMienGiamChangeNamHocAction |
    HinhThucMienGiamShowItemsModalAction | HinhThucMienGiamCloseItemModalAction |
    HinhThucMienGiamShowCopyModalAction | HinhThucMienGiamCloseCopyModalAction