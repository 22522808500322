import { sf_khoannop_group } from "../../models/response/category/sf_khoannop_group"
import {
    KhoanNopGroupActionTypeIds,
    KhoanNopGroupLoadStartAction, KhoanNopGroupLoadSuccessAction, KhoanNopGroupLoadErrorAction, KhoanNopGroupShowDetailModalAction,
    KhoanNopGroupSaveStartAction, KhoanNopGroupSaveSuccessAction, KhoanNopGroupSaveErrorAction, KhoanNopGroupDeleteStartAction, KhoanNopGroupDeleteSuccessAction, KhoanNopGroupDeleteErrorAction, KhoanNopGroupChangeSelectedIdsAction, KhoanNopGroupShowDeleteConfirmAction
} from "../action-types/khoanNopGroupActionTypes"

export const loadKhoanNopGroupStart = (): KhoanNopGroupLoadStartAction => {
    return {
        type: KhoanNopGroupActionTypeIds.KHOANNOPGROUP_LOAD_START
    }
}

export const loadKhoanNopGroupSuccess = (payload: sf_khoannop_group[]): KhoanNopGroupLoadSuccessAction => {
    return {
        type: KhoanNopGroupActionTypeIds.KHOANNOPGROUP_LOAD_SUCCESS,
        payload: payload
    }
}
export const loadKhoanNopGroupError = (payload: string): KhoanNopGroupLoadErrorAction => {
    return {
        type: KhoanNopGroupActionTypeIds.KHOANNOPGROUP_LOAD_ERROR,
        payload: payload
    }
}

export const showKhoanNopGroupDetailModal = (payload: boolean): KhoanNopGroupShowDetailModalAction => {
    return {
        type: KhoanNopGroupActionTypeIds.KHOANOP_SHOW_DETAIL_MODAL,
        payload: payload
    }
}

export const showKhoanNopGroupDeleteConfirm = (payload: boolean): KhoanNopGroupShowDeleteConfirmAction => {
    return {
        type: KhoanNopGroupActionTypeIds.KHOANNOPGROUP_SHOW_DELETE_CONFIRM,
        payload: payload
    }
}

export const saveKhoanNopGroupStart = (payload: sf_khoannop_group): KhoanNopGroupSaveStartAction => {
    return {
        type: KhoanNopGroupActionTypeIds.KHOANNOPGROUP_SAVE_START,
        payload: payload
    }
}

export const saveKhoanNopGroupSuccess = (payload: sf_khoannop_group): KhoanNopGroupSaveSuccessAction => {
    return {
        type: KhoanNopGroupActionTypeIds.KHOANNOPGROUP_SAVE_SUCCESS,
        payload: payload
    }
}
export const saveKhoanNopGroupError = (payload: string): KhoanNopGroupSaveErrorAction => {
    return {
        type: KhoanNopGroupActionTypeIds.KHOANNOPGROUP_SAVE_ERROR,
        payload: payload
    }
}

export const deleteKhoanNopGroupStart = (payload: number[]): KhoanNopGroupDeleteStartAction => {
    return {
        type: KhoanNopGroupActionTypeIds.KHOANNOPGROUP_DELETE_START,
        payload: payload
    }
}

export const deleteKhoanNopGroupSuccess = (payload: number[]): KhoanNopGroupDeleteSuccessAction => {
    return {
        type: KhoanNopGroupActionTypeIds.KHOANNOPGROUP_DELETE_SUCCESSS,
        payload: payload
    }
}
export const deleteKhoanNopGroupError = (payload: string): KhoanNopGroupDeleteErrorAction => {
    return {
        type: KhoanNopGroupActionTypeIds.KHOANNOPGROUP_DELETE_ERROR,
        payload: payload
    }
}

export const changeKhoanNopGroupSelectionIds = (payload: number[]): KhoanNopGroupChangeSelectedIdsAction => {
    return {
        type: KhoanNopGroupActionTypeIds.KHOANNOPGROUP_CHANGE_SELECTED_IDS,
        payload: payload
    }
}