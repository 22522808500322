import { sf_khoannop } from "../../models/response/category/sf_khoannop"
import {
    KhoanNopActionTypeIds,
    KhoanNopLoadStartAction, KhoanNopLoadSuccessAction, KhoanNopLoadErrorAction, KhoanNopShowDetailModalAction,
    KhoanNopSaveStartAction, KhoanNopSaveSuccessAction, KhoanNopSaveErrorAction, KhoanNopDeleteStartAction, KhoanNopDeleteSuccessAction, KhoanNopDeleteErrorAction, KhoanNopChangeSelectedIdsAction, KhoanNopShowDeleteConfirmAction, KhoanNopCloseDetailModalAction, KhoanNopCloseDeleteConfirmAction
} from "../action-types/khoanNopActionTypes"

export const loadKhoanNopStart = (): KhoanNopLoadStartAction => {
    return {
        type: KhoanNopActionTypeIds.KHOANNOP_LOAD_START
    }
}

export const loadKhoanNopSuccess = (payload: sf_khoannop[]): KhoanNopLoadSuccessAction => {
    return {
        type: KhoanNopActionTypeIds.KHOANNOP_LOAD_SUCCESS,
        payload: payload
    }
}
export const loadKhoanNopError = (payload: string): KhoanNopLoadErrorAction => {
    return {
        type: KhoanNopActionTypeIds.KHOANNOP_LOAD_ERROR,
        payload: payload
    }
}

export const showKhoanNopDetailModal = (payload?: sf_khoannop): KhoanNopShowDetailModalAction => {
    return {
        type: KhoanNopActionTypeIds.KHOANNOP_SHOW_DETAIL_MODAL,
        payload: payload
    }
}
export const closeKhoanNopDetailModal = (): KhoanNopCloseDetailModalAction => {
    return {
        type: KhoanNopActionTypeIds.KHOANNOP_CLOSE_DETAIL_MODAL,
    }
}

export const showKhoanNopDeleteConfirm = (): KhoanNopShowDeleteConfirmAction => {
    return {
        type: KhoanNopActionTypeIds.KHOANNOP_SHOW_DELETE_CONFIRM,
    }
}
export const closeKhoanNopDeleteConfirm = (): KhoanNopCloseDeleteConfirmAction => {
    return {
        type: KhoanNopActionTypeIds.KHOANNOP_CLOSE_DELETE_CONFIRM,
    }
}

export const saveKhoanNopStart = (payload: sf_khoannop): KhoanNopSaveStartAction => {
    return {
        type: KhoanNopActionTypeIds.KHOANNOP_SAVE_START,
        payload: payload
    }
}

export const saveKhoanNopSuccess = (payload: sf_khoannop): KhoanNopSaveSuccessAction => {
    return {
        type: KhoanNopActionTypeIds.KHOANNOP_SAVE_SUCCESS,
        payload: payload
    }
}
export const saveKhoanNopError = (payload: string): KhoanNopSaveErrorAction => {
    return {
        type: KhoanNopActionTypeIds.KHOANNOP_SAVE_ERROR,
        payload: payload
    }
}

export const deleteKhoanNopStart = (payload: number[]): KhoanNopDeleteStartAction => {
    return {
        type: KhoanNopActionTypeIds.KHOANNOP_DELETE_START,
        payload: payload
    }
}

export const deleteKhoanNopSuccess = (payload: number[]): KhoanNopDeleteSuccessAction => {
    return {
        type: KhoanNopActionTypeIds.KHOANNOP_DELETE_SUCCESSS,
        payload: payload
    }
}
export const deleteKhoanNopError = (payload: string): KhoanNopDeleteErrorAction => {
    return {
        type: KhoanNopActionTypeIds.KHOANNOP_DELETE_ERROR,
        payload: payload
    }
}

export const changeKhoanNopSelectionIds = (payload: number[]): KhoanNopChangeSelectedIdsAction => {
    return {
        type: KhoanNopActionTypeIds.KHOANNOP_CHANGE_SELECTED_IDS,
        payload: payload
    }
}