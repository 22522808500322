import { sf_tbtp_add_khoannop_rq } from "../../../models/request/tbtp/sf_tbtp_add_khoannop_rq";
import { sf_tbtp_remove_khoannop_rq } from "../../../models/request/tbtp/sf_tbtp_remove_khoannop_rq";
import { sf_hinhthucnopbom } from "../../../models/response/category/sf_hinhthucnopbom";
import { sf_tbtp_khoannop_viewmodel } from "../../../models/response/tbtp/sf_tbtp_khoannop";

export enum EditTBTPKhoanNopActionTypeIds {
    LOAD_KHOANNOP_START = "EDIT_TBTP_KHOANNOP_LOAD_KHOANNOP_START",
    LOAD_KHOANNOP_SUCCESS = "EDIT_TBTP_KHOANNOP_LOAD_KHOANNOP_SUCCESS",
    LOAD_KHOANNOP_ERROR = "EDIT_TBTP_KHOANNOP_LOAD_KHOANNOP_ERROR",

    SHOW_DELETE_CONFIRM = "EDIT_TBTP_KHOANOP_SHOW_DELETE_CONFIRM",
    CLOSE_DELETE_CONFIRM = "EDIT_TBTP_KHOANOP_CLOSE_DELETE_CONFIRM",

    LOAD_HINHTHUCNOPBOM_START = "EDIT_TBTP_KHOANNOP_LOAD_HINHTHUCNOPBOM_START",
    LOAD_HINHTHUCNOPBOM_SUCCESS = "EDIT_TBTP_KHOANNOP_LOAD_HINHTHUCNOPBOM_SUCCESS",
    LOAD_HINHTHUCNOPBOM_ERROR = "EDIT_TBTP_KHOANNOP_LOAD_HINHTHUCNOPBOM_ERROR",

    KHOANNOP_DELETE_START = "EDIT_TBTP_KHOANNOP_KHOANNOP_DELETE_START",
    KHOANNOP_DELETE_SUCCESS = "EDIT_TBTP_KHOANNOP_KHOANNOP_DELETE_SUCCESS",
    KHOANNOP_DELETE_ERROR = "EDIT_TBTP_KHOANNOP_KHOANNOP_DELETE_ERROR",

    KHOANNOP_SHOW_ADD_MODAL = "EDIT_TBTP_KHOANNOP_KHOANNOP_SHOW_ADD_MODAL",
    KHOANNOP_CLOSE_ADD_MODAL = "EDIT_TBTP_KHOANNOP_KHOANNOP_CLOSE_ADD_MODAL",
    KHOANNOP_ADD_START = "EDIT_TBTP_KHOANNOP_KHOANNOP_ADD_START",
    KHOANNOP_ADD_SUCCESS = "EDIT_TBTP_KHOANNOP_KHOANNOP_ADD_SUCCESS",
    KHOANNOP_ADD_ERROR = "EDIT_TBTP_KHOANNOP_KHOANNOP_ADD_ERROR"

}

export interface KhoanNopLoadStart {
    type: EditTBTPKhoanNopActionTypeIds.LOAD_KHOANNOP_START,
    payload: number
}

export interface KhoanNopLoadSuccess {
    type: EditTBTPKhoanNopActionTypeIds.LOAD_KHOANNOP_SUCCESS,
    payload: sf_tbtp_khoannop_viewmodel[]
}

export interface KhoanNopLoadError {
    type: EditTBTPKhoanNopActionTypeIds.LOAD_KHOANNOP_ERROR,
    payload: string
}

export interface ShowDeleteConfirm {
    type: EditTBTPKhoanNopActionTypeIds.SHOW_DELETE_CONFIRM,
    payload: number
}


export interface CloseDeleteConfirm {
    type: EditTBTPKhoanNopActionTypeIds.CLOSE_DELETE_CONFIRM,
}

export interface HinhThucNopBomLoadStart {
    type: EditTBTPKhoanNopActionTypeIds.LOAD_HINHTHUCNOPBOM_START,
    payload: number
}

export interface HinhThucNopBomLoadSuccess {
    type: EditTBTPKhoanNopActionTypeIds.LOAD_HINHTHUCNOPBOM_SUCCESS,
    payload: sf_hinhthucnopbom[]
}

export interface HinhThucNopBomLoadError {
    type: EditTBTPKhoanNopActionTypeIds.LOAD_HINHTHUCNOPBOM_ERROR,
    payload: string
}


export interface KhoanNopDeleteStart {
    type: EditTBTPKhoanNopActionTypeIds.KHOANNOP_DELETE_START,
    payload: sf_tbtp_remove_khoannop_rq
}


export interface KhoanNopDeleteSuccess {
    type: EditTBTPKhoanNopActionTypeIds.KHOANNOP_DELETE_SUCCESS,
}


export interface KhoanNopDeleteError {
    type: EditTBTPKhoanNopActionTypeIds.KHOANNOP_DELETE_ERROR,
    payload: string
}

export interface KhoanNopShowAddModal {
    type: EditTBTPKhoanNopActionTypeIds.KHOANNOP_SHOW_ADD_MODAL
}

export interface KhoanNopCloseAddModal {
    type: EditTBTPKhoanNopActionTypeIds.KHOANNOP_CLOSE_ADD_MODAL
}


export interface KhoanNopAddStart {
    type: EditTBTPKhoanNopActionTypeIds.KHOANNOP_ADD_START,
    payload: sf_tbtp_add_khoannop_rq
}

export interface KhoanNopAddSuccess {
    type: EditTBTPKhoanNopActionTypeIds.KHOANNOP_ADD_SUCCESS
}

export interface KhoanNopAddError {
    type: EditTBTPKhoanNopActionTypeIds.KHOANNOP_ADD_ERROR,
    payload: string
}


export type EditTBTPKhoanNopActionTypes = KhoanNopLoadStart | KhoanNopLoadSuccess | KhoanNopLoadError |
    ShowDeleteConfirm | CloseDeleteConfirm |
    HinhThucNopBomLoadStart | HinhThucNopBomLoadSuccess | HinhThucNopBomLoadError |
    KhoanNopDeleteStart | KhoanNopDeleteSuccess | KhoanNopDeleteError |
    KhoanNopShowAddModal | KhoanNopCloseAddModal |

    KhoanNopAddStart | KhoanNopAddSuccess | KhoanNopAddError