import { sf_mucphaithu_search_rq } from "../models/request/tonghop/sf_mucphaithu_search_rq";
import { apiClient } from "./apiClient";

export const SF_MUCPHAITHU_SEARCH_API = "sf_mucphaithu/search";


export const mucPhaiThuApi = {
    search: (request: sf_mucphaithu_search_rq) => {
        return apiClient.post(`${SF_MUCPHAITHU_SEARCH_API}`, request)
    }
}