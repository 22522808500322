import { IDictionaryModel } from "../../models/response/dictionary";

export enum DictionaryActionTypeIds {
    LOAD_DICTIONARY_REQUESTED = "LOAD_DICTIONARY_REQUESTED",
    SET_DICTIONARY = "SET_DICTIONARY"
}
export interface ILoadDictionaryRequestedAction {
    type: DictionaryActionTypeIds.LOAD_DICTIONARY_REQUESTED
}
export interface ISetDictionaryAction {
    type: DictionaryActionTypeIds.SET_DICTIONARY,
    payload: IDictionaryModel[]
}
export type DictionaryActionTypes = ISetDictionaryAction | ILoadDictionaryRequestedAction