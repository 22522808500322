import { sf_loaikhoannop } from "../../models/response/category/sf_loaikhoannop"
export enum LoaiKhoanNopActionTypeIds {
    LOAIKHOANNOP_LOAD_START = "LOAIKHOANNOP_LOAD_START",
    LOAIKHOANNOP_LOAD_SUCCESS = "LOAIKHOANNOP_LOAD_SUCCESS",
    LOAIKHOANNOP_LOAD_ERROR = "LOAIKHOANNOP_LOAD_ERROR",

    KHOANOP_SHOW_DETAIL_MODAL = "KHOANOP_SHOW_DETAIL_MODAL",
    LOAIKHOANNOP_SHOW_DELETE_CONFIRM = "LOAIKHOANNOP_SHOW_DELETE_CONFIRM",

    LOAIKHOANNOP_SAVE_START = "LOAIKHOANNOP_SAVE_START",
    LOAIKHOANNOP_SAVE_SUCCESS = "LOAIKHOANNOP_SAVE_SUCCESS",
    LOAIKHOANNOP_SAVE_ERROR = "LOAIKHOANNOP_SAVE_ERROR",

    LOAIKHOANNOP_DELETE_START = "LOAIKHOANNOP_DELETE_START",
    LOAIKHOANNOP_DELETE_SUCCESSS = "LOAIKHOANNOP_DELETE_SUCCESSS",
    LOAIKHOANNOP_DELETE_ERROR = "LOAIKHOANNOP_DELETE_ERROR",

    LOAIKHOANNOP_CHANGE_SELECTED_IDS = "LOAIKHOANNOP_CHANGE_SELECTED_IDS"

}

export interface LoaiKhoanNopLoadStartAction {
    type: LoaiKhoanNopActionTypeIds.LOAIKHOANNOP_LOAD_START
}
export interface LoaiKhoanNopLoadSuccessAction {
    type: LoaiKhoanNopActionTypeIds.LOAIKHOANNOP_LOAD_SUCCESS,
    payload: sf_loaikhoannop[]
}
export interface LoaiKhoanNopLoadErrorAction {
    type: LoaiKhoanNopActionTypeIds.LOAIKHOANNOP_LOAD_ERROR,
    payload: string
}

export interface LoaiKhoanNopShowDetailModalAction {
    type: LoaiKhoanNopActionTypeIds.KHOANOP_SHOW_DETAIL_MODAL,
    payload: boolean

}
export interface LoaiKhoanNopShowDeleteConfirmAction {
    type: LoaiKhoanNopActionTypeIds.LOAIKHOANNOP_SHOW_DELETE_CONFIRM,
    payload: boolean
}
export interface LoaiKhoanNopSaveStartAction {
    type: LoaiKhoanNopActionTypeIds.LOAIKHOANNOP_SAVE_START,
    payload: sf_loaikhoannop
}
export interface LoaiKhoanNopSaveSuccessAction {
    type: LoaiKhoanNopActionTypeIds.LOAIKHOANNOP_SAVE_SUCCESS,
    payload: sf_loaikhoannop
}
export interface LoaiKhoanNopSaveErrorAction {
    type: LoaiKhoanNopActionTypeIds.LOAIKHOANNOP_SAVE_ERROR,
    payload: string
}
export interface LoaiKhoanNopDeleteStartAction {
    type: LoaiKhoanNopActionTypeIds.LOAIKHOANNOP_DELETE_START,
    payload: number[]
}
export interface LoaiKhoanNopDeleteSuccessAction {
    type: LoaiKhoanNopActionTypeIds.LOAIKHOANNOP_DELETE_SUCCESSS,
    payload: number[]
}
export interface LoaiKhoanNopDeleteErrorAction {
    type: LoaiKhoanNopActionTypeIds.LOAIKHOANNOP_DELETE_ERROR,
    payload: string
}

export interface LoaiKhoanNopChangeSelectedIdsAction {
    type: LoaiKhoanNopActionTypeIds.LOAIKHOANNOP_CHANGE_SELECTED_IDS,
    payload: number[]
}
export type LoaiKhoanNopActionTypes =
    LoaiKhoanNopLoadStartAction | LoaiKhoanNopLoadSuccessAction | LoaiKhoanNopLoadErrorAction |
    LoaiKhoanNopShowDetailModalAction | LoaiKhoanNopShowDeleteConfirmAction |
    LoaiKhoanNopSaveStartAction | LoaiKhoanNopSaveSuccessAction | LoaiKhoanNopSaveErrorAction |
    LoaiKhoanNopDeleteStartAction | LoaiKhoanNopDeleteSuccessAction | LoaiKhoanNopDeleteErrorAction |
    LoaiKhoanNopChangeSelectedIdsAction