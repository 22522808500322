import { localStorageHelper } from "../../helpers/localStorage";
import { showNotify } from "../../helpers/toast";
import { PageBaseStatus } from "../../models/pageBaseStatus";
import { HachToanCongNoActionTypeIds, HachToanCongNoActionTypes } from "../action-types/hachToanCongNoActionTypes";
import { HachToanCongNoPageState } from "../page-state-model/hachToanCongNoPageState";
const initialState: HachToanCongNoPageState = {
    sf_hachtoan_congno_viewmodels: [],
    sf_hachtoan_congno_selected_ids: [],
    status: PageBaseStatus.is_completed,
    filter: {
        nam_hoc: localStorageHelper.getNamHoc(),
        dm_he_id: localStorageHelper.getDmHeId(),
        dm_khoi_id: localStorageHelper.getDmKhoiId(),
        dm_truong_id: localStorageHelper.getDmTruongId()
    }
}
export const hachToanCongNoReducer = (state: HachToanCongNoPageState = initialState, action: HachToanCongNoActionTypes): HachToanCongNoPageState => {
    switch (action.type) {
        case HachToanCongNoActionTypeIds.HACHTOANCONGNO_LOAD_START:
            return {
                ...state,
                status: PageBaseStatus.is_loading
            }
        case HachToanCongNoActionTypeIds.HACHTOANCONGNO_LOAD_SUCCESS:
            return {
                ...state,
                status: PageBaseStatus.is_completed,
                sf_hachtoan_congno_viewmodels: action.payload
            }
        case HachToanCongNoActionTypeIds.HACHTOANCONGNO_LOAD_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }
        case HachToanCongNoActionTypeIds.HACHTOANCONGNO_SHOW_DETAIL_MODAL:
            return {
                ...state,
                is_show_detail_modal: true,
                sf_hachtoan_congno_editing: action.payload
            }
        case HachToanCongNoActionTypeIds.HACHTOANCONGNO_CLOSE_DETAIL_MODAL:
            return {
                ...state,
                is_show_detail_modal: false,
                sf_hachtoan_congno_editing: undefined
            }
        case HachToanCongNoActionTypeIds.HACHTOANCONGNO_SHOW_DELETE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: true
            }
        case HachToanCongNoActionTypeIds.HACHTOANCONGNO_CLOSE_DELETE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: false
            }
        case HachToanCongNoActionTypeIds.HACHTOANCONGNO_SAVE_START:
            return {
                ...state,
                status: PageBaseStatus.is_saving
            }
        case HachToanCongNoActionTypeIds.HACHTOANCONGNO_SAVE_SUCCESS:
            showNotify({
                message: `${action.payload.id > 0 ? "Cập nhật " : "Thêm mới "} thành công quản quyết toán ${action.payload.noi_dung}`,
                type: "success"
            })
            return {
                ...state,
                status: PageBaseStatus.is_need_reload,
                sf_hachtoan_congno_editing: undefined,
                is_show_detail_modal: false
            }
        case HachToanCongNoActionTypeIds.HACHTOANCONGNO_SAVE_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }

        case HachToanCongNoActionTypeIds.HACHTOANCONGNO_DELETE_START:
            return {
                ...state,
                status: PageBaseStatus.is_deleting
            }
        case HachToanCongNoActionTypeIds.HACHTOANCONGNO_DELETE_SUCCESSS:
            showNotify({ message: `Xóa thành công ${action.payload.length} khoản nộp`, type: "success" })
            return {
                ...state,
                status: PageBaseStatus.is_need_reload,
                is_show_delete_confirm: false
            }
        case HachToanCongNoActionTypeIds.HACHTOANCONGNO_DELETE_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }
        case HachToanCongNoActionTypeIds.HACHTOANCONGNO_CHANGE_SELECTED_IDS:
            return {
                ...state,
                sf_hachtoan_congno_selected_ids: action.payload
            }
        case HachToanCongNoActionTypeIds.HACHTOANCONGNO_CHANGE_FILTER:
            return {
                ...state,
                filter: action.payload
            }
        case HachToanCongNoActionTypeIds.HACHTOANCONGNO_SHOW_IMPORT_MODEL:
            return {
                ...state,
                is_show_import_modal: true
            }
        case HachToanCongNoActionTypeIds.HACHTOANCONGNO_CLOSE_IMPORT_MODEL:
            return {
                ...state,
                is_show_import_modal: false
            }
        default:
            return state;
    }
}