import { sf_mucthuphi_select_rq } from "../../models/request/mucthuphi/sf_mucthiphi_select_rq"
import { sf_mucthuphi } from "../../models/response/category/sf_mucthuphi"
import {
    MucThuPhiActionTypeIds,
    MucThuPhiLoadStartAction, MucThuPhiLoadSuccessAction, MucThuPhiLoadErrorAction, MucThuPhiShowDetailModalAction,
    MucThuPhiSaveStartAction, MucThuPhiSaveSuccessAction, MucThuPhiSaveErrorAction, MucThuPhiDeleteStartAction, MucThuPhiDeleteSuccessAction, MucThuPhiDeleteErrorAction, MucThuPhiChangeSelectedIdsAction, MucThuPhiShowDeleteConfirmAction, MucThuPhiCloseDetailModalAction, MucThuPhiCloseDeleteConfirmAction, MucThuPhiNamHocChangedAction, MucThuPhiTruongChangedAction, MucThuPhiHeChangedAction, MucThuPhiKhoiChangedAction, MucThuPhiShowCopyModalAction, MucThuPhiCloseCopyModalAction
} from "../action-types/mucThuPhiActionTypes"

export const loadMucThuPhiStart = (payload: sf_mucthuphi_select_rq): MucThuPhiLoadStartAction => {
    return {
        type: MucThuPhiActionTypeIds.MUCTHUPHI_LOAD_START,
        payload
    }
}

export const loadMucThuPhiSuccess = (payload: sf_mucthuphi[]): MucThuPhiLoadSuccessAction => {
    return {
        type: MucThuPhiActionTypeIds.MUCTHUPHI_LOAD_SUCCESS,
        payload: payload
    }
}
export const loadMucThuPhiError = (payload: string): MucThuPhiLoadErrorAction => {
    return {
        type: MucThuPhiActionTypeIds.MUCTHUPHI_LOAD_ERROR,
        payload: payload
    }
}

export const showMucThuPhiDetailModal = (payload?: sf_mucthuphi): MucThuPhiShowDetailModalAction => {
    return {
        type: MucThuPhiActionTypeIds.MUCTHUPHI_SHOW_DETAIL_MODAL,
        payload: payload
    }
}
export const closeMucThuPhiDetailModal = (): MucThuPhiCloseDetailModalAction => {
    return {
        type: MucThuPhiActionTypeIds.MUCTHUPHI_CLOSE_DETAIL_MODAL,
    }
}

export const showMucThuPhiDeleteConfirm = (): MucThuPhiShowDeleteConfirmAction => {
    return {
        type: MucThuPhiActionTypeIds.MUCTHUPHI_SHOW_DELETE_CONFIRM,
    }
}
export const closeMucThuPhiDeleteConfirm = (): MucThuPhiCloseDeleteConfirmAction => {
    return {
        type: MucThuPhiActionTypeIds.MUCTHUPHI_CLOSE_DELETE_CONFIRM,
    }
}

export const saveMucThuPhiStart = (payload: sf_mucthuphi): MucThuPhiSaveStartAction => {
    return {
        type: MucThuPhiActionTypeIds.MUCTHUPHI_SAVE_START,
        payload: payload
    }
}

export const saveMucThuPhiSuccess = (payload: sf_mucthuphi): MucThuPhiSaveSuccessAction => {
    return {
        type: MucThuPhiActionTypeIds.MUCTHUPHI_SAVE_SUCCESS,
        payload: payload
    }
}
export const saveMucThuPhiError = (payload: string): MucThuPhiSaveErrorAction => {
    return {
        type: MucThuPhiActionTypeIds.MUCTHUPHI_SAVE_ERROR,
        payload: payload
    }
}

export const deleteMucThuPhiStart = (payload: number[]): MucThuPhiDeleteStartAction => {
    return {
        type: MucThuPhiActionTypeIds.MUCTHUPHI_DELETE_START,
        payload: payload
    }
}

export const deleteMucThuPhiSuccess = (payload: number[]): MucThuPhiDeleteSuccessAction => {
    return {
        type: MucThuPhiActionTypeIds.MUCTHUPHI_DELETE_SUCCESSS,
        payload: payload
    }
}
export const deleteMucThuPhiError = (payload: string): MucThuPhiDeleteErrorAction => {
    return {
        type: MucThuPhiActionTypeIds.MUCTHUPHI_DELETE_ERROR,
        payload: payload
    }
}

export const changeMucThuPhieSelectionIds = (payload: number[]): MucThuPhiChangeSelectedIdsAction => {
    return {
        type: MucThuPhiActionTypeIds.MUCTHUPHI_CHANGE_SELECTED_IDS,
        payload: payload
    }
}

export const changeMucThuPhiNamHoc = (payload: string): MucThuPhiNamHocChangedAction => {
    return {
        type: MucThuPhiActionTypeIds.MUCTHUPHI_NAMHOC_CHANGED,
        payload: payload
    }
}

export const changeMucThuPhiTruong = (payload: number): MucThuPhiTruongChangedAction => {
    return {
        type: MucThuPhiActionTypeIds.MUCTHUPHI_TRUONG_CHANGED,
        payload: payload
    }
}
export const changeMucThuPhiHe = (payload: number): MucThuPhiHeChangedAction => {
    return {
        type: MucThuPhiActionTypeIds.MUCTHUPHI_HE_CHANGED,
        payload: payload
    }
}
export const changeMucThuPhiKhoi = (payload: number): MucThuPhiKhoiChangedAction => {
    return {
        type: MucThuPhiActionTypeIds.MUCTHUPHI_KHOI_CHANGED,
        payload: payload
    }
}
export const showCopyModalAction = (): MucThuPhiShowCopyModalAction => {
    return {
        type: MucThuPhiActionTypeIds.MUC_THU_PHI_SHOW_COPY_MODAL
    }
}
export const closeCopyModalAction = (): MucThuPhiCloseCopyModalAction => {
    return {
        type: MucThuPhiActionTypeIds.MUC_THU_PHI_CLOSE_COPY_MODAL
    }
}