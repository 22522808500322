import { sf_mucthuphi_select_rq } from "../../models/request/mucthuphi/sf_mucthiphi_select_rq"
import { sf_mucthuphi } from "../../models/response/category/sf_mucthuphi"
export enum MucThuPhiActionTypeIds {
    MUCTHUPHI_LOAD_START = "MUCTHUPHI_LOAD_START",
    MUCTHUPHI_LOAD_SUCCESS = "MUCTHUPHI_LOAD_SUCCESS",
    MUCTHUPHI_LOAD_ERROR = "MUCTHUPHI_LOAD_ERROR",

    MUCTHUPHI_SHOW_DETAIL_MODAL = "MUCTHUPHI_SHOW_DETAIL_MODAL",
    MUCTHUPHI_CLOSE_DETAIL_MODAL = "MUCTHUPHI_CLOSE_DETAIL_MODAL",

    MUCTHUPHI_SHOW_DELETE_CONFIRM = "MUCTHUPHI_SHOW_DELETE_CONFIRM",
    MUCTHUPHI_CLOSE_DELETE_CONFIRM = "MUCTHUPHI_CLOSE_DELETE_CONFIRM",

    MUCTHUPHI_SAVE_START = "MUCTHUPHI_SAVE_START",
    MUCTHUPHI_SAVE_SUCCESS = "MUCTHUPHI_SAVE_SUCCESS",
    MUCTHUPHI_SAVE_ERROR = "MUCTHUPHI_SAVE_ERROR",

    MUCTHUPHI_DELETE_START = "MUCTHUPHI_DELETE_START",
    MUCTHUPHI_DELETE_SUCCESSS = "MUCTHUPHI_DELETE_SUCCESSS",
    MUCTHUPHI_DELETE_ERROR = "MUCTHUPHI_DELETE_ERROR",

    MUCTHUPHI_CHANGE_SELECTED_IDS = "MUCTHUPHI_CHANGE_SELECTED_IDS",

    MUCTHUPHI_NAMHOC_CHANGED = "MUCTHUPHI_NAMHOC_CHANGED",
    MUCTHUPHI_TRUONG_CHANGED = "MUCTHUPHI_TRUONG_CHANGED",
    MUCTHUPHI_KHOI_CHANGED = "MUCTHUPHI_KHOI_CHANGED",
    MUCTHUPHI_HE_CHANGED = "MUCTHUPHI_HE_CHANGED",

    MUC_THU_PHI_SHOW_COPY_MODAL = "MUC_THU_PHI_SHOW_COPY_MODAL",
    MUC_THU_PHI_CLOSE_COPY_MODAL = "MUC_THU_PHI_CLOSE_COPY_MODAL"

}

export interface MucThuPhiLoadStartAction {
    type: MucThuPhiActionTypeIds.MUCTHUPHI_LOAD_START,
    payload: sf_mucthuphi_select_rq
}
export interface MucThuPhiLoadSuccessAction {
    type: MucThuPhiActionTypeIds.MUCTHUPHI_LOAD_SUCCESS,
    payload: sf_mucthuphi[]
}
export interface MucThuPhiLoadErrorAction {
    type: MucThuPhiActionTypeIds.MUCTHUPHI_LOAD_ERROR,
    payload: string
}

export interface MucThuPhiShowDetailModalAction {
    type: MucThuPhiActionTypeIds.MUCTHUPHI_SHOW_DETAIL_MODAL,
    payload?: sf_mucthuphi
}
export interface MucThuPhiCloseDetailModalAction {
    type: MucThuPhiActionTypeIds.MUCTHUPHI_CLOSE_DETAIL_MODAL,
}
export interface MucThuPhiShowDeleteConfirmAction {
    type: MucThuPhiActionTypeIds.MUCTHUPHI_SHOW_DELETE_CONFIRM,
}
export interface MucThuPhiCloseDeleteConfirmAction {
    type: MucThuPhiActionTypeIds.MUCTHUPHI_CLOSE_DELETE_CONFIRM,
}

export interface MucThuPhiSaveStartAction {
    type: MucThuPhiActionTypeIds.MUCTHUPHI_SAVE_START,
    payload: sf_mucthuphi
}
export interface MucThuPhiSaveSuccessAction {
    type: MucThuPhiActionTypeIds.MUCTHUPHI_SAVE_SUCCESS,
    payload: sf_mucthuphi
}
export interface MucThuPhiSaveErrorAction {
    type: MucThuPhiActionTypeIds.MUCTHUPHI_SAVE_ERROR,
    payload: string
}
export interface MucThuPhiDeleteStartAction {
    type: MucThuPhiActionTypeIds.MUCTHUPHI_DELETE_START,
    payload: number[]
}
export interface MucThuPhiDeleteSuccessAction {
    type: MucThuPhiActionTypeIds.MUCTHUPHI_DELETE_SUCCESSS,
    payload: number[]
}
export interface MucThuPhiDeleteErrorAction {
    type: MucThuPhiActionTypeIds.MUCTHUPHI_DELETE_ERROR,
    payload: string
}

export interface MucThuPhiChangeSelectedIdsAction {
    type: MucThuPhiActionTypeIds.MUCTHUPHI_CHANGE_SELECTED_IDS,
    payload: number[]
}
export interface MucThuPhiNamHocChangedAction {
    type: MucThuPhiActionTypeIds.MUCTHUPHI_NAMHOC_CHANGED,
    payload: string
}
export interface MucThuPhiTruongChangedAction {
    type: MucThuPhiActionTypeIds.MUCTHUPHI_TRUONG_CHANGED,
    payload: number
}
export interface MucThuPhiKhoiChangedAction {
    type: MucThuPhiActionTypeIds.MUCTHUPHI_KHOI_CHANGED,
    payload: number
}
export interface MucThuPhiHeChangedAction {
    type: MucThuPhiActionTypeIds.MUCTHUPHI_HE_CHANGED,
    payload: number
}
export interface MucThuPhiShowCopyModalAction {
    type: MucThuPhiActionTypeIds.MUC_THU_PHI_SHOW_COPY_MODAL
}
export interface MucThuPhiCloseCopyModalAction {
    type: MucThuPhiActionTypeIds.MUC_THU_PHI_CLOSE_COPY_MODAL
}

export type MucThuPhiActionTypes =
    MucThuPhiLoadStartAction | MucThuPhiLoadSuccessAction | MucThuPhiLoadErrorAction |
    MucThuPhiShowDetailModalAction | MucThuPhiShowDeleteConfirmAction |
    MucThuPhiCloseDetailModalAction | MucThuPhiCloseDeleteConfirmAction |
    MucThuPhiSaveStartAction | MucThuPhiSaveSuccessAction | MucThuPhiSaveErrorAction |
    MucThuPhiDeleteStartAction | MucThuPhiDeleteSuccessAction | MucThuPhiDeleteErrorAction |
    MucThuPhiChangeSelectedIdsAction |
    MucThuPhiNamHocChangedAction | MucThuPhiTruongChangedAction | MucThuPhiKhoiChangedAction | MucThuPhiHeChangedAction |
    MucThuPhiShowCopyModalAction | MucThuPhiCloseCopyModalAction