import { call, put, takeEvery } from "redux-saga/effects";
import { accountApi } from "../../api/accountApi";
import { initialUserInfo } from "../../models/response/account/user-info";
import { IBaseResponeModel } from "../../models/response/base-response";
import { AuthActionTypeIds } from "../action-types/authActionTypes";
import { setUser } from "../actions/authActions";


function* authSaga(): any {
    yield takeEvery(AuthActionTypeIds.GET_USER_AUTH, getUserWorker)
}

function* getUserWorker() {
    try {
        const res: IBaseResponeModel = yield call(accountApi.getUser);
        if (res.is_success) {
            yield put(setUser(res.data))
        } else {
            yield put(setUser(initialUserInfo))
        }
    } catch (error) {
        yield put(setUser(initialUserInfo))
    }
}
const setJWTToken = (access_token: string, refresh_token: string) => {
    localStorage.access_token = access_token;
    localStorage.refresh_token = refresh_token;

}
const clearJWTToken = () => {
    localStorage.access_token = null;
    localStorage.refresh_token = null;
}

export { authSaga, setJWTToken, clearJWTToken };

