import { call, put, takeEvery } from "redux-saga/effects";
import { mucPhaiThuChiTietApi } from "../../api/mucPhaiThuChiTietApi";
import { IBaseResponeModel } from "../../models/response/base-response";
import { TongHopChiTietActionTypeIds, TongHopChiTietLoadStart } from "../action-types/tongHopChiTietActionTypes";
import { loadTongHopChiTietError, loadTongHopChiTietSuccess } from "../actions/tongHopChiTietActions";

export function* tongHopChiTietSaga() {
    yield takeEvery(TongHopChiTietActionTypeIds.TONG_HOP_CHI_TIETLOAD_START, loadTongHopChiTietStartWorker)
}
function* loadTongHopChiTietStartWorker(data: TongHopChiTietLoadStart) {
    const res: IBaseResponeModel = yield call(mucPhaiThuChiTietApi.search, data.payload)
    if (res.is_success) {
        yield put(loadTongHopChiTietSuccess(res.data))
    } else {
        yield put(loadTongHopChiTietError(res.message || "Có lỗi"))
    }
}