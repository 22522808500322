import { showNotify } from "../../../helpers/toast";
import { PageBaseStatus } from "../../../models/pageBaseStatus";
import { EditTBTPHachToanActionIds, EditTBTPHachToanActionTypes } from "../../action-types/edit-tbtp/editTBTPHachToanActionTypes";
import { EditTBTPHachToanPageState } from "../../page-state-model/edit-tbtp/editTBTPHachToanPageState";

const initialState: EditTBTPHachToanPageState = {
    is_show_add_hachtoan_modal: false,
    is_show_remove_hachtoan_confirm: false,
    sf_hachtoan_congno_editing_id: 0,
    sf_hachtoan_congnos_added: [],
    sf_hachtoan_congnos_pending: [],
    sf_tbtp_viewmodel: undefined,
    status: PageBaseStatus.is_completed
}
export const editTBTPHachToanReducer = (state: EditTBTPHachToanPageState = initialState, action: EditTBTPHachToanActionTypes): EditTBTPHachToanPageState => {
    switch (action.type) {
        case EditTBTPHachToanActionIds.EDIT_TBTP_HACHTOAN_LOAD_HACHTOAN_ADDED_START:
            return {
                ...state,
                status: PageBaseStatus.is_loading,
            }
        case EditTBTPHachToanActionIds.EDIT_TBTP_HACHTOAN_LOAD_HACHTOAN_ADDED_SUCESS:
            return {
                ...state,
                status: PageBaseStatus.is_completed,
                sf_hachtoan_congnos_added: action.payload
            }
        case EditTBTPHachToanActionIds.EDIT_TBTP_HACHTOAN_LOAD_HACHTOAN_ADDED_ERROR:
            showNotify({ message: "Không tải được danh sách khoản quyết toán đã thêm", type: "warning" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }
        case EditTBTPHachToanActionIds.EDIT_TBTP_HACHTOAN_LOAD_HACHTOAN_PENDING_START:
            return {
                ...state
            }
        case EditTBTPHachToanActionIds.EDIT_TBTP_HACHTOAN_LOAD_HACHTOAN_PENDING_SUCCESS:
            return {
                ...state,
                sf_hachtoan_congnos_pending: action.payload
            }
        case EditTBTPHachToanActionIds.EDIT_TBTP_HACHTOAN_LOAD_HACHTOAN_PENDING_ERROR:
            showNotify({ message: "Không tải được danh sách khoản quyết toán đang pending", type: "warning" })
            return {
                ...state,
            }
        case EditTBTPHachToanActionIds.EDIT_TBTP_HACHTOAN_SHOW_DELETE_CONFIRM:
            return {
                ...state,
                sf_hachtoan_congno_editing_id: action.payload,
                is_show_remove_hachtoan_confirm: true
            }
        case EditTBTPHachToanActionIds.EDIT_TBTP_HACHTOAN_CLOSE_DELETE_CONFIRM:
            return {
                ...state,
                is_show_remove_hachtoan_confirm: false
            }
        case EditTBTPHachToanActionIds.EDIT_TBTP_HACHTOAN_SHOW_ADD_MODAL:
            return {
                ...state,
                is_show_add_hachtoan_modal: true
            }
        case EditTBTPHachToanActionIds.EDIT_TBTP_HACHTOAN_CLOSE_ADD_MODAL:
            return {
                ...state,
                is_show_add_hachtoan_modal: false
            }
        case EditTBTPHachToanActionIds.EDIT_TBTP_HACHTOAN_DELTE_START:
            return {
                ...state,
                status: PageBaseStatus.is_deleting
            }
        case EditTBTPHachToanActionIds.EDIT_TBTP_HACHTOAN_DELTE_SUCCESS:
            showNotify({ message: "Đã xóa khoản quyết toán", type: "success" })

            return {
                ...state,
                is_show_remove_hachtoan_confirm:false,
                status: PageBaseStatus.is_need_reload
            }
        case EditTBTPHachToanActionIds.EDIT_TBTP_HACHTOAN_DELTE_ERROR:
            showNotify({ message: "Xóa khoản quyết toán thất bại", type: "warning" })

            return {
                ...state,
                status: PageBaseStatus.is_completed
            }

        default:
            return state;
    }
}