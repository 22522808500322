import { localStorageHelper } from "../../helpers/localStorage";
import { showNotify } from "../../helpers/toast";
import { PageBaseStatus } from "../../models/pageBaseStatus";
import { HinhThucMienGiamActionTypeIds, HinhThucMienGiamActionTypes } from "../action-types/hinhThucMienGiamActionTypes";
import { HinhThucMienGiamPageState } from "../page-state-model/hinhThucMienGiamPageState";
const initialState: HinhThucMienGiamPageState = {
    sf_hinhthucmiengiams: [],
    sf_hinhthucmiengiam_selected_ids: [],
    status: PageBaseStatus.is_completed,
    sf_hinhthucmiengiam_hinhthuctinhs: [],
    nam_hoc: localStorageHelper.getNamHoc()
}
export const hinhThucMienGiamReducer = (state: HinhThucMienGiamPageState = initialState, action: HinhThucMienGiamActionTypes): HinhThucMienGiamPageState => {
    switch (action.type) {
        case HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_LOAD_START:
            return {
                ...state,
                status: PageBaseStatus.is_loading
            }
        case HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_LOAD_SUCCESS:
            return {
                ...state,
                status: PageBaseStatus.is_completed,
                sf_hinhthucmiengiams: action.payload
            }
        case HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_LOAD_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }
        case HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_SHOW_DETAIL_MODAL:
            return {
                ...state,
                is_show_detail_modal: true,
                sf_hinhthucmiengiam_editing: action.payload
            }
        case HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_CLOSE_DETAIL_MODAL:
            return {
                ...state,
                is_show_detail_modal: false,
                // sf_hinhthucmiengiam_editing: undefined
            }
        case HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_SHOW_DELETE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: true
            }
        case HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_CLOSE_DELETE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: false
            }
        case HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_SAVE_START:
            return {
                ...state,
                status: PageBaseStatus.is_saving
            }
        case HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_SAVE_SUCCESS:
            showNotify({
                message: `${action.payload.id > 0 ? "Cập nhật " : "Thêm mới "} thành công hình thức miễn giảm ${action.payload.ma_hinh_thuc}`,
                type: "success"
            })
            return {
                ...state,
                status: PageBaseStatus.is_need_reload,
                sf_hinhthucmiengiam_editing: undefined,
                is_show_detail_modal: false
            }
        case HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_SAVE_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }

        case HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_DELETE_START:
            return {
                ...state,
                status: PageBaseStatus.is_deleting
            }
        case HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_DELETE_SUCCESSS:
            showNotify({ message: `Xóa thành công ${action.payload.length} hình thức miễn giảm`, type: "success" })
            return {
                ...state,
                status: PageBaseStatus.is_need_reload,
                is_show_delete_confirm: false
            }
        case HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_DELETE_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }
        case HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_CHANGE_SELECTED_IDS:
            return {
                ...state,
                sf_hinhthucmiengiam_selected_ids: action.payload
            }
        case HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_HINHTHUCTINH_LOAD_SUCCESS:
            return {
                ...state,
                sf_hinhthucmiengiam_hinhthuctinhs: action.payload
            }
        case HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_CHANGE_NAMHOC:
            return {
                ...state,
                nam_hoc: action.payload
            }
        case HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_SHOW_ITEMS_MODAL:
            return {
                ...state,
                is_show_items_modal: true,
                sf_hinhthucmiengiam_editing: action.payload
            }
        case HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_CLOSE_ITEMS_MODAL:
            return {
                ...state,
                is_show_items_modal: false,
                sf_hinhthucmiengiam_editing: undefined
            }
            case HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_SHOW_COPY_MODAL:
            return {
                ...state,
                is_show_copy_modal: true,
            }
        case HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_CLOSE_COPY_MODAL:
            return {
                ...state,
                is_show_copy_modal: false,
            }
        default:
            return state;
    }
}