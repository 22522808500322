import { call, put, takeEvery } from "redux-saga/effects"
import { khoanNopApi } from "../../api/khoanNopApi"
import { IBaseResponeModel } from "../../models/response/base-response"
import { KhoanNopActionTypeIds, KhoanNopDeleteStartAction, KhoanNopSaveStartAction } from "../action-types/khoanNopActionTypes"
import {
    loadKhoanNopError, loadKhoanNopSuccess, saveKhoanNopError, saveKhoanNopSuccess,
    deleteKhoanNopSuccess, deleteKhoanNopError
} from "../actions/khoanNopActions"
export function* khoanNopSaga(): any {
    yield takeEvery(KhoanNopActionTypeIds.KHOANNOP_LOAD_START, loadKhoanNopStartWorker)
    yield takeEvery(KhoanNopActionTypeIds.KHOANNOP_SAVE_START, saveKhoanNopStartWorker)
    yield takeEvery(KhoanNopActionTypeIds.KHOANNOP_DELETE_START, deleteKhoanNopStartWorker)
}
function* loadKhoanNopStartWorker() {
    const res: IBaseResponeModel = yield call(khoanNopApi.select_all)
    if (res.is_success) {
        yield put(loadKhoanNopSuccess(res.data))
    } else {
        yield put(loadKhoanNopError(res.message || ""))
    }
}
function* saveKhoanNopStartWorker(data: KhoanNopSaveStartAction) {
    let res: IBaseResponeModel;
    if (data.payload.id > 0) {
        res = yield call(khoanNopApi.update, data.payload)
    } else {
        res = yield call(khoanNopApi.insert, data.payload)
    }

    if (res.is_success) {
        yield put(saveKhoanNopSuccess(data.payload))
        yield call(loadKhoanNopStartWorker)
    } else {
        yield put(saveKhoanNopError(res.message || ""))
    }
}
function* deleteKhoanNopStartWorker(data: KhoanNopDeleteStartAction) {
    const res: IBaseResponeModel = yield call(khoanNopApi.delete, data.payload)
    if (res.is_success) {
        yield put(deleteKhoanNopSuccess(data.payload))
        yield call(loadKhoanNopStartWorker)
    } else {
        yield put(deleteKhoanNopError(res.message || ""))
    }
}