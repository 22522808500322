import { NamHocTruongKhoiHeFilter } from "../../models/namHocTruongKhoiHeFilter"
import { ganMienGiamFilterStudentModel } from "../../models/request/gan-mien-giam/ganMienGiamFilterStudent"
import { sf_dexuatmiengiam_approve_rq } from "../../models/request/gan-mien-giam/sf_dexuatmiengiam_approve_rq"
import { sf_dexuatmiengiam_changed_rq } from "../../models/request/gan-mien-giam/sf_dexuatmiengiam_changed_rq"
import { sf_hinhthucmiengiam } from "../../models/response/category/sf_hinhthucmiengiam"
import { sf_dexuatmiengiam, sf_dexuatmiengiam_viewmodel } from "../../models/response/gan-mien-giam/sf_dexuatmiengiam"
import { sf_dexuatmiengiam_changed_res } from "../../models/response/gan-mien-giam/sf_dexuatmiengiam_changed_res"
import { ts_hocsinh_viewmodel } from "../../models/response/hocsinh/ts_hocsinh"

export enum GanMienGiamActionTypeIds {
    GAN_MIEN_GIAM_CHANGE_FILTER = "GAN_MIEN_GIAM_CHANGE_FILTER",
    GAN_MIEN_GIAM_CHANGE_FILTER_STUDENT = "GAN_MIEN_GIAM_CHANGE_FILTER_STUDENT",
    GAN_MIEN_GIAM_SHOW_FILTER_STUDENT_MODAL = "GAN_MIEN_GIAM_SHOW_FILTER_STUDENT_MODAL",
    GAN_MIEN_GIAM_CLOSE_FILTER_STUDENT_MODAL = "GAN_MIEN_GIAM_CLOSE_FILTER_STUDENT_MODAL",
    GAN_MIEN_GIAM_LOAD_START = "GAN_MIEN_GIAM_LOAD_START",
    GAN_MIEN_GIAM_LOAD_SUCCESS = "GAN_MIEN_GIAM_LOAD_SUCCESS",
    GAN_MIEN_GIAM_LOAD_ERROR = "GAN_MIEN_GIAM_LOAD_ERROR",

    GAN_MIEN_GIAM_LOAD_HINHTHUCMIENGIAM_START = "GAN_MIEN_GIAM_LOAD_HINHTHUCMIENGIAM_START",
    GAN_MIEN_GIAM_LOAD_HINHTHUCMIENGIAM_SUCCESS = "GAN_MIEN_GIAM_LOAD_HINHTHUCMIENGIAM_SUCCESS",

    GAN_MIEN_GIAM_LOAD_HOCSINH_START = "GAN_MIEN_GIAM_LOAD_HOCSINH_START",
    GAN_MIEN_GIAM_LOAD_HOCSINH_SUCCESS = "GAN_MIEN_GIAM_LOAD_HOCSINH_SUCCESS",

    GAN_MIEN_GIAM_CHANGE_START = "GAN_MIEN_GIAM_CHANGE_START",
    GAN_MIEN_GIAM_CHANGE_SUCCESS = "GAN_MIEN_GIAM_CHANGE_SUCCESS",
    GAN_MIEN_GIAM_CHANGE_ERR = "GAN_MIEN_GIAM_CHANGE_ERR",

    GAN_MIEN_GIAM_APPROVE_ALL_START = "GAN_MIEN_GIAM_APPROVE_ALL_START",
    GAN_MIEN_GIAM_APPROVE_ALL_CANCEL = "GAN_MIEN_GIAM_APPROVE_ALL_CANCEL",
    GAN_MIEN_GIAM_APPROVE_ALL_SUBMIT = "GAN_MIEN_GIAM_APPROVE_ALL_SUBMIT",
    GAN_MIEN_GIAM_APPROVE_ALL_SUCCESS = "GAN_MIEN_GIAM_APPROVE_ALL_SUCCESS",
    GAN_MIEN_GIAM_APPROVE_ALL_ERR = "GAN_MIEN_GIAM_APPROVE_ALL_ERR",

}
export interface GanMienGiamChangeFilterAction {
    type: GanMienGiamActionTypeIds.GAN_MIEN_GIAM_CHANGE_FILTER,
    payload: NamHocTruongKhoiHeFilter
}

export interface GanMienGiamChangeFilterStudentAction {
    type: GanMienGiamActionTypeIds.GAN_MIEN_GIAM_CHANGE_FILTER_STUDENT,
    payload: ganMienGiamFilterStudentModel
}

export interface GanMienGiamShowFilterStudentModalAction {
    type: GanMienGiamActionTypeIds.GAN_MIEN_GIAM_SHOW_FILTER_STUDENT_MODAL
}
export interface GanMienGiamCloseFilterStudentModalAction {
    type: GanMienGiamActionTypeIds.GAN_MIEN_GIAM_CLOSE_FILTER_STUDENT_MODAL
}

export interface GanMienGiamLoadStartAction {
    type: GanMienGiamActionTypeIds.GAN_MIEN_GIAM_LOAD_START,
    payload: NamHocTruongKhoiHeFilter
}
export interface GanMienGiamLoadSuccessAction {
    type: GanMienGiamActionTypeIds.GAN_MIEN_GIAM_LOAD_SUCCESS,
    payload: sf_dexuatmiengiam_viewmodel[]
}
export interface GanMienGiamLoadErrAction {
    type: GanMienGiamActionTypeIds.GAN_MIEN_GIAM_LOAD_ERROR,
    payload: string
}

export interface GanMienGiamLoadHinhThucMienGiamStartAction {
    type: GanMienGiamActionTypeIds.GAN_MIEN_GIAM_LOAD_HINHTHUCMIENGIAM_START,
    payload: string
}

export interface GanMienGiamLoadHinhThucMienGiamSuccessAction {
    type: GanMienGiamActionTypeIds.GAN_MIEN_GIAM_LOAD_HINHTHUCMIENGIAM_SUCCESS,
    payload: sf_hinhthucmiengiam[]
}

export interface GanMienGiamLoadHocSinhStartAction {
    type: GanMienGiamActionTypeIds.GAN_MIEN_GIAM_LOAD_HOCSINH_START,
    payload: NamHocTruongKhoiHeFilter
}


export interface GanMienGiamLoadHocSinhSuccessAction {
    type: GanMienGiamActionTypeIds.GAN_MIEN_GIAM_LOAD_HOCSINH_SUCCESS,
    payload: ts_hocsinh_viewmodel[]
}

export interface GanMienGiamChangeStartAction {
    type: GanMienGiamActionTypeIds.GAN_MIEN_GIAM_CHANGE_START,
    payload: sf_dexuatmiengiam_changed_rq
}
export interface GanMienGiamChangeSuccessAction {
    type: GanMienGiamActionTypeIds.GAN_MIEN_GIAM_CHANGE_SUCCESS,
    payload: sf_dexuatmiengiam_changed_res
}
export interface GanMienGiamChangeErrAction {
    type: GanMienGiamActionTypeIds.GAN_MIEN_GIAM_CHANGE_ERR,
    payload: string
}

export interface GanMienGiamApproveAllStartAction {
    type: GanMienGiamActionTypeIds.GAN_MIEN_GIAM_APPROVE_ALL_START,
    payload: sf_dexuatmiengiam_approve_rq
}

export interface GanMienGiamApproveAllSubmitAction {
    type: GanMienGiamActionTypeIds.GAN_MIEN_GIAM_APPROVE_ALL_SUBMIT,
}

export interface GanMienGiamApproveAllSubmitAction {
    type: GanMienGiamActionTypeIds.GAN_MIEN_GIAM_APPROVE_ALL_SUBMIT,
    payload: sf_dexuatmiengiam_approve_rq

}

export interface GanMienGiamApproveAllCancelAction {
    type: GanMienGiamActionTypeIds.GAN_MIEN_GIAM_APPROVE_ALL_CANCEL,
}

export interface GanMienGiamApproveAllSuccessAction {
    type: GanMienGiamActionTypeIds.GAN_MIEN_GIAM_APPROVE_ALL_SUCCESS,
}

export interface GanMienGiamApproveAllErrorAction {
    type: GanMienGiamActionTypeIds.GAN_MIEN_GIAM_APPROVE_ALL_ERR,
    payload: string
}

export type GanMienGiamActionTypes = GanMienGiamChangeFilterAction |
    GanMienGiamChangeFilterStudentAction |
    GanMienGiamShowFilterStudentModalAction | GanMienGiamCloseFilterStudentModalAction |
    GanMienGiamLoadStartAction | GanMienGiamLoadSuccessAction | GanMienGiamLoadErrAction |
    GanMienGiamLoadHinhThucMienGiamStartAction | GanMienGiamLoadHinhThucMienGiamSuccessAction |
    GanMienGiamLoadHocSinhStartAction | GanMienGiamLoadHocSinhSuccessAction |
    GanMienGiamChangeStartAction | GanMienGiamChangeSuccessAction | GanMienGiamChangeErrAction |
    GanMienGiamApproveAllStartAction | GanMienGiamApproveAllCancelAction |
    GanMienGiamApproveAllSubmitAction | GanMienGiamApproveAllSuccessAction | GanMienGiamApproveAllErrorAction
