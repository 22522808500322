import { sf_mucphaithu_insert_rq } from "../models/request/thu-phi/sf_mucphaithu_insert_from_khoannop_rq";
import { sf_mucphaithu_insert_from_tbtp_rq } from "../models/request/thu-phi/sf_mucphaithu_insert_from_tbtp_rq";
import { sf_mucphaithu_select_byhocsinh_rq } from "../models/request/thu-phi/sf_mucphaithu_select_byhocsinh_rq";
import { apiClient } from "./apiClient";

export const SF_MUCPHAITHU_SELECT_BY_HOCSINH = "sf_mucphaithu/select_by_hocsinh";

export const SF_MUCPHAITHU_SELECT_BY_SCT = "sf_mucphaithu/select_by_sct";


export const SF_MUCPHAITHU_INSERT_FROM_BOM = "sf_mucphaithu/insert_from_tbtp";
export const SF_MUCPHAITHU_INSERT_FROM_KHOANNOP = "sf_mucphaithu/insert";


export const SF_MUCPHAITHU_CHITIET_SELECT = "sf_mucphaithu_chitiet/select_by_sct";
export const SF_MUCPHAITHU_CHITIET_DELETE = "sf_mucphaithu_chitiet/delete";

export const SF_MUCPHAITHU_MIENGIAM_SELECT = "sf_mucphaithu_miengiam/select_by_sct";
export const SF_MUCPHAITHU_MIENGIAM_SAVECHANGES = "sf_mucphaithu_miengiam/savechanges";

export const SF_MUCPHAITHU_CHITIET_MIENGIAM_SELECT = "sf_mucphaithu_chitiet_miengiam/select_by_sct";

export const SF_MUCPHAITHU_HUY_CHUNG_TU = "sf_mucphaithu/huy_chung_tu";



export const thuPhiApi = {
    select_by_sct: (sf_mucphaithu_id: number) => {
        return apiClient.post(`${SF_MUCPHAITHU_SELECT_BY_SCT}?sf_mucphaithu_id=${sf_mucphaithu_id}`)
    },
    select_by_hocsinh: (rq: sf_mucphaithu_select_byhocsinh_rq) => {
        return apiClient.post(`${SF_MUCPHAITHU_SELECT_BY_HOCSINH}`, rq)
    },
    insert_from_bom: (rq: sf_mucphaithu_insert_from_tbtp_rq) => {
        return apiClient.post(`${SF_MUCPHAITHU_INSERT_FROM_BOM}`, rq)

    },
    insert_from_khoannop: (rq: sf_mucphaithu_insert_rq) => {
        return apiClient.post(`${SF_MUCPHAITHU_INSERT_FROM_KHOANNOP}`, rq)

    },
    select_chitiet: (sf_mucphaithu_id: number) => apiClient.post(`${SF_MUCPHAITHU_CHITIET_SELECT}?sf_mucphaithu_id=${sf_mucphaithu_id}`),
    select_miengiam: (sf_mucphaithu_id: number) => apiClient.post(`${SF_MUCPHAITHU_MIENGIAM_SELECT}?sf_mucphaithu_id=${sf_mucphaithu_id}`),
    select_miengiam_chitiet: (sf_mucphaithu_id: number) => apiClient.post(`${SF_MUCPHAITHU_CHITIET_MIENGIAM_SELECT}?sf_mucphaithu_id=${sf_mucphaithu_id}`),
    remove_khoannop: (id: number) => apiClient.post(`${SF_MUCPHAITHU_CHITIET_DELETE}?id=${id}`),
    huy_chungtu: (data: any) => apiClient.post(SF_MUCPHAITHU_HUY_CHUNG_TU, data),
    save_miengiams: (data: any) => apiClient.post(SF_MUCPHAITHU_MIENGIAM_SAVECHANGES, data),



}
