import { sf_loaikhoannop } from "../../models/response/category/sf_loaikhoannop"
import {
    LoaiKhoanNopActionTypeIds,
    LoaiKhoanNopLoadStartAction, LoaiKhoanNopLoadSuccessAction, LoaiKhoanNopLoadErrorAction, LoaiKhoanNopShowDetailModalAction,
    LoaiKhoanNopSaveStartAction, LoaiKhoanNopSaveSuccessAction, LoaiKhoanNopSaveErrorAction, LoaiKhoanNopDeleteStartAction, LoaiKhoanNopDeleteSuccessAction, LoaiKhoanNopDeleteErrorAction, LoaiKhoanNopChangeSelectedIdsAction, LoaiKhoanNopShowDeleteConfirmAction
} from "../action-types/loaiKhoanNopActionTypes"

export const loadLoaiKhoanNopStart = (): LoaiKhoanNopLoadStartAction => {
    return {
        type: LoaiKhoanNopActionTypeIds.LOAIKHOANNOP_LOAD_START
    }
}

export const loadLoaiKhoanNopSuccess = (payload: sf_loaikhoannop[]): LoaiKhoanNopLoadSuccessAction => {
    return {
        type: LoaiKhoanNopActionTypeIds.LOAIKHOANNOP_LOAD_SUCCESS,
        payload: payload
    }
}
export const loadLoaiKhoanNopError = (payload: string): LoaiKhoanNopLoadErrorAction => {
    return {
        type: LoaiKhoanNopActionTypeIds.LOAIKHOANNOP_LOAD_ERROR,
        payload: payload
    }
}

export const showLoaiKhoanNopDetailModal = (payload: boolean): LoaiKhoanNopShowDetailModalAction => {
    return {
        type: LoaiKhoanNopActionTypeIds.KHOANOP_SHOW_DETAIL_MODAL,
        payload: payload
    }
}

export const showLoaiKhoanNopDeleteConfirm = (payload: boolean): LoaiKhoanNopShowDeleteConfirmAction => {
    return {
        type: LoaiKhoanNopActionTypeIds.LOAIKHOANNOP_SHOW_DELETE_CONFIRM,
        payload: payload
    }
}

export const saveLoaiKhoanNopStart = (payload: sf_loaikhoannop): LoaiKhoanNopSaveStartAction => {
    return {
        type: LoaiKhoanNopActionTypeIds.LOAIKHOANNOP_SAVE_START,
        payload: payload
    }
}

export const saveLoaiKhoanNopSuccess = (payload: sf_loaikhoannop): LoaiKhoanNopSaveSuccessAction => {
    return {
        type: LoaiKhoanNopActionTypeIds.LOAIKHOANNOP_SAVE_SUCCESS,
        payload: payload
    }
}
export const saveLoaiKhoanNopError = (payload: string): LoaiKhoanNopSaveErrorAction => {
    return {
        type: LoaiKhoanNopActionTypeIds.LOAIKHOANNOP_SAVE_ERROR,
        payload: payload
    }
}

export const deleteLoaiKhoanNopStart = (payload: number[]): LoaiKhoanNopDeleteStartAction => {
    return {
        type: LoaiKhoanNopActionTypeIds.LOAIKHOANNOP_DELETE_START,
        payload: payload
    }
}

export const deleteLoaiKhoanNopSuccess = (payload: number[]): LoaiKhoanNopDeleteSuccessAction => {
    return {
        type: LoaiKhoanNopActionTypeIds.LOAIKHOANNOP_DELETE_SUCCESSS,
        payload: payload
    }
}
export const deleteLoaiKhoanNopError = (payload: string): LoaiKhoanNopDeleteErrorAction => {
    return {
        type: LoaiKhoanNopActionTypeIds.LOAIKHOANNOP_DELETE_ERROR,
        payload: payload
    }
}

export const changeLoaiKhoanNopSelectionIds = (payload: number[]): LoaiKhoanNopChangeSelectedIdsAction => {
    return {
        type: LoaiKhoanNopActionTypeIds.LOAIKHOANNOP_CHANGE_SELECTED_IDS,
        payload: payload
    }
}