import { sf_mucphaithu_search_rq } from "../../models/request/tonghop/sf_mucphaithu_search_rq"
import {
    TongHopLoadStart, TongHopLoadSucess,
    TongHopLoadError, TongHopActionTypeIds, TongHopChangeRequest,
    TongHopActionTypes
} from "../action-types/tongHopActionTypes"

export const loadTongHopStart = (rq: sf_mucphaithu_search_rq): TongHopLoadStart => {
    return {
        type: TongHopActionTypeIds.TONG_HOP_LOAD_START,
        payload: rq
    }
}

export const loadTongHopSuccess = (payload: any[]): TongHopLoadSucess => {
    return {
        type: TongHopActionTypeIds.TONG_HOP_LOAD_SUCCESS,
        payload: payload
    }
}

export const loadTongHopError= (payload: string): TongHopLoadError => {
    return {
        type: TongHopActionTypeIds.TONG_HOP_LOAD_ERROR,
        payload: payload
    }
}


export const changeTongHopRequest= (payload: sf_mucphaithu_search_rq): TongHopChangeRequest => {
    return {
        type: TongHopActionTypeIds.TONG_HOP_CHANGE_REQUEST,
        payload: payload
    }
}