import { IUserInfoModel } from "../../models/response/account/user-info";
import { AuthActionTypeIds, AuthActionTypes } from "../action-types/authActionTypes";
export interface IAuthState {
    isLoading: boolean,
    user_info?: IUserInfoModel
}
const initialState: IAuthState = {
    isLoading: false,
}
const authReducer = (state: IAuthState = initialState, action: AuthActionTypes): IAuthState => {
    switch (action.type) {
        case AuthActionTypeIds.GET_USER_AUTH:
            return {
                ...state,
                isLoading: true
            }
        case AuthActionTypeIds.SET_USER_AUTH:

            return {
                ...state,
                user_info: action.payload,
                isLoading: false,
            }
        case AuthActionTypeIds.CLEAR_USER_AUTH:
            return initialState
        default:
            return state;
    }
}
export { authReducer };

