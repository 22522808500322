import { call, put, takeEvery } from "redux-saga/effects";
import { mucPhaiThuApi } from "../../api/mucPhaiThuApi";
import { IBaseResponeModel } from "../../models/response/base-response";
import { TongHopActionTypeIds, TongHopLoadStart } from "../action-types/tongHopActionTypes";
import { loadTongHopError, loadTongHopSuccess } from "../actions/tongHocActions";

export function* tongHopSaga() {
    yield takeEvery(TongHopActionTypeIds.TONG_HOP_LOAD_START, loadTongHopStartWorker)
}
function* loadTongHopStartWorker(data: TongHopLoadStart) {
    const res: IBaseResponeModel = yield call(mucPhaiThuApi.search, data.payload)
    if (res.is_success) {
        yield put(loadTongHopSuccess(res.data))
    } else {
        yield put(loadTongHopError(res.message || "Có lỗi"))
    }
}