import { call, put, takeEvery } from "redux-saga/effects"
import { AccountActionTypeIds, ILoginStartAction } from "../action-types/accountActionTypes"
import { accountApi } from "../../api/accountApi"
import { ILoginRespone } from "../../models/response/account/login-response"
import { setUser } from "../actions/authActions"
import { loginError, loginSuccess } from "../actions/accountActions"
import { appInfo } from "../../AppInfo"
import { clearJWTToken, setJWTToken } from "./authSaga"

//watcher
export default function* accountSaga(): any {
    yield takeEvery(AccountActionTypeIds.LOGIN_START, loginStartWorker)
}


//workers
function* loginStartWorker(data: ILoginStartAction): any {
    const res: any = yield call(accountApi.logInSSO, data.payload)
    const result = res.data as ILoginRespone;
    if (result.is_success && result.data && result.data.user_info) {
        setJWTToken(result.data.access_token, result.data.refresh_token);
        yield put(loginSuccess())
        yield put(setUser(result.data.user_info))
        return true;
    } else {
        yield put(loginError(result.message || "Đăng nhập thất bại"))
        clearJWTToken();
        window.location.href = appInfo.homeURL;
    }
    return false;

}
