import { call, put, takeEvery } from "redux-saga/effects"
import { mucThuPhiApi } from "../../api/mucThuPhiApi"
import { IBaseResponeModel } from "../../models/response/base-response"
import { MucThuPhiActionTypeIds, MucThuPhiDeleteStartAction, MucThuPhiLoadStartAction, MucThuPhiSaveStartAction } from "../action-types/mucThuPhiActionTypes"
import {
    loadMucThuPhiError, loadMucThuPhiSuccess, saveMucThuPhiError, saveMucThuPhiSuccess,
    deleteMucThuPhiSuccess, deleteMucThuPhiError
} from "../actions/mucThuPhiActions"
export function* mucThuPhiSaga(): any {
    yield takeEvery(MucThuPhiActionTypeIds.MUCTHUPHI_LOAD_START, loadMucThuPhiStartWorker)
    yield takeEvery(MucThuPhiActionTypeIds.MUCTHUPHI_SAVE_START, saveMucThuPhiStartWorker)
    yield takeEvery(MucThuPhiActionTypeIds.MUCTHUPHI_DELETE_START, deleteMucThuPhiStartWorker)
}
function* loadMucThuPhiStartWorker(data: MucThuPhiLoadStartAction) {
    const res: IBaseResponeModel = yield call(mucThuPhiApi.select,data.payload)
    if (res.is_success) {
        yield put(loadMucThuPhiSuccess(res.data))
    } else {
        yield put(loadMucThuPhiError(res.message || ""))
    }
}
function* saveMucThuPhiStartWorker(data: MucThuPhiSaveStartAction) {
    let res: IBaseResponeModel;
    if (data.payload.id > 0) {
        res = yield call(mucThuPhiApi.update, data.payload)
    } else {
        res = yield call(mucThuPhiApi.insert, data.payload)
    }

    if (res.is_success) {
        yield put(saveMucThuPhiSuccess(data.payload))
    } else {
        yield put(saveMucThuPhiError(res.message || ""))
    }
}
function* deleteMucThuPhiStartWorker(data: MucThuPhiDeleteStartAction) {
    const res: IBaseResponeModel = yield call(mucThuPhiApi.delete, data.payload)
    if (res.is_success) {
        yield put(deleteMucThuPhiSuccess(data.payload))
    } else {
        yield put(deleteMucThuPhiError(res.message || ""))
    }
}