import { sf_mucphaithu_search_rq } from "../../models/request/tonghop/sf_mucphaithu_search_rq"
import {
    TongHopChiTietLoadStart, TongHopChiTietLoadSucess,
    TongHopChiTietLoadError, TongHopChiTietActionTypeIds, TongHopChiTietChangeRequest,
    TongHopChiTietActionTypes
} from "../action-types/tongHopChiTietActionTypes"

export const loadTongHopChiTietStart = (rq: sf_mucphaithu_search_rq): TongHopChiTietLoadStart => {
    return {
        type: TongHopChiTietActionTypeIds.TONG_HOP_CHI_TIETLOAD_START,
        payload: rq
    }
}

export const loadTongHopChiTietSuccess = (payload: any[]): TongHopChiTietLoadSucess => {
    return {
        type: TongHopChiTietActionTypeIds.TONG_HOP_CHI_TIETLOAD_SUCCESS,
        payload: payload
    }
}

export const loadTongHopChiTietError= (payload: string): TongHopChiTietLoadError => {
    return {
        type: TongHopChiTietActionTypeIds.TONG_HOP_CHI_TIETLOAD_ERROR,
        payload: payload
    }
}


export const changeTongHopChiTietRequest= (payload: sf_mucphaithu_search_rq): TongHopChiTietChangeRequest => {
    return {
        type: TongHopChiTietActionTypeIds.TONG_HOP_CHI_TIETCHANGE_REQUEST,
        payload: payload
    }
}