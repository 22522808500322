import { localStorageHelper } from "../../helpers/localStorage";
import { showNotify } from "../../helpers/toast";
import { PageBaseStatus } from "../../models/pageBaseStatus";
import { TongHopChiTietActionTypeIds, TongHopChiTietActionTypes } from "../action-types/tongHopChiTietActionTypes";
import { TongHopChiTietPageState } from "../page-state-model/tongHopChiTietPageStatus";
const initalState: TongHopChiTietPageState = {
    dataSource: [],
    request: {
        username: "",
        den_ngay_ts: Math.floor(new Date().getTime() / 1000),
        dm_truong_id: 0,
        ma_hs: "",
        nam_hoc: localStorageHelper.getNamHoc(),
        tu_ngay_ts: Math.floor(new Date().getTime() / 1000)
    },
    status: PageBaseStatus.is_completed
}

export const tongHopChiTietReducer = (state: TongHopChiTietPageState = initalState, action: TongHopChiTietActionTypes): TongHopChiTietPageState => {
    switch (action.type) {
        case TongHopChiTietActionTypeIds.TONG_HOP_CHI_TIETLOAD_START:
            return {
                ...state,
                status: PageBaseStatus.is_loading
            }
        case TongHopChiTietActionTypeIds.TONG_HOP_CHI_TIETLOAD_SUCCESS:
            return {
                ...state,
                status: PageBaseStatus.is_completed,
                dataSource: action.payload
            }
        case TongHopChiTietActionTypeIds.TONG_HOP_CHI_TIETLOAD_ERROR:
            showNotify({ message: "Không tải được dữ liệu", type: "warning" });
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }
        case TongHopChiTietActionTypeIds.TONG_HOP_CHI_TIETCHANGE_REQUEST:
            return {
                ...state,
                request: action.payload
            }

        default:
            return state;
    }

}