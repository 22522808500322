import { IUserInfoModel } from "../../models/response/account/user-info"
export enum AuthActionTypeIds {
    GET_USER_AUTH = "GET_USET_AUTH",
    SET_USER_AUTH = "SET_USER_AUTH",
    CLEAR_USER_AUTH = "CLER_USET_AUTH"
}
export interface IGetUserAuthAction {
    type: AuthActionTypeIds.GET_USER_AUTH,
}
export interface ISetUserAuthAction {
    type: AuthActionTypeIds.SET_USER_AUTH,
    payload: IUserInfoModel
}
export interface IClerUserAuthAction {
    type: AuthActionTypeIds.CLEAR_USER_AUTH
}

export type AuthActionTypes = IGetUserAuthAction | IClerUserAuthAction | ISetUserAuthAction