import { IBaseDeleteRequestModel } from "../models/request/base/deleteRequest";
import { sf_ngaynghi_rq } from "../models/response/category/sf_ngaynghi";
import { apiClient } from "./apiClient";

export const SF_NGAYNGHI_SELECT_ALL_API = "sf_ngaynghi/select_all";
export const SF_NGAYNGHI_INSERT_API = "sf_ngaynghi/insert";
export const SF_NGAYNGHI_UPDATE_API = "sf_ngaynghi/update";
export const SF_NGAYNGHI_DELETE_API = "sf_ngaynghi/delete";


export const ngayNghiApi = {
    select_all: () => {
        return apiClient.post(`${SF_NGAYNGHI_SELECT_ALL_API}`)
    },
    insert: (data: sf_ngaynghi_rq) => {
        return apiClient.post(`${SF_NGAYNGHI_INSERT_API}`, data)
    },
    update: (data: sf_ngaynghi_rq) => {
        return apiClient.post(`${SF_NGAYNGHI_UPDATE_API}`, data)
    },
    delete: (data: number[]) => {
        const model: IBaseDeleteRequestModel = {
            ids: data
        }
        return apiClient.post(`${SF_NGAYNGHI_DELETE_API}`, model)
    },

}