import { sf_khoannop } from "../../models/response/category/sf_khoannop"
export enum KhoanNopActionTypeIds {
    KHOANNOP_LOAD_START = "KHOANNOP_LOAD_START",
    KHOANNOP_LOAD_SUCCESS = "KHOANNOP_LOAD_SUCCESS",
    KHOANNOP_LOAD_ERROR = "KHOANNOP_LOAD_ERROR",

    KHOANNOP_SHOW_DETAIL_MODAL = "KHOANNOP_SHOW_DETAIL_MODAL",
    KHOANNOP_CLOSE_DETAIL_MODAL = "KHOANNOP_CLOSE_DETAIL_MODAL",

    KHOANNOP_SHOW_DELETE_CONFIRM = "KHOANNOP_SHOW_DELETE_CONFIRM",
    KHOANNOP_CLOSE_DELETE_CONFIRM = "KHOANNOP_CLOSE_DELETE_CONFIRM",

    KHOANNOP_SAVE_START = "KHOANNOP_SAVE_START",
    KHOANNOP_SAVE_SUCCESS = "KHOANNOP_SAVE_SUCCESS",
    KHOANNOP_SAVE_ERROR = "KHOANNOP_SAVE_ERROR",

    KHOANNOP_DELETE_START = "KHOANNOP_DELETE_START",
    KHOANNOP_DELETE_SUCCESSS = "KHOANNOP_DELETE_SUCCESSS",
    KHOANNOP_DELETE_ERROR = "KHOANNOP_DELETE_ERROR",

    KHOANNOP_CHANGE_SELECTED_IDS = "KHOANNOP_CHANGE_SELECTED_IDS"

}

export interface KhoanNopLoadStartAction {
    type: KhoanNopActionTypeIds.KHOANNOP_LOAD_START
}
export interface KhoanNopLoadSuccessAction {
    type: KhoanNopActionTypeIds.KHOANNOP_LOAD_SUCCESS,
    payload: sf_khoannop[]
}
export interface KhoanNopLoadErrorAction {
    type: KhoanNopActionTypeIds.KHOANNOP_LOAD_ERROR,
    payload: string
}

export interface KhoanNopShowDetailModalAction {
    type: KhoanNopActionTypeIds.KHOANNOP_SHOW_DETAIL_MODAL,
    payload?: sf_khoannop
}
export interface KhoanNopCloseDetailModalAction {
    type: KhoanNopActionTypeIds.KHOANNOP_CLOSE_DETAIL_MODAL,
}
export interface KhoanNopShowDeleteConfirmAction {
    type: KhoanNopActionTypeIds.KHOANNOP_SHOW_DELETE_CONFIRM,
}
export interface KhoanNopCloseDeleteConfirmAction {
    type: KhoanNopActionTypeIds.KHOANNOP_CLOSE_DELETE_CONFIRM,
}

export interface KhoanNopSaveStartAction {
    type: KhoanNopActionTypeIds.KHOANNOP_SAVE_START,
    payload: sf_khoannop
}
export interface KhoanNopSaveSuccessAction {
    type: KhoanNopActionTypeIds.KHOANNOP_SAVE_SUCCESS,
    payload: sf_khoannop
}
export interface KhoanNopSaveErrorAction {
    type: KhoanNopActionTypeIds.KHOANNOP_SAVE_ERROR,
    payload: string
}
export interface KhoanNopDeleteStartAction {
    type: KhoanNopActionTypeIds.KHOANNOP_DELETE_START,
    payload: number[]
}
export interface KhoanNopDeleteSuccessAction {
    type: KhoanNopActionTypeIds.KHOANNOP_DELETE_SUCCESSS,
    payload: number[]
}
export interface KhoanNopDeleteErrorAction {
    type: KhoanNopActionTypeIds.KHOANNOP_DELETE_ERROR,
    payload: string
}

export interface KhoanNopChangeSelectedIdsAction {
    type: KhoanNopActionTypeIds.KHOANNOP_CHANGE_SELECTED_IDS,
    payload: number[]
}
export type KhoanNopActionTypes =
    KhoanNopLoadStartAction | KhoanNopLoadSuccessAction | KhoanNopLoadErrorAction |
    KhoanNopShowDetailModalAction | KhoanNopShowDeleteConfirmAction |
    KhoanNopCloseDetailModalAction | KhoanNopCloseDeleteConfirmAction |
    KhoanNopSaveStartAction | KhoanNopSaveSuccessAction | KhoanNopSaveErrorAction |
    KhoanNopDeleteStartAction | KhoanNopDeleteSuccessAction | KhoanNopDeleteErrorAction |
    KhoanNopChangeSelectedIdsAction