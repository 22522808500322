import { sf_bom_copy_rq } from "../../models/request/bom/sf_bom_copy_rq"
import { sf_bom_selecte_rq } from "../../models/request/bom/sf_bom_select_rq"
import { sf_bom } from "../../models/response/bom/sf_bom"
import {
    BOMActionTypeIds, BOMChangeSelectedIdsAction, BOMCloseBomChiTietModalAction, BOMCloseCopyModal, BOMCloseCopyNamHocModal, BOMCloseDeleteConfirmAction, BOMCloseDetailModalAction, BOMCopyErrAction, BOMCopyStartAction, BOMCopySuccessAction, BOMDeleteErrorAction, BOMDeleteStartAction, BOMDeleteSuccessAction, BOMHeChangedAction, BOMKhoiChangedAction, BOMLoadErrorAction, BOMLoadStartAction, BOMLoadSuccessAction, BOMNamHocChangedAction, BOMSaveErrorAction, BOMSaveStartAction, BOMSaveSuccessAction, BOMShowBomChiTietModalAction, BOMShowCopyModalAction, BOMShowCopyNamHocModalAction, BOMShowDeleteConfirmAction, BOMShowDetailModalAction, BOMTruongChangedAction
} from "../action-types/bomActionTypes"

export const loadBOMStart = (payload: sf_bom_selecte_rq): BOMLoadStartAction => {
    return {
        type: BOMActionTypeIds.BOM_LOAD_START,
        payload: payload
    }
}

export const loadBOMSuccess = (payload: sf_bom[]): BOMLoadSuccessAction => {
    return {
        type: BOMActionTypeIds.BOM_LOAD_SUCCESS,
        payload: payload
    }
}
export const loadBOMError = (payload: string): BOMLoadErrorAction => {
    return {
        type: BOMActionTypeIds.BOM_LOAD_ERROR,
        payload: payload
    }
}

export const showBOMDetailModal = (payload?: sf_bom): BOMShowDetailModalAction => {
    return {
        type: BOMActionTypeIds.BOM_SHOW_DETAIL_MODAL,
        payload: payload
    }
}

export const closeBOMDetailModal = (): BOMCloseDetailModalAction => {
    return {
        type: BOMActionTypeIds.BOM_CLOSE_DETAIL_MODAL
    }
}

export const showBOMDeleteConfirm = (): BOMShowDeleteConfirmAction => {
    return {
        type: BOMActionTypeIds.BOM_SHOW_DELETE_CONFIRM,
    }
}

export const closeBOMDeleteConfirm = (): BOMCloseDeleteConfirmAction => {
    return {
        type: BOMActionTypeIds.BOM_CLOSE_DELETE_CONFIRM,
    }
}

export const saveBOMStart = (payload: sf_bom): BOMSaveStartAction => {
    return {
        type: BOMActionTypeIds.BOM_SAVE_START,
        payload: payload
    }
}

export const saveBOMSuccess = (payload: sf_bom): BOMSaveSuccessAction => {
    return {
        type: BOMActionTypeIds.BOM_SAVE_SUCCESS,
        payload: payload
    }
}
export const saveBOMError = (payload: string): BOMSaveErrorAction => {
    return {
        type: BOMActionTypeIds.BOM_SAVE_ERROR,
        payload: payload
    }
}

export const deleteBOMStart = (payload: number[]): BOMDeleteStartAction => {
    return {
        type: BOMActionTypeIds.BOM_DELETE_START,
        payload: payload
    }
}

export const deleteBOMSuccess = (payload: number[]): BOMDeleteSuccessAction => {
    return {
        type: BOMActionTypeIds.BOM_DELETE_SUCCESSS,
        payload: payload
    }
}
export const deleteBOMError = (payload: string): BOMDeleteErrorAction => {
    return {
        type: BOMActionTypeIds.BOM_DELETE_ERROR,
        payload: payload
    }
}

export const changeBOMSelectionIds = (payload: number[]): BOMChangeSelectedIdsAction => {
    return {
        type: BOMActionTypeIds.BOM_CHANGE_SELECTED_IDS,
        payload: payload
    }
}


export const changeBOMNamHoc = (payload: string): BOMNamHocChangedAction => {
    return {
        type: BOMActionTypeIds.BOM_NAMHOC_CHANGED,
        payload: payload
    }
}

export const changeBOMTruong = (payload: number): BOMTruongChangedAction => {
    return {
        type: BOMActionTypeIds.BOM_TRUONG_CHANGED,
        payload: payload
    }
}
export const changeBOMHe = (payload: number): BOMHeChangedAction => {
    return {
        type: BOMActionTypeIds.BOM_HE_CHANGED,
        payload: payload
    }
}
export const changeBOMKhoi = (payload: number): BOMKhoiChangedAction => {
    return {
        type: BOMActionTypeIds.BOM_KHOI_CHANGED,
        payload: payload
    }
}

export const showBOMBomChiTietModal = (payload: sf_bom): BOMShowBomChiTietModalAction => {
    return {
        type: BOMActionTypeIds.BOM_SHOW_BOMCHIETIET_MODAL,
        payload: payload
    }
}
export const closeBOMBomChiTietModal = (): BOMCloseBomChiTietModalAction => {
    return {
        type: BOMActionTypeIds.BOM_CLOSE_BOMCHITIET_MODAL
    }
}

export const showBomCopyModal = (payload: sf_bom): BOMShowCopyModalAction => {
    return {
        type: BOMActionTypeIds.BOM_SHOW_COPY_MODAL,
        payload: payload
    }
}
export const closeBomCopyModal = (): BOMCloseCopyModal => {
    return {
        type: BOMActionTypeIds.BOM_CLOSE_COPY_MODAL
    }
}

export const showBomCopyNamHocModal = (): BOMShowCopyNamHocModalAction => {
    return {
        type: BOMActionTypeIds.BOM_SHOW_COPY_NAMHOC_MODAL
    }
}
export const closeBomCopyNamHocModal = (): BOMCloseCopyNamHocModal => {
    return {
        type: BOMActionTypeIds.BOM_CLOSE_COPY_NAMHOC_MODAL
    }
}


export const copyBomStart = (payload: sf_bom_copy_rq): BOMCopyStartAction => {
    return {
        type: BOMActionTypeIds.BOM_COPY_START,
        payload: payload
    }
}

export const copyBomSuccess = (): BOMCopySuccessAction => {
    return {
        type: BOMActionTypeIds.BOM_COPY_SUCCESS,
    }
}
export const copyBomErr = (payload: string): BOMCopyErrAction => {
    return {
        type: BOMActionTypeIds.BOM_COPY_ERR,
        payload: payload
    }
}