import { IBaseDeleteRequestModel } from "../models/request/base/deleteRequest";
import { sf_mucthuphi_select_rq } from "../models/request/mucthuphi/sf_mucthiphi_select_rq";
import { sf_tbtp_add_hachtoan_rq } from "../models/request/tbtp/sf_tbtp_add_hachtoan_rq";
import { sf_tbtp_add_khoannop_rq } from "../models/request/tbtp/sf_tbtp_add_khoannop_rq";
import { sf_tbtp_auto_hachtoan_rq } from "../models/request/tbtp/sf_tbtp_auto_hachtoan_rq";
import { sf_tbtp_insert_rq } from "../models/request/tbtp/sf_tbtp_insert_rq";
import { sf_tbtp_pdf_create_rq } from "../models/request/tbtp/sf_tbtp_pdf_create_rq";
import { sf_tbtp_remove_hachtoan_rq } from "../models/request/tbtp/sf_tbtp_remove_hachtoan_rq";
import { sf_tbtp_remove_khoannop_rq } from "../models/request/tbtp/sf_tbtp_remove_khoannop_rq";
import { sf_tbtp_select_by_hocsinh_rq } from "../models/request/tbtp/sf_tbtp_select_by_hocsinh_rq";
import { sf_tbtp_send_rq } from "../models/request/tbtp/sf_tbtp_send_rq";
import { apiClient } from "./apiClient";

export const SF_TBTP_SELECT_API = "sf_tbtp/select";
export const SF_TBTP_INSERT_API = "sf_tbtp/insert";
export const SF_TBTP_DELETE_API = "sf_tbtp/delete";

export const ST_TBTP_SELECT_KHOANNOP_API = "sf_tbtp_khoannop/select_by_tbtp";
export const ST_TBTP_SELECT_HACHTOAN_API = "sf_tbtp_khoannop/select_hachtoan";


export const ST_TBTP_ADD_HACHTOAN_API = "sf_tbtp/add_hachtoan";
export const ST_TBTP_REMOVE_HACHTOAN_API = "sf_tbtp/remove_hachtoan";

export const ST_TBTP_ADD_KHOANNOP_API = "sf_tbtp/add_khoannop";
export const SF_TBTP_REMOVE_KHOANNOP_API="sf_tbtp/remove_khoannop";

export const SF_TBTP_CREATE_PDF_API="sf_tbtp/create_pdf";

export const SF_TBTP_SELECT_BY_HOCSINH_API="sf_tbtp/select_by_hocsinh";

export const ST_TBTP_AUTO_HACHTOAN_API="sf_tbtp/auto_hachtoan";
export const SF_TBTP_SEND_TBTP_API="sf_tbtp/send_tbtp";



export const tbtpApi = {
    select: (request: sf_mucthuphi_select_rq) => {
        return apiClient.post(`${SF_TBTP_SELECT_API}`, request)
    },
    insert: (data: sf_tbtp_insert_rq) => {
        return apiClient.post(`${SF_TBTP_INSERT_API}`, data)
    },
    delete: (data: number[]) => {
        const model: IBaseDeleteRequestModel = {
            ids: data
        }
        return apiClient.post(`${SF_TBTP_DELETE_API}`, model)
    },
    select_khoannops: (sf_tbtp_id: number) => apiClient.post(`${ST_TBTP_SELECT_KHOANNOP_API}?sf_tbtp_id=${sf_tbtp_id}`),
    select_hachtoans: (sf_tbtp_id: number) => apiClient.post(`${ST_TBTP_SELECT_HACHTOAN_API}?sf_tbtp_id=${sf_tbtp_id}`),
    add_hachtoan: (data: sf_tbtp_add_hachtoan_rq) => apiClient.post(ST_TBTP_ADD_HACHTOAN_API, data),
    add_khoannop: (data: sf_tbtp_add_khoannop_rq) => apiClient.post(ST_TBTP_ADD_KHOANNOP_API, data),
    remove_hachtoan: (data: sf_tbtp_remove_hachtoan_rq) => apiClient.post(ST_TBTP_REMOVE_HACHTOAN_API, data),
    remove_khoannop: (data: sf_tbtp_remove_khoannop_rq) => apiClient.post(SF_TBTP_REMOVE_KHOANNOP_API, data),
    create_pdf: (data: sf_tbtp_pdf_create_rq) => apiClient.post(SF_TBTP_CREATE_PDF_API, data),
    select_by_hocsinh: (data: sf_tbtp_select_by_hocsinh_rq) => apiClient.post(SF_TBTP_SELECT_BY_HOCSINH_API, data),
    auto_hachtoan: (data: sf_tbtp_auto_hachtoan_rq) => apiClient.post(`${ST_TBTP_AUTO_HACHTOAN_API}`, data),
    send_tbtp: (data: sf_tbtp_send_rq) => apiClient.post(`${SF_TBTP_SEND_TBTP_API}`, data)



}

