import { call, put, takeEvery } from "redux-saga/effects"
import { tbtpApi } from "../../api/tbtpApi"
import { IBaseResponeModel } from "../../models/response/base-response"
import { TBTPActionTypeIds, TBTPAutoHachToanStart, TBTPCreatePdfStart, TBTPDeleteStartAction, TBTPLoadStartAction, TBTPSaveStartAction, TBTPSendStart } from "../action-types/tbtpActionTypes"
import { autoHachToanError, autoHachToanSuccess, createPdfError, createPdfSuccess, deleteTBTPError, deleteTBTPSuccess, loadTBTPError, loadTBTPSuccess, sendTBTPError, sendTBTPSuccess } from "../actions/tbtpActions"
export function* tbtpSagas(): any {
    yield takeEvery(TBTPActionTypeIds.TBTP_LOAD_START, loadTBTPStartWorker)
    yield takeEvery(TBTPActionTypeIds.TBTP_SAVE_START, saveTBTPStartWorker)
    yield takeEvery(TBTPActionTypeIds.TBTP_DELETE_START, deleteTBTPStartWorker)
    yield takeEvery(TBTPActionTypeIds.TBTP_CREATE_PDF_START, createPdfStartWorker)
    yield takeEvery(TBTPActionTypeIds.TBTP_AUTO_HACHTOAN_START, autoHachToanWorker)
    yield takeEvery(TBTPActionTypeIds.TBTP_SEND_START, sendWorker)
}



function* sendWorker(data: TBTPSendStart) {
    const res: IBaseResponeModel = yield call(tbtpApi.send_tbtp, data.payload)
    if (res.is_success) {
        yield put(sendTBTPSuccess())
    } else {
        yield put(sendTBTPError(res.message || ""))
    }
}

function* autoHachToanWorker(data: TBTPAutoHachToanStart) {
    const res: IBaseResponeModel = yield call(tbtpApi.auto_hachtoan, data.payload)
    if (res.is_success) {
        yield put(autoHachToanSuccess())
    } else {
        yield put(autoHachToanError(res.message || ""))
    }
}

function* loadTBTPStartWorker(data: TBTPLoadStartAction) {
    const res: IBaseResponeModel = yield call(tbtpApi.select, data.payload)
    if (res.is_success) {
        yield put(loadTBTPSuccess(res.data))
    } else {
        yield put(loadTBTPError(res.message || ""))
    }
}
function* saveTBTPStartWorker(data: TBTPSaveStartAction) {
    
}
function* deleteTBTPStartWorker(data: TBTPDeleteStartAction) {
    const res: IBaseResponeModel = yield call(tbtpApi.delete, data.payload)
    if (res.is_success) {
        yield put(deleteTBTPSuccess(data.payload))

    } else {
        yield put(deleteTBTPError(res.message || ""))
    }
}
function* createPdfStartWorker(data: TBTPCreatePdfStart) {
    const res: IBaseResponeModel = yield call(tbtpApi.create_pdf, data.payload)
    if (res.is_success) {
        yield put(createPdfSuccess())

    } else {
        yield put(createPdfError(res.message || ""))
    }
}