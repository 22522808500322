import { apiClient } from "./apiClient";

export const DM_NAMHOC_SELECT_ALL = "dm_namhoc/select_all";
export const DM_TRUONG_SELECT_ALL = "dm_truong/select_all";
export const DM_KHOI_SELECT_ALL = "dm_khoi/select_all";
export const DM_KHOI_SELECT_BY_TRUONG = "dm_khoi/select_by_truong";
export const DM_HE_SELECT_ALL = "dm_he/select_all";
export const DM_HE_SELECT_BY_KHOI = "dm_he/select_by_khoi";
export const DM_TRUONG_TREEVIEW_SELECT = "dm_truong/select_treeview";
export const DM_TRUONG_KHOI_HE_SELECT_ALL = "dm_truong/select_all_khoi_he";
export const SF_HINHTHUCNOPBOM_SELECT_ALL = "sf_hinhthucnopbom/select_all";
export const SF_HINHTHUCNOPBOM_SELECT_BY_BOM = "sf_hinhthucnopbom/select_by_bom"


export const categorySourceApi = {
    dm_hes: () => apiClient.post(DM_HE_SELECT_ALL),
    dm_hes_by_khoi: (dm_khoi_id: number) => apiClient.post(DM_HE_SELECT_BY_KHOI + "?dm_khoi_id=" + dm_khoi_id.toString()),
    dm_khois: () => apiClient.post(DM_KHOI_SELECT_ALL),
    dm_khois_by_truong: (dm_truong_id: number) => apiClient.post(DM_KHOI_SELECT_BY_TRUONG + "?dm_truong_id=" + dm_truong_id.toString()),
    dm_namhocs: () => apiClient.post(DM_NAMHOC_SELECT_ALL),
    dm_truongs: () => apiClient.post(DM_TRUONG_SELECT_ALL),
    dm_truong_treeview: () => apiClient.post(DM_TRUONG_TREEVIEW_SELECT),
    dm_truong_khoi_hes: () => apiClient.post(DM_TRUONG_KHOI_HE_SELECT_ALL),
    sf_hinhthucnopboms: () => apiClient.post(SF_HINHTHUCNOPBOM_SELECT_ALL),
    sf_hinhthucnopboms_bybom: (sf_bom_id: number) => apiClient.post(SF_HINHTHUCNOPBOM_SELECT_BY_BOM + "?sf_bom_id=" + sf_bom_id.toString())
}