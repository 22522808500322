import { localStorageHelper } from "../../helpers/localStorage";
import { showNotify } from "../../helpers/toast";
import { PageBaseStatus } from "../../models/pageBaseStatus";
import { TongHopActionTypeIds, TongHopActionTypes } from "../action-types/tongHopActionTypes";
import { TongHopPageState } from "../page-state-model/tongHopPageState";
const initalState: TongHopPageState = {
    dataSource: [],
    request: {
        username: "",
        den_ngay_ts: Math.floor(new Date().getTime() / 1000),
        dm_truong_id: 0,
        ma_hs: "",
        nam_hoc: localStorageHelper.getNamHoc(),
        tu_ngay_ts: Math.floor(new Date().getTime() / 1000)
    },
    status: PageBaseStatus.is_completed
}

export const tongHopReducer = (state: TongHopPageState = initalState, action: TongHopActionTypes): TongHopPageState => {
    switch (action.type) {
        case TongHopActionTypeIds.TONG_HOP_LOAD_START:
            return {
                ...state,
                status: PageBaseStatus.is_loading
            }
        case TongHopActionTypeIds.TONG_HOP_LOAD_SUCCESS:
            return {
                ...state,
                status: PageBaseStatus.is_completed,
                dataSource: action.payload
            }
        case TongHopActionTypeIds.TONG_HOP_LOAD_ERROR:
            showNotify({ message: "Không tải được dữ liệu", type: "warning" });
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }
        case TongHopActionTypeIds.TONG_HOP_CHANGE_REQUEST:
            return {
                ...state,
                request: action.payload
            }

        default:
            return state;
    }

}