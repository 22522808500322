import { sf_mucphaithu_search_rq } from "../../models/request/tonghop/sf_mucphaithu_search_rq"
export enum TongHopChiTietActionTypeIds {
    TONG_HOP_CHI_TIETLOAD_START = "TONG_HOP_CHI_TIETLOAD_START",
    TONG_HOP_CHI_TIETLOAD_SUCCESS = "TONG_HOP_CHI_TIETLOAD_SUCCESS",
    TONG_HOP_CHI_TIETLOAD_ERROR = "TONG_HOP_CHI_TIETLOAD_ERROR",
    TONG_HOP_CHI_TIETCHANGE_REQUEST = "TONG_HOP_CHI_TIETCHANGE_REQUEST"
}

export interface TongHopChiTietLoadStart {
    type: TongHopChiTietActionTypeIds.TONG_HOP_CHI_TIETLOAD_START,
    payload: sf_mucphaithu_search_rq
}

export interface TongHopChiTietLoadSucess {
    type: TongHopChiTietActionTypeIds.TONG_HOP_CHI_TIETLOAD_SUCCESS,
    payload: any[]
}
export interface TongHopChiTietLoadError {
    type: TongHopChiTietActionTypeIds.TONG_HOP_CHI_TIETLOAD_ERROR,
    payload: string
}
export interface TongHopChiTietChangeRequest {
    type: TongHopChiTietActionTypeIds.TONG_HOP_CHI_TIETCHANGE_REQUEST,
    payload: sf_mucphaithu_search_rq

}
export type TongHopChiTietActionTypes = TongHopChiTietLoadStart | TongHopChiTietLoadSucess | TongHopChiTietLoadError | TongHopChiTietChangeRequest