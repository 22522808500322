import { ts_hocsinh_select } from "../models/request/hocsinh/ts_hocsinh_select_rq"
import { apiClient } from "./apiClient"

export const TS_HOCSINH_SELECT_API = "ts_hocsinh/select_by"
export const TS_HOCSINH_SEARCH_API = "ts_hocsinh/search"
export const TS_HOCSINH_SELECT_PARENT_EMAIL = "ts_hocsinh/select_suggest_email"
export const hocSinhApi = {
    select: (rq: ts_hocsinh_select) => {
        return apiClient.post(TS_HOCSINH_SELECT_API, rq)
    },
    search: (key: any) => apiClient.post(TS_HOCSINH_SEARCH_API, key),
    select_parent_email: (ts_hocsinh_id: number) => apiClient.post(`${TS_HOCSINH_SELECT_PARENT_EMAIL}?ts_hocsinh_id=${ts_hocsinh_id}`),
}
