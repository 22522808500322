import { call, put, takeEvery } from "redux-saga/effects"
import { ngayNghiApi } from "../../api/ngayNghiApi"
import { IBaseResponeModel } from "../../models/response/base-response"
import { NgayNghiActionTypeIds, NgayNghiDeleteStartAction, NgayNghiSaveStartAction } from "../action-types/ngayNghiActionTypes"
import {
    loadNgayNghiError, loadNgayNghiSuccess, saveNgayNghiError, saveNgayNghiSuccess,
    deleteNgayNghiSuccess, deleteNgayNghiError
} from "../actions/ngayNghiActions"
export function* ngayNghiSagas(): any {
    yield takeEvery(NgayNghiActionTypeIds.NGAYNGHI_LOAD_START, loadNgayNghiStartWorker)
    yield takeEvery(NgayNghiActionTypeIds.NGAYNGHI_SAVE_START, saveNgayNghiStartWorker)
    yield takeEvery(NgayNghiActionTypeIds.NGAYNGHI_DELETE_START, deleteNgayNghiStartWorker)
}
function* loadNgayNghiStartWorker() {
    const res: IBaseResponeModel = yield call(ngayNghiApi.select_all)
    if (res.is_success) {
        yield put(loadNgayNghiSuccess(res.data))
    } else {
        yield put(loadNgayNghiError(res.message || ""))
    }
}
function* saveNgayNghiStartWorker(data: NgayNghiSaveStartAction) {
    let res: IBaseResponeModel;
    if (data.payload.id > 0) {
        res = yield call(ngayNghiApi.update, data.payload)
    } else {
        res = yield call(ngayNghiApi.insert, data.payload)
    }

    if (res.is_success) {
        yield put(saveNgayNghiSuccess(data.payload))
        yield call(loadNgayNghiStartWorker)
    } else {
        yield put(saveNgayNghiError(res.message || ""))
    }
}
function* deleteNgayNghiStartWorker(data: NgayNghiDeleteStartAction) {
    const res: IBaseResponeModel = yield call(ngayNghiApi.delete, data.payload)
    if (res.is_success) {
        yield put(deleteNgayNghiSuccess(data.payload))
        yield call(loadNgayNghiStartWorker)
    } else {
        yield put(deleteNgayNghiError(res.message || ""))
    }
}