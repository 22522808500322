import { localStorageHelper } from "../../helpers/localStorage"
import { showNotify } from "../../helpers/toast";
import { PageBaseStatus } from "../../models/pageBaseStatus";
import { ThuPhiActionTypeIds, ThuPhiActionTypes } from "../action-types/thuPhiActionTypes";
import { ThuPhiPageState } from "../page-state-model/thuPhiPageState"

const initialState: ThuPhiPageState = {
    nam_hoc: localStorageHelper.getNamHoc(),
    dm_truong_id: localStorageHelper.getDmTruongId(),
    sf_mucphaithus: [],
    status: PageBaseStatus.is_completed,
    // ts_hocsinh: {
    //     title: "[NS216921] - bùi nhật nam",
    //     ho_ten: "bùi nhật nam",
    //     ma_hs: "NS216921",
    //     id: 19,
    //     ngay_sinh: "2013-10-12T00:00:00",
    //     email: "dobichhuong1986@gmail.com",
    //     dien_thoai: "988868080",
    //     ten_khoi: "Khối 3",
    //     ten_he: "Hệ Chuyên",
    //     ten_truong: "Tiểu học Ngôi sao Hà Nội",
    //     ma_tn: "TN000019",
    //     dm_he_id: 2,
    //     dm_truong_id: 1,
    //     dm_khoi_id: 3
    // }
}
export const thuPhiReducer = (state: ThuPhiPageState = initialState, action: ThuPhiActionTypes): ThuPhiPageState => {
    switch (action.type) {
        case ThuPhiActionTypeIds.THUPHI_CHANGE_NAMHOC:
            return {
                ...state,
                nam_hoc: action.payload
            }
        case ThuPhiActionTypeIds.THUPHI_CHANGE_HOCSINH:
            return {
                ...state,
                ts_hocsinh: action.payload
            }
        case ThuPhiActionTypeIds.THUPHI_MUCPHAITHU_LOAD_START:
            return {
                ...state,
                status: PageBaseStatus.is_loading
            }
        case ThuPhiActionTypeIds.THUPHI_MUCPHAITHU_LOAD_SUCCESS:
            return {
                ...state,
                status: PageBaseStatus.is_completed,
                sf_mucphaithus: action.payload
            }
        case ThuPhiActionTypeIds.THUPHI_MUCPHAITHU_LOAD_ERROR:
            showNotify({ message: action.payload, type: "warning" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }
        case ThuPhiActionTypeIds.THUPHI_CREATE_FROM_TBTP_START:
            return {
                ...state,
                status: PageBaseStatus.is_saving,
            }
        case ThuPhiActionTypeIds.THUPHI_CREATE_FROM_TBTP_SUCESS:
            showNotify({ message: "Tạo chứng từ thành công", type: "success" })

            return {
                ...state,
                status: PageBaseStatus.is_need_reload
            }
        case ThuPhiActionTypeIds.THUPHI_CREATE_FROM_TBTP_ERROR:
            showNotify({ message: action.payload, type: "warning" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }
        case ThuPhiActionTypeIds.THUPHI_SHOW_CHITIET:
            return {
                ...state,
                is_show_editing_modal: true,
                sf_mucphaithu_editing: action.payload
            }
        case ThuPhiActionTypeIds.THUPHI_CLOSE_CHITIET:
            return {
                ...state,
                is_show_editing_modal: false,
                sf_mucphaithu_editing: undefined
            }
        case ThuPhiActionTypeIds.THUPHI_SHOW_ADD_MODAL:
            return {
                ...state,
                is_show_adding_modal: true
            }
        case ThuPhiActionTypeIds.THUPHI_CLOSE_ADD_MODAL:
            return {
                ...state,
                is_show_adding_modal: false,
            }
        case ThuPhiActionTypeIds.THUPHI_CREATE_FROM_KHOANNOP_START:
            return {
                ...state,
                status: PageBaseStatus.is_saving,
            }
        case ThuPhiActionTypeIds.THUPHI_CREATE_FROM_KHOANNOP_SUCCESS:
            showNotify({ message: "Tạo chứng từ thành công", type: "success" })

            return {
                ...state,
                status: PageBaseStatus.is_need_reload,
                is_show_adding_modal: false
            }
        case ThuPhiActionTypeIds.THUPHI_CREATE_FROM_KHOANNOP_ERROR:
            showNotify({ message: action.payload, type: "warning" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }

        default:
            return state;
    }
}