import axios from 'axios';
import { appInfo } from '../AppInfo';
import { ILoginSSORequestModel } from '../models/request/account';
import { apiClient } from './apiClient';

const accountApi = {
    getUser: () => {
        return apiClient.post(`login/detail`)
    },
    logInSSO: (data: ILoginSSORequestModel) => {
        return axios.post(`${appInfo.baseApiURL}/login`, data)
    },
    logOut() {

    }

}
export {accountApi}